import { StaffProfile, User } from "api/types";
import classNames from "classnames";
import Checkbox from "components/Checkbox";
import ReusableForm from "components/Form";
import { FieldType, FormData } from "components/types";
import { useState } from "react";
import { UseFormGetValues, UseFormSetValue, useForm } from "react-hook-form";
import { OccupationOptions } from "routes/dashboard/staff/InviteStaff";
import {
  AUSTRALIA_STATE_OPTIONS,
  countriesOptions,
  getTodayDate,
  languageOptions,
  validateAustralianPhoneNumber,
} from "utils/util";
import { AdminUpdateProps } from "./AdminUpdateResidencyAndWorkRights";

export const getAdminPersonalDetailsFields = (
  getValue: UseFormGetValues<FormData>,
  setValue: UseFormSetValue<FormData>,
  staffProfile: StaffProfile,
  user: User,
  setResidentialState: (value: string | undefined) => void,
  residentialState: string | undefined,
  watchedFields: FormData
) => [
  {
    name: "email",
    label: "Email *",
    type: FieldType.Input,
    placeholder: "johndoe@gmail.com",
    parentFormContainerClassName:
      "md:w-1/2 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.email,
  },
  {
    name: "occupation",
    label: "Occupation *",
    type: FieldType.Select,
    placeholder: "Select",
    options: OccupationOptions,
    parentFormContainerClassName:
      "md:w-1/2 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: staffProfile.occupation,
  },
  {
    name: "mobile",
    label: "Mobile *",
    type: FieldType.Input,
    placeholder: "04XXX XXX XXX",
    parentFormContainerClassName:
      "md:w-1/2 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.mobile,
    additionalTriggerRequired: true,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
    requiredCondition: watchedFields.mobile,
  },
  {
    name: "homePhone",
    label: "Home Telephone no.",
    type: FieldType.Input,
    placeholder: "04XXX XXX XXX",
    parentFormContainerClassName:
      "md:w-1/2 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: staffProfile.homePhone,
    additionalTriggerRequired: true,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
    requiredCondition: watchedFields.homePhone,
  },

  {
    type: FieldType.Paragraph,
    parentFormContainerClassName: "w-full",
    name: "",
    label: "",
    children: (
      <p
        className={classNames(
          "mt-5 text-sm leading-5 font-semibold text-gray-700"
        )}
      >
        Residential address *
      </p>
    ),
  },
  {
    name: "residentialAddressBuildingNo",
    label: "Building/House/Unit number",
    type: FieldType.Input,
    placeholder: "2",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.residentialAddressBuildingNo,
  },
  {
    name: "residentialAddressStreetName",
    label: "Street name",
    type: FieldType.Input,
    placeholder: "Rose Street",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.residentialAddressStreetName,
  },
  {
    name: "residentialAddressSuburb",
    label: "Suburb",
    type: FieldType.Input,
    placeholder: "Clayton",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.residentialAddressSuburb,
  },
  {
    name: "residentialAddressState",
    label: "State/Territory",
    type: FieldType.Select,
    placeholder: "Select State/Territory",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.residentialAddressState,
    options: AUSTRALIA_STATE_OPTIONS,
  },
  {
    name: "residentialAddressZip",
    label: "Postcode",
    type: FieldType.Input,
    placeholder: "3001",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.residentialAddressZip,
  },
  {
    type: FieldType.Paragraph,
    parentFormContainerClassName: "w-full",
    name: "",
    label: "",
    children: (
      <div className="flex space-x-4 items-center mt-5 ">
        <p
          className={classNames(
            "text-sm leading-5 font-semibold text-gray-700"
          )}
        >
          Mailing address *
        </p>
        <Checkbox
          name="sameAsResidentialAddress"
          label="Same as Residential address"
          className="!mt-0"
          type={FieldType.Checbox}
          onChange={(e) => {
            if (e.target.checked) {
              setValue(
                "mailingAddressBuildingNo",
                getValue("residentialAddressBuildingNo")
              );
              setValue(
                "mailingAddressStreetName",
                getValue("residentialAddressStreetName")
              );
              setValue(
                "mailingAddressSuburb",
                getValue("residentialAddressSuburb")
              );
              setValue(
                "mailingAddressState",
                getValue("residentialAddressState")
              );
              setResidentialState(getValue("residentialAddressState"));
              setValue("mailingAddressZip", getValue("residentialAddressZip"));
            } else {
              setValue("mailingAddressBuildingNo", "");
              setValue("mailingAddressStreetName", "");
              setValue("mailingAddressSuburb", "");
              setValue("mailingAddressState", "");
              setValue("mailingAddressZip", "");
              setResidentialState(undefined);
            }
          }}
        />
      </div>
    ),
  },
  {
    name: "mailingAddressBuildingNo",
    label: "Building/House/Unit number",
    type: FieldType.Input,
    placeholder: "2",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.mailingAddressBuildingNo,
  },
  {
    name: "mailingAddressStreetName",
    label: "Street name",
    type: FieldType.Input,
    placeholder: "Rose Street",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.mailingAddressStreetName,
  },
  {
    name: "mailingAddressSuburb",
    label: "Suburb",
    type: FieldType.Input,
    placeholder: "Clayton",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.mailingAddressSuburb,
  },
  {
    name: "mailingAddressState",
    label: "State/Territory",
    type: FieldType.Select,
    placeholder: "Select State/Territory",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: residentialState || staffProfile.mailingAddressState,
    options: AUSTRALIA_STATE_OPTIONS,
  },
  {
    name: "mailingAddressZip",
    label: "Postcode",
    type: FieldType.Input,
    placeholder: "3001",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.mailingAddressZip,
  },
];

export const getSuperAdminPersonalDetailsFields = (
  getValue: UseFormGetValues<FormData>,
  setValue: UseFormSetValue<FormData>,
  staffProfile: StaffProfile,
  user: User,
  setResidentialState: (value: string | undefined) => void,
  residentialState: string | undefined,
  watchedFields: FormData
) => [
  {
    name: "title",
    label: "Title *",
    type: FieldType.Select,
    placeholder: "Select",

    options: [
      { label: "Mr", value: "Mr" },
      {
        label: "Mrs",
        value: "Mrs",
      },
      { label: "Miss", value: "Miss" },
    ],
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.title,
    hideSearch: true,
  },
  {
    name: "firstName",
    label: "First Name *",
    type: FieldType.Input,
    placeholder: "John",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: user.firstName,
  },
  {
    name: "lastName",
    label: "Last Name *",
    type: FieldType.Input,
    placeholder: "Smith",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: user.lastName,
  },
  {
    name: "email",
    label: "Email *",
    type: FieldType.Input,
    placeholder: "johndoe@gmail.com",
    parentFormContainerClassName:
      "md:w-1/2 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.email,
  },
  {
    name: "occupation",
    label: "Occupation *",
    type: FieldType.Select,
    placeholder: "Select",
    options: OccupationOptions,
    parentFormContainerClassName:
      "md:w-1/2 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: staffProfile.occupation,
  },
  {
    name: "mobile",
    label: "Mobile *",
    type: FieldType.Input,
    placeholder: "04XXX XXX XXX",
    parentFormContainerClassName:
      "md:w-1/2 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.mobile,
    additionalTriggerRequired: true,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
    requiredCondition: watchedFields && watchedFields.mobile,
  },
  {
    name: "homePhone",
    label: "Home Telephone no.",
    type: FieldType.Input,
    placeholder: "04XXX XXX XXX",
    parentFormContainerClassName:
      "md:w-1/2 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: staffProfile.homePhone,
    additionalTriggerRequired: true,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
    requiredCondition: watchedFields && watchedFields.homePhone,
  },

  {
    type: FieldType.Paragraph,
    parentFormContainerClassName: "w-full",
    name: "",
    label: "",
    children: (
      <p
        className={classNames(
          "mt-5 text-sm leading-5 font-semibold text-gray-700"
        )}
      >
        Residential address *
      </p>
    ),
  },
  {
    name: "residentialAddressBuildingNo",
    label: "Building/House/Unit number",
    type: FieldType.Input,
    placeholder: "2",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.residentialAddressBuildingNo,
  },
  {
    name: "residentialAddressStreetName",
    label: "Street name",
    type: FieldType.Input,
    placeholder: "Rose Street",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.residentialAddressStreetName,
  },
  {
    name: "residentialAddressSuburb",
    label: "Suburb",
    type: FieldType.Input,
    placeholder: "Clayton",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.residentialAddressSuburb,
  },
  {
    name: "residentialAddressState",
    label: "State/Territory",
    type: FieldType.Select,
    placeholder: "Select State/Territory",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.residentialAddressState,
    options: AUSTRALIA_STATE_OPTIONS,
  },
  {
    name: "residentialAddressZip",
    label: "Postcode",
    type: FieldType.Input,
    placeholder: "3001",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.residentialAddressZip,
  },
  {
    type: FieldType.Paragraph,
    parentFormContainerClassName: "w-full",
    name: "",
    label: "",
    children: (
      <div className="flex space-x-4 items-center mt-5 ">
        <p
          className={classNames(
            "text-sm leading-5 font-semibold text-gray-700"
          )}
        >
          Mailing address *
        </p>
        <Checkbox
          name="sameAsResidentialAddress"
          label="Same as Residential address"
          className="!mt-0"
          type={FieldType.Checbox}
          onChange={(e) => {
            if (e.target.checked) {
              setValue(
                "mailingAddressBuildingNo",
                getValue("residentialAddressBuildingNo")
              );
              setValue(
                "mailingAddressStreetName",
                getValue("residentialAddressStreetName")
              );
              setValue(
                "mailingAddressSuburb",
                getValue("residentialAddressSuburb")
              );
              setValue(
                "mailingAddressState",
                getValue("residentialAddressState")
              );
              setResidentialState(getValue("residentialAddressState"));
              setValue("mailingAddressZip", getValue("residentialAddressZip"));
            } else {
              setValue("mailingAddressBuildingNo", "");
              setValue("mailingAddressStreetName", "");
              setValue("mailingAddressSuburb", "");
              setValue("mailingAddressState", "");
              setValue("mailingAddressZip", "");
              setResidentialState(undefined);
            }
          }}
        />
      </div>
    ),
  },
  {
    name: "mailingAddressBuildingNo",
    label: "Building/House/Unit number",
    type: FieldType.Input,
    placeholder: "2",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.mailingAddressBuildingNo,
  },
  {
    name: "mailingAddressStreetName",
    label: "Street name",
    type: FieldType.Input,
    placeholder: "Rose Street",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.mailingAddressStreetName,
  },
  {
    name: "mailingAddressSuburb",
    label: "Suburb",
    type: FieldType.Input,
    placeholder: "Clayton",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.mailingAddressSuburb,
  },
  {
    name: "mailingAddressState",
    label: "State/Territory",
    type: FieldType.Select,
    placeholder: "Select State/Territory",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: residentialState || staffProfile.mailingAddressState,
    options: AUSTRALIA_STATE_OPTIONS,
  },
  {
    name: "mailingAddressZip",
    label: "Postcode",
    type: FieldType.Input,
    placeholder: "3001",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6 mr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.mailingAddressZip,
  },
  {
    name: "dob",
    label: "Date of Birth *",
    type: FieldType.Calendar,
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    wrapperClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.dob,
    maxDate: getTodayDate(),
  },
  {
    name: "birthCountry",
    label: "Country of Birth *",
    type: FieldType.Select,
    options: countriesOptions,
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.birthCountry,
  },
  {
    name: "gender",
    label: "Gender *",
    type: FieldType.Radio,
    options: [
      { label: "Male", value: "male" },
      {
        label: "Female",
        value: "female",
      },
    ],
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: staffProfile.gender,
  },
  {
    name: "homeRegion",
    label: "Home Region *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.homeRegion,
  },
  {
    name: "spokenLanguages",
    label: "Spoken language(s)*",
    type: FieldType.MultiSelect,
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    options: languageOptions,
    defaultValue: staffProfile.spokenLanguages,
  },
  {
    name: "aboriginalTSIStatus",
    label: "I identify as Aboriginal and or TSI *",
    type: FieldType.Radio,
    options: [
      { label: "Aboriginal", value: "Aboriginal" },
      {
        label: "Torres Strait Islander (TSI)",
        value: "Torres Strait Islander (TSI)",
      },
      { label: "Both", value: "Both" },
      { label: "None of the above", value: "None of the above" },
    ],
    isCol: true,
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",

    defaultValue: staffProfile.aboriginalTSIStatus,
  },
];

export default function AdminUpdatePersonalDetails({
  user,
  onSubmitFn,
  isSuperAdmin,
}: AdminUpdateProps) {
  const { formState, getValues, setValue, watch, ...formMethods } =
    useForm<FormData>();
  const { staffProfile } = user;
  const [residentialState, setResidentialState] = useState<string | undefined>(
    undefined
  );

  const watchedFields = watch();

  const onSubmit = async (data: FormData) => onSubmitFn(data);

  return (
    <div className="bg-white rounded-md">
      <div className="pl-6 pr-0 pb-0">
        <ReusableForm
          fields={
            isSuperAdmin
              ? getSuperAdminPersonalDetailsFields(
                  getValues,
                  setValue,
                  staffProfile,
                  user,
                  setResidentialState,
                  residentialState,
                  watchedFields
                )
              : getAdminPersonalDetailsFields(
                  getValues,
                  setValue,
                  staffProfile,
                  user,
                  setResidentialState,
                  residentialState,
                  watchedFields
                )
          }
          onSubmit={onSubmit}
          id="edit-PERSONAL_INFORMATION"
          formClassName="pt-1 pb-6"
          formMethods={{
            ...formMethods,
            formState,
            getValues,
            setValue,
            watch,
          }}
        />
      </div>
    </div>
  );
}
