import { createdAccountDocumentUpdate } from "api/staff";
import Button from "components/Button";
import Calendar from "components/Calendar";
import FileUpload from "components/FileUpload";
import Notification, { NotificationProps } from "components/Notification";
import { FieldType, FormData } from "components/types";
import { handleResponse } from "components/utility";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getTodayDate } from "utils/util";
import { CurrentEmployeeProps } from "./CurrentEmployeeStep1";

const uploadComponents = [
  {
    name: "policeCheck",
    label: "Police check *",
    buttonLabel: "Upload police check",
    type: FieldType.FileUpload,
    fileUploadContainerClassName: "w-full md:w-3/4 mb-5 pr-6",
    key: "policeCheck",
    externalLink: "https://www.police.vic.gov.au/apply-national-police-check",
  },
];

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear"];

export default function CurrentEmployeeStep5({
  user,
  refreshUser,
  isToggled,
}: CurrentEmployeeProps) {
  const { handleSubmit, formState, setError, ...formMethods } =
    useForm<FormData>();
  const [response, setResponse] = useState<NotificationProps | null>(null);

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    delete data.sameAsResidentialAddress;
    const response = await createdAccountDocumentUpdate(
      5,
      data,
      FIELDS_TO_REMOVE
    );
    const isSuccess = await handleResponse(
      response,
      setError,
      setResponse,
      "Submitted successfully"
    );
    isSuccess && (await refreshUser());
  };

  const { staffProfile } = user;

  return (
    <div className="bg-white shadow rounded-md">
      <div className="flex flex-col ">
        {response ? (
          <Notification
            type={response.type}
            message={response.message}
            className="my-2 !inline-flex w-max"
          />
        ) : (
          <></>
        )}
      </div>
      <FormProvider {...{ ...formMethods, handleSubmit, formState, setError }}>
        <form id={"currentStaffDocument-5"} onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap md:flex-nowrap px-6">
            {uploadComponents.map((uploadComp) => (
              <FileUpload
                {...uploadComp}
                requiredCondition="This is a required field"
                isHiglighted={isToggled}
                defaultValue={staffProfile.policeCheck}
              />
            ))}
            <Calendar
              name="policeCheckValidUntil"
              label="Police check expiry date *"
              type={FieldType.Calendar}
              parentFormContainerClassName="!my-0"
              requiredCondition="This is a required field"
              isHiglighted={isToggled}
              defaultValue={staffProfile.policeCheckValidUntil}
              minDate={getTodayDate()}
              className="w-full md:w-96"
            />
          </div>
          <div className="bg-gray-50 flex justify-end space-x-2 items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full flex-wrap md:flex-nowrap">
            <Button
              variant="primary"
              type="submit"
              form={`currentStaffDocument-5`}
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
