import { GenericResponse } from "api/types";
import { FieldError, UseFormSetError } from "react-hook-form";
import { QueryClient } from "react-query";
import { Bounce, toast } from "react-toastify";
import { Icon } from "./Icon";
import { NotificationProps } from "./Notification";
import { FormData } from "./types";

export const renderErrorMessage = (error: FieldError | undefined) => {
  if (!error) return null;
  return (
    <div role="alert" className="mt-1 text-sm text-red-700">
      {error.message}
    </div>
  );
};

export const handleError = (
  response: GenericResponse,
  setError: UseFormSetError<FormData>,
  setResponse: (props: NotificationProps) => void
) => {
  const {
    data: { validation },
  } = response;
  const errorField = validation?.body?.keys[0];
  const errorMessage = validation?.body?.message;
  errorField &&
    setError(errorField, {
      type: "manual",
      message: errorMessage,
    });
  setResponse({
    type: "error",
    message: response.data?.message || response.data?.error,
  });
};

export const handleResponse = (
  response: GenericResponse,
  setError: UseFormSetError<FormData>,
  setResponse: (props: NotificationProps) => void,
  successMessage = "Saved successfully"
) => {
  if (
    (response.data?.message || response.data?.error) &&
    response.status &&
    response.status !== 200
  ) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    handleError(response, setError, setResponse);
    toast.error(response.data?.message || "Error occurred", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: <Icon name="error" />,
    });
    return false;
  }
  toast.success(successMessage, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
    icon: <Icon name="check" />,
  });
  return true;
};

export const handleGenericResponse = (
  response: GenericResponse,
  successMessage = "Saved successfully"
) => {
  if (
    ((response.data?.message || response.data?.error) &&
      response.status &&
      response.status !== 200) ||
    response.isError
  ) {
    toast.error(response.data?.message || "Error occurred", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: <Icon name="error" />,
    });
    return false;
  }
  toast.success(successMessage, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
    icon: <Icon name="check" />,
  });
  return true;
};

export const invalidateQueries = async (
  queryClient: QueryClient,
  queriesToInvalidate: string[]
) => {
  await Promise.all(
    queriesToInvalidate.map((queryKey) =>
      queryClient.invalidateQueries(queryKey)
    )
  );
};
