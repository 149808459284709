import { User } from "api/types";
import { Outlet, useLoaderData } from "react-router-dom";
import ApplicationMenu from "routes/dashboard/Menu";
import Sidebar from "../../components/Sidebar";
import NormalUserReport from "./NormalUserReport";

export interface DashboardStaffOutletContext {
  user: User;
  isAdmin: boolean;
  isSuperAdmin: boolean;
}

const sidebarData = [
  {
    title: "Staff",
    icon: "allStaff",
    children: [
      {
        label: "Employment checks register",
        url: "/reports/employment-checks-register",
      },
      { label: "Police check report", url: "/reports/police-check-report" },
      {
        label: "Emergency contact details",
        url: "/reports/emergency-contacts-details",
      },
      {
        label: "Expiring documents",
        url: "/dashboard/expiringDocuments",
        isOpenInNewTab: true,
      },
    ],
  },
  {
    title: "Clients",
    icon: "clients",
    url: "/reports/clients",
  },
  {
    title: "Allied Health Services",
    icon: "alliedHealthService",
    url: "/reports/health-services",
  },
  {
    title: "Subcontractors",
    icon: "subContractor",
    url: "/reports/subcontractors",
  },
];

export default function Reports() {
  const { isAdmin } = useLoaderData() as { isAdmin: boolean; user: User };
  console.log("isAdmin", isAdmin);
  return (
    <div className="">
      <ApplicationMenu />
      <header className="mx-auto max-w-7xl">
        <div className="font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-200 pt-10 pb-3 flex justify-between">
          <h1 className="text-3xl">Reports</h1>
        </div>
      </header>
      <main className="mb-11">
        {isAdmin ? (
          <div className="flex mx-auto max-w-7xl pt-8">
            <div className="md:w-1/4 pr-8 hidden sm:block">
              <Sidebar items={sidebarData} />
            </div>
            <div className="flex flex-col md:w-3/4 pl-2">
              <Outlet />
            </div>
          </div>
        ) : (
          <NormalUserReport />
        )}
      </main>
    </div>
  );
}
