import Calendar from "components/Calendar";
import FileUpload from "components/FileUpload";
import Radio from "components/RadioBox";
import Input from "components/UnControlledInput";
import { FieldType, FormData } from "components/types";
import { FormProvider, useForm } from "react-hook-form";
import { getTodayDate, validateNumberOrDecimal } from "utils/util";
import { AdminUpdateProps } from "./AdminUpdateResidencyAndWorkRights";

export default function AdminUpdateVehicleDetails({
  user,
  onSubmitFn,
  isSuperAdmin,
}: AdminUpdateProps) {
  const {
    getValues,
    watch,
    formState,
    handleSubmit,
    setError,
    ...formMethods
  } = useForm<FormData>();
  const { staffProfile } = user;
  const vehicleOwnership = watch(
    "ownVehicle",
    typeof staffProfile.vehicleOwnership === "boolean"
      ? staffProfile.vehicleOwnership
        ? "Yes"
        : "No"
      : "Yes"
  );
  const engineSize = watch(
    "engineSize",
    staffProfile.vehicleDetails?.engineSize
  );
  const methods = {
    ...formMethods,
    getValues,
    formState,
    watch,
    handleSubmit,
    setError,
  };

  const onSubmit = async (data: FormData) => onSubmitFn(data);

  return (
    <div className="bg-white rounded-md">
      <div className="text-sm font-medium">
        <FormProvider {...methods}>
          <form
            id="edit-VEHICLE_AND_DRIVING_LICENCE"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="pl-6 pb-6">
              <div className="">
                <Radio
                  name="ownVehicle"
                  label="Do you own a vehicle? *"
                  options={[
                    {
                      label: "Yes",
                      value: "Yes",
                    },
                    {
                      label: "No",
                      value: "No",
                    },
                  ]}
                  type={FieldType.Radio}
                  defaultValue={
                    typeof staffProfile.vehicleOwnership === "boolean"
                      ? staffProfile.vehicleOwnership
                        ? "Yes"
                        : "No"
                      : "Yes"
                  }
                />
              </div>
              {vehicleOwnership === "Yes" && (
                <div>
                  <div>
                    <div className="flex justify-start flex-wrap md:flex-nowrap">
                      <Input
                        name="vehicleOwner"
                        label="Vehicle owner"
                        type={FieldType.Input}
                        defaultValue={
                          staffProfile.vehicleDetails?.vehicleOwner ||
                          `${user.firstName} ${user.lastName}`
                        }
                        isDisabled={!isSuperAdmin}
                        containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                        className="w-full"
                      />
                      <Input
                        name="vehicleMake"
                        label="Make of vehicle *"
                        type={FieldType.Input}
                        defaultValue={staffProfile.vehicleDetails?.vehicleMake}
                        containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                        className="w-full"
                      />
                      <Input
                        name="vehicleModel"
                        label="Model of vehicle *"
                        type={FieldType.Input}
                        defaultValue={staffProfile.vehicleDetails?.vehicleModel}
                        containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                        className="w-full"
                      />
                    </div>
                    <div className="flex justify-start flex-wrap md:flex-nowrap">
                      <Input
                        name="engineSize"
                        label="Engine size *"
                        type={FieldType.Input}
                        requiredCondition={
                          engineSize && "This is a required field"
                        }
                        additionalTriggerRequired
                        defaultValue={
                          staffProfile.vehicleDetails?.engineSize
                            ?.toLowerCase()
                            .includes("l")
                            ? staffProfile.vehicleDetails?.engineSize.replaceAll(
                                /l/gi,
                                ""
                              )
                            : staffProfile.vehicleDetails?.engineSize
                        }
                        containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                        className="w-full rounded-r-none !border-r-none"
                        inputContainerClassName="flex"
                        validationFunction={validateNumberOrDecimal}
                        validationErrorMessage="Only numbers and decimals are allowed."
                        children={
                          <span
                            className={
                              "rounded-r-md inline-flex items-center px-3 text-gray-500 ring-1 ring-inset ring-gray-300 bg-gray-100 text-sm h-10"
                            }
                          >
                            L
                          </span>
                        }
                      />
                      <Input
                        name="vehiclePlateNumber"
                        label="Vehicle plate number *"
                        type={FieldType.Input}
                        defaultValue={
                          staffProfile.vehicleDetails?.vehiclePlateNumber
                        }
                        containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                        className="w-full"
                      />
                    </div>
                  </div>
                  <div className="flex items-stretch flex-wrap md:flex-nowrap">
                    <div className="w-full md:w-2/3 pr-6">
                      <FileUpload
                        name="vehicleRegistration"
                        label="Vehicle registration * (Download report from Vic Roads)"
                        type={FieldType.FileUpload}
                        defaultValue={
                          staffProfile.vehicleDetails?.vehicleRegistration
                        }
                        isMultipleFiles={false}
                        buttonLabel="Upload Vehicle registration"
                        externalLink="https://www.vicroads.vic.gov.au/registration/buy-sell-or-transfer-a-vehicle/check-vehicle-registration/vehicle-registration-enquiry"
                      />
                    </div>
                    <div className="w-full md:w-1/3 pr-6">
                      <Calendar
                        name="vehicleRegistrationExpiry"
                        defaultValue={
                          staffProfile.vehicleDetails?.vehicleRegistrationExpiry
                        }
                        type={FieldType.Calendar}
                        label="Registration expiry date *"
                        containerClassName="w-full"
                        wrapperClassName="w-full"
                        minDate={getTodayDate()}
                        className="w-full"
                      />
                    </div>
                  </div>
                  <div className="flex items-stretch flex-wrap md:flex-nowrap">
                    <div className="w-full md:w-2/3 md:pr-6">
                      <FileUpload
                        name="vehicleInsurance"
                        label="Vehicle insurance *"
                        type={FieldType.FileUpload}
                        defaultValue={
                          staffProfile.vehicleDetails?.vehicleInsurance
                        }
                        isMultipleFiles={false}
                        buttonLabel="Upload Vehicle insurance"
                      />
                    </div>
                    <div className="w-full md:w-1/3 md:pr-6">
                      <Calendar
                        name="vehicleInsuranceExpiry"
                        defaultValue={
                          staffProfile.vehicleDetails?.vehicleInsuranceExpiry
                        }
                        type={FieldType.Calendar}
                        label="Vehicle insurance expiry date *"
                        containerClassName="w-full"
                        wrapperClassName="w-full"
                        minDate={getTodayDate()}
                        className="w-full"
                      />
                    </div>
                  </div>
                  <div className="flex items-stretch flex-wrap md:flex-nowrap">
                    <div className="w-full md:w-1/2 md:pr-6">
                      <Input
                        name="drivingLicenseNumber"
                        label="Driving licence number *"
                        type={FieldType.Input}
                        defaultValue={
                          staffProfile.vehicleDetails?.drivingLicenseNumber
                        }
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pr-6">
                      <Calendar
                        name="drivingLicenseExpiry"
                        defaultValue={
                          staffProfile.vehicleDetails?.drivingLicenseExpiry
                        }
                        type={FieldType.Calendar}
                        label="Driving licence expiry date *"
                        containerClassName="w-full"
                        wrapperClassName="w-full"
                        minDate={getTodayDate()}
                        className="w-full"
                      />
                    </div>
                  </div>
                  <div className="flex items-stretch flex-wrap md:flex-nowrap">
                    <FileUpload
                      name="drivingLicenseFront"
                      label="Certified driving licence (Front) *"
                      type={FieldType.FileUpload}
                      defaultValue={
                        staffProfile.vehicleDetails?.drivingLicenseFront
                      }
                      buttonLabel="Upload driving licence (Front)"
                      fileUploadContainerClassName="w-full md:w-1/2 md:pr-6"
                    />
                    <FileUpload
                      name="drivingLicenseBack"
                      label="Certified driving licence (Back) *"
                      type={FieldType.FileUpload}
                      defaultValue={
                        staffProfile.vehicleDetails?.drivingLicenseBack
                      }
                      buttonLabel="Upload driving licence (Back)"
                      fileUploadContainerClassName="w-full md:w-1/2 md:pr-6"
                    />
                  </div>
                </div>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
