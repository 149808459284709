import { StaffProfile, User } from "api/types";
import ReusableForm from "components/Form";
import { FieldType, FormData, RESIDENCY_STATUS } from "components/types";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ResidencyOptions,
  VisaTypeOptions,
} from "routes/onboarding/staff/steps/step1";
import { getDateAfterPeriod, getTodayDate } from "utils/util";

export const getRequiredFields = (staffProfile: StaffProfile) => [
  {
    name: "residencyStatus",
    label: "Select Residency Status *",
    type: FieldType.Select,
    placeholder: "Select",
    className: "w-full ",
    parentFormContainerClassName: `w-1/2 md:pr-6`,
    options: ResidencyOptions,
    defaultValue: staffProfile.residencyStatus,
    containerClassName: "w-full ",
    hideSearch: true,
  },
];

export const getCitizenFields = (staffProfile: StaffProfile) => {
  return [
    {
      type: FieldType.Paragraph,
      parentFormContainerClassName: "w-full",
      name: "",
      label: "",
      children: (
        <p className="mt-5">
          <span className="text-lg leading-6 font-semibold text-gray-900">
            Proof of citizenship
          </span>
          <p className="text-sm leading-5 font-normal text-gray-500">
            Please provide at least 1 of the 3 options
          </p>
        </p>
      ),
    },
    {
      name: "citizenShipCertificate",
      label: "Australian citizenship certificate",
      type: FieldType.FileUpload,
      parentFormContainerClassName:
        "md:w-1/3 w-full inline-block align-top pr-2 md:pr-6",
      defaultValue: staffProfile.citizenShipCertificate,

      externalLink:
        "https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online/visa-holders",
    },
    {
      name: "passport",
      label: "Certified copy of Passport",
      type: FieldType.FileUpload,
      parentFormContainerClassName:
        "md:w-1/3 w-full inline-block align-top pr-2 md:pr-6",
      defaultValue: staffProfile.passport,

      externalLink: "https://www.justice.vic.gov.au/certifiedcopies",
    },
    {
      name: "australianBirthCertificate",
      label: "Australian birth certificate",
      type: FieldType.FileUpload,
      parentFormContainerClassName:
        "md:w-1/3 w-full inline-block align-top md:pr-6",
      defaultValue: staffProfile.australianBirthCertificate,

      externalLink: "https://www.bdm.vic.gov.au/births/get-a-birth-certificate",
    },
  ];
};

export const getPassportExpiry = (staffProfile: StaffProfile) => [
  {
    name: "passportExpiryDate",
    label: "Passport expiry date *",
    type: FieldType.Calendar,
    parentFormContainerClassName: `w-full flex items-center justify-center`,
    defaultValue: staffProfile.passportExpiryDate,

    containerClassName: "md:w-1/3  md:pr-6",
    minDate: getTodayDate(),
    wrapperClassName: "w-full",
    className: "w-full",
    placeholder: "Input passport expiry date",
  },
  {
    name: "passportNumber",
    label: "Passport number *",
    type: FieldType.Input,
    containerClassName: "md:w-1/3  md:pr-6",
    parentFormContainerClassName: "w-full flex items-center justify-center",

    defaultValue: staffProfile.passportNumber,
    placeholder: "Input passport number",
  },
];

export const getLegalWorkField = (staffProfile: StaffProfile) => [
  {
    name: "legallyWorkInAustralia",
    label: "Are you legally entitled to work in Australia? *",
    type: FieldType.Radio,
    className: "mt-4 w-full",
    options: [
      { label: "Yes", value: "true" },
      {
        label: "No",
        value: "false",
      },
    ],

    defaultValue: staffProfile.legallyWorkInAustralia?.toString(),
  },
];

export const validateDate = (date: Date | null) => {
  if (date) {
    const today = new Date();
    const sixMonthsFromToday = new Date(today.setMonth(today.getMonth() + 6));
    if (date < sixMonthsFromToday) {
      return "The date cannot be before the current date and should be at least 6 months valid.";
    }
  }
  return true;
};

export const getOtherFields = (staffProfile: StaffProfile) => [
  {
    name: "visaType",
    label: "Visa type *",
    type: FieldType.Select,
    placeholder: "Select",
    className: "w-full ",
    containerClassName: "w-full ",

    options: VisaTypeOptions,
    parentFormContainerClassName: "md:w-1/2 w-full inline-block align-top pr-6",
    defaultValue: staffProfile.visaType,
  },
  {
    name: "visaClass",
    label: "Visa Class/Subclass i.e TU/573 *",
    type: FieldType.Input,
    className: "w-full ",
    containerClassName: "w-full ",
    placeholder: "Visa Class example",
    parentFormContainerClassName:
      "md:w-1/2 w-full  inline-block align-top  pr-6",

    defaultValue: staffProfile.visaClass,
  },
  {
    name: "workEntitlement",
    label: "Work entitlements *",
    type: FieldType.Radio,
    className: "w-96",
    options: [
      { label: "Limited", value: "limited" },
      {
        label: "Unlimited",
        value: "unlimited",
      },
    ],

    defaultValue: staffProfile.workEntitlement,
  },
  {
    name: "visaGrantDate",
    label: "Visa grant date *",
    type: FieldType.Calendar,
    parentFormContainerClassName:
      "md:w-1/2 w-full  inline-block align-top pr-6",

    defaultValue: staffProfile.visaGrantDate,
    maxDate: getTodayDate(),
    className: "w-full ",
    containerClassName: "w-full ",
    wrapperClassName: "w-full ",
  },
  {
    name: "visaExpiryDate",
    label: "Visa expiry date (Visa must be valid for at least 6 months)*",
    type: FieldType.Calendar,
    parentFormContainerClassName: "w-1/2  inline-block align-top pr-6",

    validateFunction: validateDate,
    defaultValue: staffProfile.visaExpiryDate,
    containerClassName: "w-full",
    wrapperClassName: "w-full ",
    className: "!w-full ",
    minDate: getDateAfterPeriod(1, 6),
  },
  {
    name: "vevoRecord",
    label: "Upload vevo *",
    type: FieldType.FileUpload,
    buttonLabel: "Attach vevo",
    placeholder: "or drag and drop",
    parentFormContainerClassName: "w-full pr-6",
    defaultValue: staffProfile.vevoRecord,
    externalLink:
      "https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online/visa-holders",
  },
  {
    name: "passport",
    label: "Certified copy of Passport *",
    type: FieldType.FileUpload,
    parentFormContainerClassName: "w-full",
    fileUploadContainerClassName: "md:w-full pr-6",
    defaultValue: staffProfile.passport,

    externalLink: "https://www.justice.vic.gov.au/certifiedcopies",
  },
  {
    name: "passportExpiryDate",
    label: "Passport expiry date *",
    type: FieldType.Calendar,
    parentFormContainerClassName: "md:w-1/2 w-full inline-block align-top pr-6",
    defaultValue: staffProfile.passportExpiryDate,

    containerClassName: "w-full",
    minDate: getTodayDate(),
    wrapperClassName: "w-full",
    className: "w-full",
    placeholder: "Input passport expiry date",
  },
  {
    name: "passportNumber",
    label: "Passport number *",
    type: FieldType.Input,
    containerClassName: "md:w-full",
    className: " mt-1",
    parentFormContainerClassName: "md:w-1/2 w-full inline-block align-top pr-6",

    defaultValue: staffProfile.passportNumber,
    placeholder: "Input passport number",
  },
];

export interface AdminUpdateProps {
  user: User;
  onSubmitFn: (data: FormData) => Promise<void>;
  isSuperAdmin?: boolean;
  refreshData?: () => void;
}

export default function AdminUpdateResidencyAndWorkRights({
  user,
  onSubmitFn,
}: AdminUpdateProps) {
  const {
    setError,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    trigger,
    ...formMethods
  } = useForm<FormData>({ shouldUnregister: true });
  const { staffProfile } = user;

  const [residency, setResidency] = useState<RESIDENCY_STATUS | undefined>(
    undefined
  );

  const residencyStatus = watch("residencyStatus");

  useEffect(() => {
    setResidency(residencyStatus || staffProfile.residencyStatus);
  }, [residencyStatus, staffProfile.residencyStatus]);

  const onSubmit = async (data: FormData) => onSubmitFn(data);
  const getFields = useCallback(() => {
    if (residency === RESIDENCY_STATUS.citizen) {
      return [
        ...getRequiredFields(staffProfile),
        ...getCitizenFields(staffProfile),
        ...getPassportExpiry(staffProfile),
      ];
    }
    return [
      ...getRequiredFields(staffProfile),
      ...getLegalWorkField(staffProfile),
      ...getOtherFields(staffProfile),
    ];
  }, [residency, staffProfile]);

  return (
    <div className="bg-white rounded-md">
      <div className="pl-6 pr-0 pb-0">
        <ReusableForm
          fields={getFields()}
          onSubmit={onSubmit}
          id="edit-RESIDENCY_WORK_RIGHT"
          formClassName="pb-6"
          formMethods={{
            ...formMethods,
            setError,
            watch,
            reset,
            handleSubmit,
            formState,
            getValues,
            trigger,
          }}
        />
      </div>
    </div>
  );
}
