import { createdAccountDocumentUpdate } from "api/staff";
import { StaffProfile, User } from "api/types";
import Button from "components/Button";
import ReusableForm from "components/Form";
import Notification, { NotificationProps } from "components/Notification";
import { FieldType, FormData, RESIDENCY_STATUS } from "components/types";
import { handleResponse } from "components/utility";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ResidencyOptions,
  VisaTypeOptions,
} from "routes/onboarding/staff/steps/step1";
import { getDateAfterPeriod, getTodayDate } from "utils/util";

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear"];

export const getRequiredFields = (
  staffProfile: StaffProfile,
  isToggled: boolean
) => [
  {
    name: "residencyStatus",
    label: "Select Residency Status *",
    type: FieldType.Select,
    placeholder: "Select",
    className: "w-full ",
    requiredCondition: "This is a required field",
    options: ResidencyOptions,
    defaultValue: staffProfile.residencyStatus,
    isHiglighted: isToggled,
    parentFormContainerClassName: "w-full md:!w-1/3 pr-6",
    containerClassName: "w-full ",
    hideSearch: true,
  },
];

export const getCitizenFields = (
  staffProfile: StaffProfile,
  isToggled: boolean
) => {
  return [
    {
      type: FieldType.Paragraph,
      parentFormContainerClassName: "w-full",
      name: "",
      label: "",
      children: (
        <p className="mt-5">
          <span className="text-lg leading-6 font-semibold text-gray-900">
            Proof of citizenship
          </span>
          <p className="text-sm leading-5 font-normal text-gray-500">
            Please provide at least 1 of the 3 options
          </p>
        </p>
      ),
    },
    {
      name: "citizenShipCertificate",
      label: "Australian citizenship certificate",
      type: FieldType.FileUpload,
      parentFormContainerClassName:
        "md:w-1/3 w-full inline-block align-top pr-2 md:pr-6",
      defaultValue: staffProfile.citizenShipCertificate,
      isHiglighted: isToggled,
      externalLink:
        "https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online/visa-holders",
    },
    {
      name: "passport",
      label: "Certified copy of Passport",
      type: FieldType.FileUpload,
      parentFormContainerClassName:
        "md:w-1/3 w-full inline-block align-top pr-2 md:pr-6",
      defaultValue: staffProfile.passport,
      isHiglighted: isToggled,
      externalLink: "https://www.justice.vic.gov.au/certifiedcopies",
    },
    {
      name: "australianBirthCertificate",
      label: "Australian birth certificate",
      type: FieldType.FileUpload,
      parentFormContainerClassName:
        "md:w-1/3 w-full inline-block align-top md:pr-6",
      defaultValue: staffProfile.australianBirthCertificate,
      isHiglighted: isToggled,
      externalLink: "https://www.bdm.vic.gov.au/births/get-a-birth-certificate",
    },
  ];
};

export const getPassportExpiry = (
  staffProfile: StaffProfile,
  isToggled: boolean
) => [
  {
    name: "passportExpiryDate",
    label: "Passport expiry date *",
    type: FieldType.Calendar,
    parentFormContainerClassName: `w-full flex items-center justify-center`,
    defaultValue: staffProfile.passportExpiryDate,
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    containerClassName: "md:w-1/3  md:pr-6",
    minDate: getTodayDate(),
    wrapperClassName: "w-full",
    className: "w-full",
    placeholder: "Input passport expiry date",
  },
  {
    name: "passportNumber",
    label: "Passport number *",
    type: FieldType.Input,
    containerClassName: "md:w-1/3  md:pr-6",
    parentFormContainerClassName: "w-full flex items-center justify-center",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.passportNumber,
    placeholder: "Input passport number",
  },
];

export const getLegalWorkField = (
  staffProfile: StaffProfile,
  isToggled: boolean
) => [
  {
    name: "legallyWorkInAustralia",
    label: "Are you legally entitled to work in Australia? *",
    type: FieldType.Radio,
    className: "mt-4 w-full",
    options: [
      { label: "Yes", value: "true" },
      {
        label: "No",
        value: "false",
      },
    ],
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.legallyWorkInAustralia?.toString(),
  },
];

export const validateDate = (date: Date | null) => {
  if (date) {
    const today = new Date();
    const sixMonthsFromToday = new Date(today.setMonth(today.getMonth() + 6));
    if (date < sixMonthsFromToday) {
      return "The date cannot be before the current date and should be at least 6 months valid.";
    }
  }
  return true;
};

export const getOtherFields = (
  staffProfile: StaffProfile,
  isToggled: boolean
) => [
  {
    name: "visaType",
    label: "Visa type *",
    type: FieldType.Select,
    placeholder: "Select",
    className: "w-full ",
    containerClassName: "w-full ",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    options: VisaTypeOptions,
    parentFormContainerClassName: "md:w-1/3 w-full inline-block align-top pr-6",
    defaultValue: staffProfile.visaType,
  },
  {
    name: "visaClass",
    label: "Visa Class/Subclass i.e TU/573 *",
    type: FieldType.Input,
    className: "w-full ",
    containerClassName: "w-full ",
    placeholder: "Visa Class example",
    parentFormContainerClassName: "md:w-1/3 w-full  inline-block align-top",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.visaClass,
  },
  {
    name: "workEntitlement",
    label: "Work entitlements *",
    type: FieldType.Radio,
    className: "w-96",
    options: [
      { label: "Limited", value: "limited" },
      {
        label: "Unlimited",
        value: "unlimited",
      },
    ],
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.workEntitlement,
  },
  {
    name: "visaGrantDate",
    label: "Visa grant date *",
    type: FieldType.Calendar,
    parentFormContainerClassName:
      "md:w-1/3 w-full  inline-block align-top pr-6",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.visaGrantDate,
    maxDate: getTodayDate(),
    className: "w-full ",
    containerClassName: "w-full ",
    wrapperClassName: "w-full ",
  },
  {
    name: "visaExpiryDate",
    label: "Visa expiry date (Visa must be valid for at least 6 months)*",
    type: FieldType.Calendar,
    parentFormContainerClassName: "md:w-96 w-full  inline-block align-top",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    validateFunction: validateDate,
    defaultValue: staffProfile.visaExpiryDate,
    containerClassName: "w-full",
    wrapperClassName: "w-full ",
    className: "w-96 ",
    minDate: getDateAfterPeriod(1, 6),
  },
  {
    name: "vevoRecord",
    label: "Upload vevo *",
    type: FieldType.FileUpload,
    buttonLabel: "Attach vevo",
    placeholder: "or drag and drop",
    parentFormContainerClassName: "w-2/3",
    requiredCondition: "This is a required field",
    defaultValue: staffProfile.vevoRecord,
    isHiglighted: isToggled,
    externalLink:
      "https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online/visa-holders",
  },
  {
    name: "passport",
    label: "Certified copy of Passport *",
    type: FieldType.FileUpload,
    parentFormContainerClassName: "w-full",
    fileUploadContainerClassName: "md:w-2/3",
    defaultValue: staffProfile.passport,
    isHiglighted: isToggled,
    externalLink: "https://www.justice.vic.gov.au/certifiedcopies",
    requiredCondition: "This is a required field",
  },
  {
    name: "passportExpiryDate",
    label: "Passport expiry date *",
    type: FieldType.Calendar,
    parentFormContainerClassName: "md:w-1/3 w-full inline-block align-top pr-6",
    defaultValue: staffProfile.passportExpiryDate,
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    containerClassName: "w-full",
    minDate: getTodayDate(),
    wrapperClassName: "w-full",
    className: "w-full",
    placeholder: "Input passport expiry date",
  },
  {
    name: "passportNumber",
    label: "Passport number *",
    type: FieldType.Input,
    containerClassName: "md:w-full",
    className: " mt-1",
    parentFormContainerClassName: "md:w-1/3 w-full inline-block align-top",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.passportNumber,
    placeholder: "Input passport number",
  },
];

export interface CurrentEmployeeProps {
  user: User;
  refreshUser: () => void;
  isToggled: boolean;
}

export default function CurrentEmployeeStep1({
  user,
  refreshUser,
  isToggled,
}: CurrentEmployeeProps) {
  const {
    setError,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    trigger,
    ...formMethods
  } = useForm<FormData>({ shouldUnregister: true });
  const { staffProfile } = user;
  const [response, setResponse] = useState<NotificationProps | null>(null);

  const [legallyWorkInAustralia, setLegallyWorkInAustralia] = useState<
    string | undefined
  >(undefined);

  const [residency, setResidency] = useState<RESIDENCY_STATUS | undefined>(
    undefined
  );

  const residencyStatus = watch("residencyStatus");
  const canlegallyWorkInAustralia = watch("legallyWorkInAustralia");
  const passport = watch("passport");

  useEffect(() => {
    setResidency(residencyStatus || staffProfile.residencyStatus);
  }, [residencyStatus, staffProfile.residencyStatus]);
  useEffect(() => {
    setLegallyWorkInAustralia(
      canlegallyWorkInAustralia ||
        staffProfile.legallyWorkInAustralia?.toString()
    );
  }, [canlegallyWorkInAustralia, staffProfile.legallyWorkInAustralia]);

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    console.log(data);
    if (residencyStatus === "citizen") {
      if (
        !(
          data.citizenShipCertificate ||
          (data.passport && data.passportExpiryDate) ||
          data.australianBirthCertificate
        )
      ) {
        setResponse({
          type: "error",
          message: "Please provide at least 1 of the 3 options",
        });
        return;
      }
      data.passport?.length === 0 && delete data.passport;
    }

    const response = await createdAccountDocumentUpdate(
      1,
      data,
      FIELDS_TO_REMOVE
    );
    const isSuccess = await handleResponse(
      response,
      setError,
      setResponse,
      "Submitted successfully"
    );
    isSuccess && (await refreshUser());
  };

  const getFields = useCallback(() => {
    if (!residency && !staffProfile.residencyStatus) {
      return [...getRequiredFields(staffProfile, isToggled)];
    }
    if (residency === RESIDENCY_STATUS.citizen) {
      if (passport && passport.length > 0) {
        return [
          ...getRequiredFields(staffProfile, isToggled),
          ...getCitizenFields(staffProfile, isToggled),
          ...getPassportExpiry(staffProfile, isToggled),
        ];
      }
      return [
        ...getRequiredFields(staffProfile, isToggled),
        ...getCitizenFields(staffProfile, isToggled),
      ];
    }
    if (
      !legallyWorkInAustralia ||
      legallyWorkInAustralia?.toLowerCase() === "false"
    ) {
      return [
        ...getRequiredFields(staffProfile, isToggled),
        ...getLegalWorkField(staffProfile, isToggled),
      ];
    }
    return [
      ...getRequiredFields(staffProfile, isToggled),
      ...getLegalWorkField(staffProfile, isToggled),
      ...getOtherFields(staffProfile, isToggled),
    ];
  }, [residency, staffProfile, legallyWorkInAustralia, passport, isToggled]);

  return (
    <div className="bg-white rounded-md">
      <div className="pl-6 pr-0 pb-0">
        <div className="flex flex-col ">
          {response ? (
            <Notification
              type={response.type}
              message={response.message}
              className="!inline-flex !m-0 !mt-2 w-max"
            />
          ) : (
            <></>
          )}
        </div>
        <ReusableForm
          fields={getFields()}
          onSubmit={onSubmit}
          id="currentStaffDocument-1"
          formClassName="pb-6"
          formMethods={{
            ...formMethods,
            setError,
            watch,
            reset,
            handleSubmit,
            formState,
            getValues,
            trigger,
          }}
        />
        {legallyWorkInAustralia?.toLowerCase() === "false" && (
          <Notification
            className="!mb-5 !ml-0"
            size={24}
            type="error"
            message="Sorry you will not be able to continue completing your Staff profile. You must have Australian work rights to work in Australia. Call us on 1300 180 888 if you need further assistance."
          />
        )}
      </div>
      <div className="bg-gray-50 flex justify-end space-x-2 items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full flex-wrap md:flex-nowrap">
        <Button variant="primary" type="submit" form={`currentStaffDocument-1`}>
          Submit
        </Button>
      </div>
    </div>
  );
}
