import { NewEmployedStaffDocuments, UserStatus } from "api/types";
import { updateCurrentStaffDocumentPopupWarning } from "api/user";
import Toggle from "components/Toggle";
import React, { useEffect, useState } from "react";
import CurrentEmployeeStep1 from "routes/currentEmployeeOnboarding/CurrentEmployeeStep1";
import CurrentEmployeeStep2 from "routes/currentEmployeeOnboarding/CurrentEmployeeStep2";
import CurrentEmployeeStep3 from "routes/currentEmployeeOnboarding/CurrentEmployeeStep3";
import CurrentEmployeeStep4 from "routes/currentEmployeeOnboarding/CurrentEmployeeStep4";
import CurrentEmployeeStep5 from "routes/currentEmployeeOnboarding/CurrentEmployeeStep5";
import CurrentEmployeeStep6 from "routes/currentEmployeeOnboarding/CurrentEmployeeStep6";
import CurrentEmployeeStep7 from "routes/currentEmployeeOnboarding/CurrentEmployeeStep7";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Notification from "../../components/Notification";
import NotificationBar from "../../components/NotificationBar";
import { UserContextData, useUser } from "../../context/UserContext"; // Assuming you store user data in context
import CurrentEmployeeStep8 from "./CurrentEmployeeStep8";
import CurrentEmployeeStep9 from "./CurrentEmployeeStep9";

const expiredDocumentsConfig: {
  [a: number]: {
    notificationTitle: string;
    modalTitle: string;
  };
} = {
  1: {
    notificationTitle:
      "Please complete your profile by proving one of the following documents for your Australian residency status and work rights",
    modalTitle: "Residency status and work rights",
  },
  2: {
    notificationTitle:
      "Please complete your profile by proving the following personal details",
    modalTitle: "Personal details",
  },
  3: {
    notificationTitle:
      "Please complete your profile by proving your health declaration and emergency contact details",
    modalTitle: "Health declaration and emergency contact details",
  },
  4: {
    notificationTitle:
      "Please complete your profile by proving your training and qualification details",
    modalTitle: "Training and qualification details",
  },
  5: {
    notificationTitle:
      "Please complete your profile by proving your current Police check details",
    modalTitle: "Police check details",
  },
  6: {
    notificationTitle:
      "Please complete your profile by proving your Bank, tax file number and superannuation details",
    modalTitle: "Bank, tax file number and superannuation details",
  },
  7: {
    notificationTitle:
      "Please complete your profile by proving your vehicle and driving licence details",
    modalTitle: "Vehicle and driving licence details",
  },
  8: {
    notificationTitle:
      "Please complete your profile by proving your going through employee handbook and code of conduct declaration",
    modalTitle: "Employee handbook and Code of conduct declaration",
  },
  9: {
    notificationTitle: "Please complete your profile by proving your contract",
    modalTitle: "Contract",
  },
};

const documentList = Array.from({ length: 2 }, (_, i) =>
  [
    "Residency status and work rights",
    "Personal details",
    "Health declaration and emergency contact details",
    "Training and qualifications",
    "Police check",
    "Bank, tax file number and superannuation details",
    "Vehicle and driving licence details",
    "Employee handbook and Code of conduct declaration",
    "Contract",
  ].slice(i * 5, i * 5 + 5)
);

const NewEmployedStaffDocument: React.FC = () => {
  const { user, loading, refreshUser } = useUser() as UserContextData;
  const [isOpen, setIsOpen] = useState(false);
  const [stepNumber, setStepNumber] = useState(1);
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    if (user && user.createdAccount && user.status !== UserStatus.Completed) {
      if (user && !user.preferences?.firstLoginPopup) {
        setStepNumber(0);
      } else if (user && user.createdStaffStatus) {
        setStepNumber(user.createdStaffStatus + 1);
      } else {
        setStepNumber(1);
      }
      setIsOpen(true);
    }
  }, [user]);

  const handleCloseModal = () => {
    setIsOpen(false);
    if (user && user.preferences && !user.preferences.firstLoginPopup) {
      setStepNumber(0);
    }
  };

  if (
    loading ||
    !user?.id ||
    !user.createdAccount ||
    user.status === UserStatus.Completed ||
    user.createdStaffStatus >= 9
  ) {
    return null; // or a loading spinner
  }

  const getCurrentEmployeeStep = (
    stepNumber: NewEmployedStaffDocuments,
    isToggled: boolean
  ) => {
    switch (stepNumber) {
      case NewEmployedStaffDocuments.Residency:
        return (
          <CurrentEmployeeStep1
            user={user}
            refreshUser={refreshUser}
            isToggled={isToggled}
          />
        );
      case NewEmployedStaffDocuments.PersonalDetails:
        return (
          <CurrentEmployeeStep2
            user={user}
            refreshUser={refreshUser}
            isToggled={isToggled}
          />
        );
      case NewEmployedStaffDocuments.HealthDeclaration:
        return (
          <CurrentEmployeeStep3
            user={user}
            refreshUser={refreshUser}
            isToggled={isToggled}
          />
        );
      case NewEmployedStaffDocuments.TrainingAndQualification:
        return (
          <CurrentEmployeeStep4
            user={user}
            refreshUser={refreshUser}
            isToggled={isToggled}
          />
        );
      case NewEmployedStaffDocuments.PoliceCheck:
        return (
          <CurrentEmployeeStep5
            user={user}
            refreshUser={refreshUser}
            isToggled={isToggled}
          />
        );
      case NewEmployedStaffDocuments.BankDetails:
        return (
          <CurrentEmployeeStep6
            user={user}
            refreshUser={refreshUser}
            isToggled={isToggled}
          />
        );
      case NewEmployedStaffDocuments.VehicleDetails:
        return (
          <CurrentEmployeeStep7
            user={user}
            refreshUser={refreshUser}
            isToggled={isToggled}
          />
        );
      case NewEmployedStaffDocuments.EmployeeHandBook:
        return (
          <CurrentEmployeeStep8
            user={user}
            refreshUser={refreshUser}
            isToggled={isToggled}
          />
        );
      case NewEmployedStaffDocuments.Contract:
        return (
          <CurrentEmployeeStep9
            user={user}
            refreshUser={refreshUser}
            isToggled={isToggled}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      <NotificationBar
        message="Your EICARE staff account profile is not complete. Please provide your information to complete your account profile!"
        iconName="exclamation"
        messageClassName="!text-yellow-900"
        iconClassName="!text-yellow-500"
        onActionClick={() => setIsOpen(true)}
        actionText="Provide Information"
      />
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={handleCloseModal}
          modalHeader={
            stepNumber ? (
              <div className="flex justify-between items-center w-full">
                <span>
                  {expiredDocumentsConfig[stepNumber]?.modalTitle || ""}
                </span>
                <div className="mr-20 flex items-center">
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="flex items-center"
                  >
                    <Toggle
                      isChecked={isToggled}
                      onChange={(checked: boolean) => setIsToggled(checked)}
                    />
                  </div>
                  <span className="ml-2 text-xs leading-4 font-semibold text-gray-900">
                    Show required fields
                  </span>
                </div>
              </div>
            ) : null
          }
          className="large"
          modalBodyClassName="!m-0"
          modalHeaderClassName="!px-6 !py-5 border-b border-gray-200 w-full"
          modalContentClassName="!p-0"
          modalHeaderTitleClassName="!m-0 w-full"
          customModalStyles={{ maxWidth: "1216px", width: "1216px" }}
          modalContainerClassName="!items-start"
        >
          {stepNumber === 0 ? (
            <div>
              <div className="flex m-6 space-x-4">
                <div>
                  <img
                    src="/images/ei-care-logo-svg.svg"
                    alt="EICARE"
                    className="object-fit w-80"
                  />
                </div>
                <div>
                  <h3 className="text-lg leading-6 font-semibold mt-1">
                    Hi {user.firstName} {user.lastName}
                  </h3>
                  <h5 className="text-primary-600 text-sm leading-5 font-semibold mt-6">
                    Welcome to your new EICARE staff account!
                  </h5>
                  <p className="text-sm leading-5 font-normal mt-2">
                    Your staff account has been setup but your profile may not
                    yet be completed.
                  </p>
                  <p className="text-sm leading-5 font-normal mt-2">
                    Bare with us as we collect information from you to complete
                    your EICARE staff account profile. Depending on how much
                    information the Administration team has added to your
                    account, you will see a number of popup forms requesting you
                    to provide some information.
                  </p>
                  <p className="text-sm leading-5 font-normal mt-2">
                    You can close the the popup to fill in the details later but
                    these forms will keep showing until you complete filling
                    them in.
                  </p>
                </div>
              </div>
              <p className="mt-6 mb-3 text-sm leading-5 font-semibold mx-6">
                Please complete and or double check that your profile
                information is correct and submit forms:
              </p>
              <div className="mx-6 mb-6 border border-gray-200 rounded-md">
                <div className="grid grid-cols-2 gap-x-8 p-5">
                  {documentList.map((column, columnIndex) => (
                    <div key={columnIndex} className="space-y-4">
                      {column.map((item, itemIndex) => (
                        <div
                          key={itemIndex}
                          className="flex items-center space-x-2 p-2"
                        >
                          <span className="bg-primary-100 text-gray-600 text-xs leading-4 font-extrabold rounded-full w-5 h-5 flex items-center justify-center">
                            {columnIndex * 5 + itemIndex + 1}
                          </span>
                          <span className="text-sm leading-5 font-medium text-gray-900">
                            {item}
                          </span>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div className="my-6 px-5">
                <Button
                  variant="primary"
                  className="w-full"
                  size="large"
                  onClick={async () => {
                    await updateCurrentStaffDocumentPopupWarning();
                    await refreshUser();
                    setStepNumber(1);
                  }}
                >
                  Get started
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div className="px-6 pt-6">
                <Notification
                  type="info"
                  message={expiredDocumentsConfig[stepNumber].notificationTitle}
                  className="!m-0 inline-flex"
                />
              </div>

              {getCurrentEmployeeStep(stepNumber, isToggled)}
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default NewEmployedStaffDocument;
