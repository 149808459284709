import { Staff, User, UserStatus } from "api/types";
import classNames from "classnames";
import React, { useState } from "react";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { formatDate, getDifferenceForDates } from "utils/util";
import Badge, { BadgeProps } from "./Badge";
import Button from "./Button";
import { Icon } from "./Icon";

interface StaffDetailProps {
  staff: Staff;
}

export enum ExpiryStatus {
  VALID = "VALID",
  ABOUT_TO_EXPIRE = "ABOUT_TO_EXPIRE",
  ABOUT_TO_EXPIRE_IN_1_MONTH = "ABOUT_TO_EXPIRE_IN_1_MONTH",
  ABOUT_TO_EXPIRE_IN_2_MONTH = "ABOUT_TO_EXPIRE_IN_2_MONTH",
  EXPIRED = "Expired",
  NA = "NA",
}

export const checkExpiry = (expiryDate: string | undefined) => {
  if (!expiryDate) {
    return ExpiryStatus.NA;
  }
  const { diffInDays } = getDifferenceForDates(new Date(expiryDate));
  if (diffInDays < 0) {
    return ExpiryStatus.EXPIRED;
  } else if (diffInDays < 31) {
    return ExpiryStatus.ABOUT_TO_EXPIRE_IN_1_MONTH;
  } else if (diffInDays < 91) {
    return ExpiryStatus.ABOUT_TO_EXPIRE_IN_2_MONTH;
  }
  return ExpiryStatus.VALID;
};

const getPropsForExpiry = (
  expiryStatus: ExpiryStatus,
  status: string
): BadgeProps => {
  if (expiryStatus === ExpiryStatus.EXPIRED) {
    return {
      iconName: "info",
      message: status,
      className:
        "bg-red-100 !text-red-800 text-xs leading-4 font-medium uppercase !rounded-none",
      statusClassName: "!m-0 !mr-1",
      isIconOnRight: true,
      iconClassName: "text-red-700",
      size: 10,
    };
  }
  if (expiryStatus === ExpiryStatus.ABOUT_TO_EXPIRE_IN_2_MONTH) {
    return {
      iconName: "info",
      message: status,
      className:
        "bg-yellow-100 text-yellow-800 text-xs leading-4 font-medium uppercase !rounded-none",
      statusClassName: "!m-0 !mr-1",
      isIconOnRight: true,
      iconClassName: "!text-yellow-700",
      size: 10,
    };
  }
  if (expiryStatus === ExpiryStatus.ABOUT_TO_EXPIRE_IN_1_MONTH) {
    return {
      iconName: "info",
      message: status,
      className:
        "bg-orange-100 text-orange-700 text-xs leading-4 font-medium uppercase !rounded-none",
      statusClassName: "!m-0 !mr-1",
      isIconOnRight: true,
      iconClassName: "!text-orange-600",
      size: 10,
    };
  }
  return {
    message: status,
    className:
      "text-xs leading-4 font-medium tracking-wider uppercase !text-gray-700 !px-0 !rounded-none",
    statusClassName: "!m-0",
    size: 10,
  };
};

export const getContentByExpiry = (expiryStatus: ExpiryStatus) => {
  if (expiryStatus === ExpiryStatus.ABOUT_TO_EXPIRE_IN_1_MONTH) {
    return "Document expires within 1 month ";
  }
  if (expiryStatus === ExpiryStatus.ABOUT_TO_EXPIRE_IN_2_MONTH) {
    return "Document expires within 2-3 months";
  }
  if (expiryStatus === ExpiryStatus.EXPIRED) {
    return "Document has expired";
  }
  return "";
};

export const getAvatarName = (person: Staff | User) => {
  if (person.firstName && person.lastName) {
    return `${person.firstName} ${person.lastName}`;
  }
  if (person.username) {
    return person.username;
  }
  return person.email;
};

export const getProfileStatusBadgeProps = (status: UserStatus): BadgeProps => {
  switch (status) {
    case UserStatus.Completed:
      return {
        iconName: "check",
        message: status,
        className: "bg-green-100 text-green-800",
      };
    case UserStatus.In_Review:
      return {
        iconName: "solidCheck",
        message: status.split("_").join(" "),
        className: "bg-purple-100 text-purple-800",
        iconClassName: "text-purple-800",
        buttonClassName: "-mt-1",
        size: 12,
      };
    case UserStatus.In_Progress:
      return {
        iconName: "inProgress",
        message: status.split("_").join(" "),
        className: "bg-blue-100 text-blue-800",
        iconClassName: "text-blue-800",
        size: 12,
      };
    case UserStatus.Invited:
      return {
        iconName: "invited",
        message: status,
        className: "bg-pink-100 text-pink-800",
      };
    default:
      return {
        iconName: "hazardWarning",
        message: "Cannot Complete",
        className: "bg-yellow-100 text-yellow-800",
        iconClassName: "text-yellow-800",
        size: 12,
      };
  }
};

const StaffDetail: React.FC<StaffDetailProps> = ({
  staff,
}: StaffDetailProps) => {
  const [isShowMoreDetails, setIsShowMoreDetails] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      className={classNames(
        "my-5 bg-white shadow rounded-md",
        staff.archivedAt && "!bg-gray-200"
      )}
    >
      <div className="p-6 border-b border-gray-200 flex items-center justify-between flex-col md:flex-row">
        <div className="flex items-start justify-start w-full">
          <Avatar
            name={getAvatarName(staff)}
            size="40"
            round
            textSizeRatio={2.5}
            className="!h-10 !w-10 !text-base !leading-6 !font-normal !text-primary-900"
            color="#D1F5FC"
            fgColor="#194860"
            src={staff.avatar}
          />
          <div className="flex flex-col items-start ml-4 w-36">
            <span className="text-sm leading-5 font-semibold text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap text-left w-36">{`${staff.title} ${staff.firstName} ${staff.lastName}`}</span>
            <span className="text-sm leading-5 font-normal text-gray-700   overflow-hidden text-ellipsis whitespace-nowrap text-left w-36">
              {staff.staffProfile?.occupation}
            </span>
          </div>
          <div className="flex flex-col items-start ml-6  w-36">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              PROFILE Status
            </span>
            <Badge {...getProfileStatusBadgeProps(staff.status)} />
          </div>

          <div className="flex flex-col justify-start items-start flex-wrap ml-6  w-28">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Phone:
            </span>
            <span className="text-sm leading-5 font-semibold text-gray-900">
              {staff.mobile || "N/A"}
            </span>
          </div>
          <div className="flex flex-col justify-start items-start flex-wrap ml-6 flex-1">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Email:
            </span>
            <span className="text-sm leading-5 font-semibold text-gray-900">
              {staff.email}
            </span>
          </div>
          <Badge
            message={
              staff.createdAccount ? "Created Account" : "Invited Account"
            }
            className="bg-blue-100 text-blue-800"
          />
          {staff.archivedAt && (
            <Badge
              iconName="archive"
              message="Archived"
              className="bg-pink-100 text-pink-800"
            />
          )}
        </div>
      </div>

      <div className="p-6 flex items-center justify-between flex-wrap md:flex-nowrap">
        <div className="flex flex-col items-start w-1/2 md:w-1/5">
          <div
            className="text-gray-500"
            data-tooltip-id="visaExpiryDate"
            data-tooltip-content={getContentByExpiry(
              checkExpiry(staff.staffProfile?.visaExpiryDate)
            )}
          >
            <Badge
              {...getPropsForExpiry(
                checkExpiry(staff.staffProfile?.visaExpiryDate),
                "visa expiry date"
              )}
            />
            <Tooltip id="visaExpiryDate" />
          </div>
          <span className="text-sm leading-5 font-medium text-gray-900">
            {staff.staffProfile?.visaExpiryDate
              ? formatDate(new Date(staff.staffProfile.visaExpiryDate))
              : "N/A"}
          </span>
        </div>

        <div className="flex flex-col items-start w-1/2 md:w-1/5">
          <div
            className="text-gray-500"
            data-tooltip-id="policeCheckValidUntil"
            data-tooltip-content={getContentByExpiry(
              checkExpiry(staff.staffProfile?.policeCheckValidUntil)
            )}
          >
            <Badge
              {...getPropsForExpiry(
                checkExpiry(staff.staffProfile?.policeCheckValidUntil),
                "Police check expiry"
              )}
            />
            <Tooltip id="policeCheckValidUntil" />
          </div>
          <span className="text-sm leading-5 font-medium text-gray-900">
            {staff.staffProfile?.policeCheckValidUntil
              ? formatDate(new Date(staff.staffProfile?.policeCheckValidUntil))
              : "N/A"}
          </span>
        </div>

        <div className="flex flex-col items-start w-1/2 md:w-1/6">
          <span className="flex justify-between">
            <div
              className="text-gray-500"
              data-tooltip-id="vehicleRegistrationExpiry"
              data-tooltip-content={getContentByExpiry(
                checkExpiry(
                  staff.staffProfile?.vehicleDetails?.vehicleRegistrationExpiry
                )
              )}
            >
              <Badge
                {...getPropsForExpiry(
                  checkExpiry(
                    staff.staffProfile?.vehicleDetails
                      ?.vehicleRegistrationExpiry
                  ),
                  "Rego expiry"
                )}
              />
              <Tooltip id="vehicleRegistrationExpiry" />
            </div>
          </span>
          <span className="text-sm leading-5 font-medium text-gray-900">
            {staff.staffProfile?.vehicleDetails?.vehicleRegistrationExpiry
              ? formatDate(
                  new Date(
                    staff.staffProfile?.vehicleDetails?.vehicleRegistrationExpiry
                  )
                )
              : "N/A"}
          </span>
        </div>

        <div className="flex flex-col items-start w-1/2 md:w-1/5">
          <div
            className="text-gray-500"
            data-tooltip-id="drivingLicenseExpiry"
            data-tooltip-content={getContentByExpiry(
              checkExpiry(
                staff.staffProfile?.vehicleDetails?.drivingLicenseExpiry
              )
            )}
          >
            <Badge
              {...getPropsForExpiry(
                checkExpiry(
                  staff.staffProfile?.vehicleDetails?.drivingLicenseExpiry
                ),
                "D. Licence expiry"
              )}
            />
            <Tooltip id="drivingLicenseExpiry" />
          </div>
          <span className="text-sm leading-5 font-medium text-gray-900">
            {staff.staffProfile?.vehicleDetails?.drivingLicenseExpiry
              ? formatDate(
                  new Date(
                    staff.staffProfile?.vehicleDetails?.drivingLicenseExpiry
                  )
                )
              : "N/A"}
          </span>
        </div>

        <div className="flex flex-col items-start w-1/2 md:w-1/5">
          <div
            className="text-gray-500"
            data-tooltip-id="vehicleInsuranceExpiry"
            data-tooltip-content={getContentByExpiry(
              checkExpiry(
                staff.staffProfile?.vehicleDetails?.vehicleInsuranceExpiry
              )
            )}
          >
            <Badge
              {...getPropsForExpiry(
                checkExpiry(
                  staff.staffProfile?.vehicleDetails?.vehicleInsuranceExpiry
                ),
                "Insurance expiry"
              )}
            />
            <Tooltip id="vehicleInsuranceExpiry" />
          </div>
          <span className="text-sm leading-5 font-medium text-gray-900">
            {staff.staffProfile?.vehicleDetails?.vehicleInsuranceExpiry
              ? formatDate(
                  new Date(
                    staff.staffProfile?.vehicleDetails?.vehicleInsuranceExpiry
                  )
                )
              : "N/A"}
          </span>
        </div>
      </div>

      {isShowMoreDetails && (
        <>
          <div className="p-6 flex items-center justify-between flex-wrap md:flex-nowrap">
            <div className="flex flex-col items-start w-1/2 md:w-1/5">
              <div
                className="text-gray-500"
                data-tooltip-id="passportExpiryDate"
                data-tooltip-content={getContentByExpiry(
                  checkExpiry(staff.staffProfile?.passportExpiryDate)
                )}
              >
                <Badge
                  {...getPropsForExpiry(
                    checkExpiry(staff.staffProfile?.passportExpiryDate),
                    "Passport expiry"
                  )}
                />
                <Tooltip id="passportExpiryDate" />
              </div>
              <span className="text-sm leading-5 font-medium text-gray-900">
                {staff.staffProfile?.passportExpiryDate
                  ? formatDate(new Date(staff.staffProfile?.passportExpiryDate))
                  : "N/A"}
              </span>
            </div>

            <div className="flex flex-col items-start md:w-1/5 w-1/2">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Emergency Contact
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900">
                {staff.staffProfile?.emergencyContact?.firstName || "N/A"}
              </span>
            </div>

            <div className="flex flex-col items-start md:w-1/6 w-1/2">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Emergency Phone
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900">
                {staff.staffProfile?.emergencyContact?.mobile || "N/A"}
              </span>
            </div>

            <div className="flex flex-col items-start md:w-1/5 w-1/2">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Visa type
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900">
                {staff.staffProfile?.visaType || "N/A"}
              </span>
            </div>

            <div className="flex flex-col items-start md:w-1/5 w-1/2">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Residency Status
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900 capitalize">
                {staff.staffProfile?.residencyStatus?.replace("_", " ") ||
                  "N/A"}
              </span>
            </div>
          </div>
          {staff.failureReasons?.length > 0 && (
            <div className="px-5 py-4">
              <div className="flex items-center w-full">
                <span className="text-base leading-5 font-medium mr-1">
                  Failure reasons
                </span>
                <span className="border border-gray-300 flex-1"></span>
              </div>
              <div className="flex justify-start items-start mt-5">
                {staff.failureReasons.map((reason) => (
                  <Badge
                    message={reason.split("_").join(" ")}
                    iconName="hazardWarning"
                    className="bg-yellow-100 text-yellow-800 mr-4"
                    iconClassName="text-yellow-800"
                    size={12}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}

      <div
        className={classNames(
          "flex py-3 px-6 justify-between items-center border border-gray-200 bg-gray-50 rounded-b-md ",
          staff.archivedAt && "!bg-gray-100"
        )}
      >
        <Button
          variant="gray"
          className="flex items-center cursor-pointer space-x-2"
          onClick={() => setIsShowMoreDetails(!isShowMoreDetails)}
        >
          <Icon
            name="downChevron"
            size={16}
            className={classNames(isShowMoreDetails && "animate rotate-180")}
          />
          <span className="text-sm leading-5 font-semibold">
            {isShowMoreDetails ? "Show less" : "Show more"}
          </span>
        </Button>
        <Button
          variant="text"
          onClick={() => navigate(`/staff/view-profile/${staff.id}`)}
        >
          View Details
        </Button>
      </div>
    </div>
  );
};

export default StaffDetail;
