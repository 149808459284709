// components/Pagination.tsx
import classNames from "classnames";
import React from "react";
import Button from "./Button";
import { Icon } from "./Icon";
import Select, { Option } from "./Select";
import { FieldType } from "./types";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  itemsPerPage: number;
  onItemsPerPageChange: (itemsPerPage: number) => void;
  onPreviousPage: () => void;
  onNextPage: () => void;
  containerClassName?: string;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  onItemsPerPageChange,
  onPreviousPage,
  onNextPage,
  containerClassName,
}) => {
  const generatePageNumbers = () => {
    const siblingCount = 1;
    const totalNumbers = siblingCount * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - siblingCount);
      const endPage = Math.min(totalPages - 1, currentPage + siblingCount);
      const pages = Array.from(
        { length: endPage - startPage + 1 },
        (_, index) => startPage + index
      );

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = endPage < totalPages - 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = Array.from(
            { length: spillOffset },
            (_, index) => startPage - spillOffset + index
          );
          return [1, "...", ...extraPages, ...pages, totalPages];
        }
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = Array.from(
            { length: spillOffset },
            (_, index) => endPage + index + 1
          );
          return [1, ...pages, ...extraPages, "...", totalPages];
        }
        case hasLeftSpill && hasRightSpill:
        default: {
          return [1, "...", ...pages, "...", totalPages];
        }
      }
    }

    return Array.from({ length: totalPages }, (_, index) => index + 1);
  };

  const pageNumbers = generatePageNumbers();

  return (
    <div className="flex justify-between items-center">
      <div
        className={classNames(
          "flex justify-start items-center space-x-2 mt-4",
          containerClassName
        )}
      >
        <span className="text-gray-500 text-sm leading-5 font-medium">
          Page {currentPage} of {totalPages}
        </span>
        <Select
          className="ml-2 border border-gray-300 rounded-md"
          onChange={(option: Option) =>
            onItemsPerPageChange(Number(option.value))
          }
          type={FieldType.Select}
          label=""
          name="itemPerPage"
          options={[
            { label: "10", value: 10 },
            { label: "20", value: 20 },
            { label: "30", value: 30 },
            { label: "40", value: 40 },
            { label: "50", value: 50 },
          ]}
          containerClassName="w-16 !my-0"
          hideSearch
          defaultValue={itemsPerPage}
          dropDownClassName="!min-w-20"
        />
        <span className="text-gray-500 text-sm leading-5 font-medium">
          Items per page
        </span>
      </div>
      <nav
        className={classNames(
          "flex flex-1 -space-x-px rounded-md justify-between items-center mt-4",
          containerClassName
        )}
        aria-label="Pagination"
      >
        <div className="flex flex-1 justify-center items-center space-x-2">
          {pageNumbers.map((page, index) =>
            typeof page === "number" ? (
              <Button
                variant={currentPage === page ? "text" : "gray"}
                className={`!py-2 rounded-md font-semibold w-9 h-9 ${
                  currentPage === page
                    ? "bg-primary-100 outline outline-offset-2 outline-primary-500"
                    : ""
                }`}
                onClick={() => onPageChange(page)}
                key={index}
              >
                {page}
              </Button>
            ) : (
              <Button
                variant="icon"
                size="noSize"
                key={index}
                className="px-4 py-2 text-gray-600  w-9 h-9"
              >
                {page}
              </Button>
            )
          )}
        </div>
        <div className="flex justify-between items-center space-x-3">
          <Button
            variant="white"
            onClick={() => onPreviousPage()}
            disabled={currentPage === 1}
            className="space-x-2"
          >
            <Icon
              name="downChevron"
              className="text-gray-600 animate rotate-90"
              size={16}
            />
            <span className="text-sm leading-5 font-semibold text-gray-800">
              Previous
            </span>
          </Button>
          <Button
            variant="white"
            onClick={() => onNextPage()}
            disabled={currentPage === totalPages}
            className="space-x-2"
          >
            <span className="text-sm leading-5 font-semibold text-gray-800">
              Next
            </span>
            <Icon
              name="downChevron"
              className="text-gray-600 animate -rotate-90"
              size={16}
            />
          </Button>
        </div>
      </nav>
    </div>
  );
};

export default Pagination;
