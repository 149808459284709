import { UserStatus } from "api/types";
import { redirect } from "react-router-dom";
import { LoaderProps } from "routes/root";

export async function DashboardLoader({ params, request, user }: LoaderProps) {
  const authData = window.localStorage.getItem("eicare_auth");
  const url = new URL(request.url);

  if (authData) {
    const auth = JSON.parse(authData);
    if (auth.session) {
      try {
        if (user.createdAccount) {
          return {
            user,
          };
        }
        if (user.status !== UserStatus.Completed) {
          const { staffProfile } = user;
          if (
            user.status === UserStatus.In_Progress &&
            staffProfile.onboardingStatus === 11
          ) {
            return redirect(`/onboarding/staff/reviewDetails`);
          }
          if (
            user.status === UserStatus.In_Review &&
            user.staffProfile.onboardingStatus === 11
          ) {
            return redirect("/onboarding/staff/onBoardingCompletion");
          }
          return redirect(
            `/onboarding/staff/step/${staffProfile.onboardingStatus}`
          );
        }
        return {
          user,
        };
      } catch (error) {
        console.log("error", error);
        return redirect(
          `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
        );
      }
    }
  }
  return redirect(
    `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
  );
}
