import { StaffProfile, User } from "api/types";
import { Option } from "components/Select";
import { FieldType } from "components/types";
import { toZonedTime } from "date-fns-tz";
import {
  capitalizeFirstLetter,
  formatDate,
  formatDateWithDay,
  formatDateWithTime,
  getFormattedDate,
  getTodayDate,
  getTomorrowDate,
  isBoolean,
  maskString,
  validateAustralianPhoneNumber,
} from "../../utils/util";

export const getPersonalInformationData = (user: User) => [
  [
    { title: "title", value: user.title, columnWidth: 5 },
    { title: "firstName", value: user.firstName, columnWidth: 6 },
    { title: "lastName", value: user.lastName, columnWidth: 6 },
    { title: "email", value: user.email, columnWidth: 4 },
    { title: "mobile number", value: user.mobile || "N/A", columnWidth: 5 },
  ],
  [
    {
      title: "Home telephone number",
      value: user.staffProfile?.homePhone || "N/A",
      columnWidth: 5,
    },
    {
      title: "Occupation",
      value: capitalizeFirstLetter(user.staffProfile?.occupation),
      columnWidth: 6,
    },
    {
      title: "Residential address",
      value: user.staffProfile.residentialAddressStreetName
        ? capitalizeFirstLetter(
            `${user.staffProfile.residentialAddressBuildingNo} ${user.staffProfile.residentialAddressStreetName}, ${user.staffProfile.residentialAddressSuburb}, ${user.staffProfile.residentialAddressState} ${user.staffProfile.residentialAddressZip}`
          )
        : "N/A",
      columnWidth: 6,
    },
    {
      title: "Mailing addresss",
      value: user.staffProfile.mailingAddressStreetName
        ? capitalizeFirstLetter(
            `${user.staffProfile.mailingAddressBuildingNo} ${user.staffProfile.mailingAddressStreetName}, ${user.staffProfile.mailingAddressSuburb}, ${user.staffProfile.mailingAddressState} ${user.staffProfile.mailingAddressZip}`
          )
        : "N/A",
      columnWidth: 4,
    },
    {
      title: "Gender",
      value: capitalizeFirstLetter(user.staffProfile?.gender || "N/A"),
      columnWidth: 5,
    },
  ],
  [
    {
      title: "Date of birth",
      value: user.staffProfile?.dob
        ? formatDate(new Date(user.staffProfile?.dob))
        : "N/A",
      columnWidth: 5,
    },
    {
      title: "Country of birth",
      value: capitalizeFirstLetter(user.staffProfile?.birthCountry || "N/A"),
      columnWidth: 6,
    },
    {
      title: "Home region",
      value: capitalizeFirstLetter(user.staffProfile?.homeRegion || "N/A"),
      columnWidth: 6,
    },
    {
      title: "Spoken language(s)",
      value: user.staffProfile?.spokenLanguages?.join(", ") || "N/A",
      columnWidth: 4,
    },
    {
      title: "I identify as Aboriginal/TSI",
      value: user.staffProfile?.aboriginalTSIStatus || "N/A",
      columnWidth: 5,
    },
  ],
];
export const getEmergencyContactData = (emergencyContact: User) => [
  [
    {
      title: "email",
      value: capitalizeFirstLetter(emergencyContact?.email) || "N/A",
    },
    { title: "mobile number", value: emergencyContact?.mobile || "N/A" },
    {
      title: "Home telephone number",
      value: emergencyContact?.homeTelephoneNo || "N/A",
    },
    {
      title: "Relationship",
      value: capitalizeFirstLetter(emergencyContact?.relationShip) || "N/A",
    },
  ],
];
export const getStatutoryDeclarationData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Statutory declaration",
      value: staffProfile?.signedStatutoryForm
        ? [staffProfile?.signedStatutoryForm]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.signedStatutoryForm),
    },
  ],
];
export const getTrainingData = (
  staffProfile: StaffProfile,
  isAdminView: boolean
) => [
  [
    {
      title: "Highest qualification in your country",
      value: capitalizeFirstLetter(staffProfile?.highestQualification) || "N/A",
    },
    {
      title: "School for highest qualification",
      value:
        capitalizeFirstLetter(staffProfile?.highestQualificationSchool) ||
        "N/A",
    },
    {
      title: "Work-related certificates",
      value: staffProfile?.workCertificates
        ? staffProfile?.workCertificates
        : "N/A",
      isTypeFile: Boolean(staffProfile?.workCertificates),
    },
    {
      title: "Food handling certificate",
      value: staffProfile?.foodHandlingCertificate
        ? staffProfile?.foodHandlingCertificate
        : "N/A",
      isTypeFile: Boolean(staffProfile?.foodHandlingCertificate),
    },
  ],
  [
    {
      title: "Infection control training",
      value: staffProfile?.infectionControlCertificate
        ? staffProfile?.infectionControlCertificate
        : "N/A",
      isTypeFile: Boolean(staffProfile?.infectionControlCertificate),
    },
    {
      title: "Resume",
      value: staffProfile?.resume ? [staffProfile?.resume] : "N/A",
      isTypeFile: Boolean(staffProfile?.resume),
    },
    {
      title: "Aged care certificate (AHPRA)",
      value: staffProfile?.ahpra ? [staffProfile?.ahpra] : "N/A",
      isTypeFile: Boolean(staffProfile?.ahpra),
      cellClassName: !isAdminView ? "hidden" : "",
    },
    {
      title: "Aged care certificate (AHPRA) date",
      value: Boolean(staffProfile.ahpraDate)
        ? formatDate(new Date(staffProfile.ahpraDate))
        : "N/A",
      cellClassName: !isAdminView ? "hidden" : "",
    },
  ],
];
export const getWorkRightsData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Australian Residency status",
      value: capitalizeFirstLetter(
        staffProfile?.residencyStatus?.replace("_", " ") || "N/A"
      ),
      cellClassName: "md:!w-76",
    },
    {
      title: "Legally entitled to work in Australia",
      value: isBoolean(staffProfile?.legallyWorkInAustralia)
        ? staffProfile?.legallyWorkInAustralia
          ? "Yes"
          : "No"
        : "N/A",
      cellClassName: "md:!w-80",
    },
    {
      title: "Visa type",
      value: capitalizeFirstLetter(staffProfile?.visaType) || "N/A",
      cellClassName: "md:!w-64",
    },
    {
      title: "Visa Class/Subclass i.e TU/573",
      value: staffProfile?.visaClass || "N/A",
      cellClassName: "md:!w-72",
    },
  ],
  [
    {
      title: "Work entitlements",
      value: capitalizeFirstLetter(staffProfile?.workEntitlement) || "N/A",
      cellClassName: "md:!w-76",
    },
    {
      title: "Visa grant date",
      value: staffProfile?.visaGrantDate
        ? formatDate(new Date(staffProfile?.visaGrantDate))
        : "N/A",
      cellClassName: "md:!w-80",
    },
    {
      title: "Visa expiry date",
      value: staffProfile?.visaExpiryDate
        ? formatDate(new Date(staffProfile?.visaExpiryDate))
        : "N/A",
      cellClassName: "md:!w-64",
    },
    {
      title: "Vevo",
      value: staffProfile?.vevoRecord ? [staffProfile?.vevoRecord] : "N/A",
      isTypeFile: Boolean(staffProfile?.vevoRecord),
      cellClassName: "md:!w-72",
    },
  ],
  [
    {
      title: "Australian citizenship certificate",
      value: staffProfile?.citizenShipCertificate
        ? [staffProfile?.citizenShipCertificate]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.citizenShipCertificate),
      cellClassName: "md:!w-76",
    },
    {
      title: "Certified copy of Passport",
      value: staffProfile?.passport ? [staffProfile?.passport] : "N/A",
      isTypeFile: Boolean(staffProfile?.passport),
      cellClassName: "md:!w-80",
    },
    {
      title: "Australian birth certificate",
      value: staffProfile?.australianBirthCertificate
        ? [staffProfile?.australianBirthCertificate]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.australianBirthCertificate),
      cellClassName: "md:!w-64",
    },
    {
      title: "Passport expiry date",
      value: Boolean(staffProfile.passportExpiryDate)
        ? formatDate(new Date(staffProfile.passportExpiryDate))
        : "N/A",
      cellClassName: "md:!w-72",
      minDate: getTodayDate(),
    },
  ],
  [
    {
      title: "Passport number",
      value: maskString(staffProfile?.passportNumber, 3, 0) || "N/A",
      cellClassName: "md:!w-72",
    },
  ],
];
export const getBankData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Salary payment authorisation",
      value: isBoolean(staffProfile?.bankDetails?.paymentAuthorisation)
        ? staffProfile?.bankDetails?.paymentAuthorisation
          ? "I authorise and request Pay Office to deposit my salary into the bank account added below"
          : "N/A"
        : "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Bank name",
      value:
        capitalizeFirstLetter(staffProfile?.bankDetails?.bankName) || "N/A",
      cellClassName: "md:!w-64",
    },
    {
      title: "Branch address",
      value:
        capitalizeFirstLetter(staffProfile?.bankDetails?.branchAddress) ||
        "N/A",
      cellClassName: "md:!w-44",
    },
    {
      title: "Account name",
      value:
        capitalizeFirstLetter(staffProfile?.bankDetails?.accountName) || "N/A",
      cellClassName: "md:!w-44",
    },
    {
      title: "Type of account",
      value:
        capitalizeFirstLetter(staffProfile?.bankDetails?.accountType) || "N/A",
      cellClassName: "md:!w-44",
    },
    {
      title: "Account number *",
      value:
        capitalizeFirstLetter(staffProfile?.bankDetails?.accountNumber) ||
        "N/A",
      cellClassName: "md:!w-52",
    },
    {
      title: "Branch number (BSB)",
      value:
        capitalizeFirstLetter(staffProfile?.bankDetails?.branchNumber) || "N/A",
      cellClassName: "md:!w-52",
    },
  ],
  [
    {
      title: "Tax File Number Declaration",
      value: staffProfile?.bankDetails?.tfn
        ? [staffProfile?.bankDetails?.tfn]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.bankDetails?.tfn),
      cellClassName: "md:!w-64",
    },
    {
      title: "Super fund selection",
      value: Boolean(staffProfile.bankDetails?.superannuationFund)
        ? staffProfile?.bankDetails?.superannuationFund === "default"
          ? "I would like an account established with the default Superannuation fund"
          : "I would like to nominate a fund and have attached the Choice of Superannuation Fund Standard Choice Form and complying fund evidence"
        : "N/A",
      cellClassName: "flex-1",
    },
    {
      title: "Superannuation form",
      value: staffProfile?.bankDetails?.superannuationFundChoice
        ? [staffProfile?.bankDetails?.superannuationFundChoice]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.bankDetails?.superannuationFundChoice),
      cellClassName: "md:!w-60",
    },
  ],
];
export const getInjuryData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Read and understood",
      value: isBoolean(staffProfile.healthTermsUnderstood)
        ? staffProfile.healthTermsUnderstood
          ? "I have read and understood this form and the attached position description, and have discussed the employment with EICARE Pty. Ltd. I understand the responsibilities and physical demands of the employment."
          : "N/A"
        : "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Acknowledge",
      value: isBoolean(staffProfile.termsPreExisting)
        ? staffProfile.termsPreExisting
          ? "I acknowledge that I am required to disclose all pre-existing conditions which I believe may be affected by me undertaking the employment."
          : "N/A"
        : "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Failure to disclose",
      value: isBoolean(staffProfile.termsFailureToDisclose)
        ? "I acknowledge that failure to disclose this information or providing false and misleading information may result in invoking Section 41 of Workplace Injury and Rehabilitation Act 2013 which may disentitle me or my dependents from receiving any worker’s compensation benefits relating to any recurrence, aggravation, acceleration, exacerbation or deterioration of any pre-existing condition which I may have arising out of or in the course of, the employment."
        : "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Declared statement",
      value: isBoolean(staffProfile?.termsDeclarationPreExisting)
        ? staffProfile?.termsDeclarationPreExisting
          ? "I have suffered the following conditions that may recur or deteriorate, accelerate or be exacerbated or aggravated by the employment."
          : "I have suffered no prior injuries that may recur or deteriorate, accelerate or be exacerbated or aggravated by the employment."
        : "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Health condition(s)",
      value: staffProfile?.termsDeclaredStatement || "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Declare and acknowledge statement",
      value: isBoolean(staffProfile.termsDeclarationAcknowledgment)
        ? "I acknowledge and declare that the information provided in this form is true and correct in every particular."
        : "N/A",
      cellClassName: "w-full",
    },
  ],
  [
    {
      title: "Health declaration signature",
      value: staffProfile?.signature || "N/A",
    },
    {
      title: "Signed date",
      value: staffProfile?.signedAt
        ? getFormattedDate(new Date(staffProfile?.signedAt))
        : "N/A",
    },
    {
      title: "Immunisation History",
      value: staffProfile?.immunisationHistory
        ? [staffProfile?.immunisationHistory]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.immunisationHistory),
    },
    {
      title: "Covid-19 vaccine certificate",
      value: staffProfile?.covidVaccineCertificate
        ? [staffProfile?.covidVaccineCertificate]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.covidVaccineCertificate),
    },
  ],
];
export const getEmployeeCodeOfConductData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Downloaded employee handboook",
      value: isBoolean(staffProfile?.downloadEmployeeHandbook)
        ? staffProfile?.downloadEmployeeHandbook
          ? "Yes"
          : "No"
        : "N/A",
    },
    {
      title: "Read employee handbook",
      value: isBoolean(staffProfile.readEmployeeHandbook)
        ? "I have read the employee handbook"
        : "N/A",
    },
    {
      title: "Code of Conduct acknowledgment",
      value: isBoolean(staffProfile.acknowledgeCodeOfConduct)
        ? "I have read and agree to the Code of Conduct"
        : "N/A",
    },
  ],
  [
    {
      title: "Sign Code of conduct ",
      value: staffProfile?.signatureCodeOfConduct || "N/A",
      columnWidth: 3,
    },
    {
      title: "Signed date",
      value: staffProfile?.signatureCodeOfConductDate
        ? getFormattedDate(new Date(staffProfile?.signatureCodeOfConductDate))
        : "N/A",
      columnWidth: 3,
    },
  ],
];
export const getVehicleData = (user: User) => {
  const { staffProfile } = user;
  return [
    [
      {
        title: "Owns a vehicle",
        value: isBoolean(staffProfile?.vehicleOwnership)
          ? staffProfile?.vehicleOwnership
            ? "Yes"
            : "No"
          : "N/A",
      },
      {
        title: "Vehicle owner",
        value: isBoolean(staffProfile?.vehicleOwnership)
          ? staffProfile?.vehicleOwnership
            ? staffProfile.vehicleDetails?.vehicleOwner
              ? staffProfile.vehicleDetails?.vehicleOwner
              : `${capitalizeFirstLetter(
                  user?.firstName
                )} ${capitalizeFirstLetter(user?.lastName)}`
            : "N/A"
          : "N/A",
      },
      {
        title: "Make of vehicle",
        value:
          capitalizeFirstLetter(staffProfile?.vehicleDetails?.vehicleMake) ||
          "N/A",
      },
      {
        title: "Model of vehicle",
        value:
          capitalizeFirstLetter(staffProfile?.vehicleDetails?.vehicleModel) ||
          "N/A",
      },
      {
        title: "Engine size",
        value: staffProfile?.vehicleDetails?.engineSize || "N/A",
      },
    ],
    [
      {
        title: "Vehicle plate number",
        value: staffProfile?.vehicleDetails?.vehiclePlateNumber || "N/A",
      },
      {
        title: "Vehicle registration",
        value: staffProfile?.vehicleDetails?.vehicleRegistration
          ? [staffProfile?.vehicleDetails?.vehicleRegistration]
          : "N/A",
        isTypeFile: Boolean(staffProfile?.vehicleDetails?.vehicleRegistration),
      },
      {
        title: "Registration expiry date",
        value: staffProfile?.vehicleDetails?.vehicleRegistrationExpiry
          ? formatDate(
              new Date(staffProfile?.vehicleDetails?.vehicleRegistrationExpiry)
            )
          : "N/A",
      },
      {
        title: "Vehicle insurance",
        value: staffProfile?.vehicleDetails?.vehicleInsurance
          ? [staffProfile?.vehicleDetails?.vehicleInsurance]
          : "N/A",
        isTypeFile: Boolean(staffProfile?.vehicleDetails?.vehicleInsurance),
      },
      {
        title: "Vehicle insurance expiry date",
        value: staffProfile?.vehicleDetails?.vehicleInsuranceExpiry
          ? formatDate(
              new Date(staffProfile?.vehicleDetails?.vehicleInsuranceExpiry)
            )
          : "N/A",
      },
    ],
    [
      {
        title: "Driving licence number",
        value: staffProfile?.vehicleDetails?.drivingLicenseNumber || "N/A",
        columnWidth: 5,
      },
      {
        title: "Driving licence expiry date",
        value: staffProfile?.vehicleDetails?.drivingLicenseExpiry
          ? formatDate(
              new Date(staffProfile?.vehicleDetails?.drivingLicenseExpiry)
            )
          : "N/A",
        columnWidth: 5,
      },
      {
        title: "Certified driving licence (Front & back)",
        value:
          staffProfile?.vehicleDetails?.drivingLicenseFront &&
          staffProfile?.vehicleDetails?.drivingLicenseBack
            ? [
                staffProfile?.vehicleDetails?.drivingLicenseFront,
                staffProfile?.vehicleDetails?.drivingLicenseBack,
              ]
            : "N/A",
        isTypeFile: Boolean(
          staffProfile?.vehicleDetails?.drivingLicenseFront &&
            staffProfile?.vehicleDetails?.drivingLicenseBack
        ),
        columnWidth: 2,
      },
    ],
  ];
};
export const getContractData = (
  staffProfile: StaffProfile,
  isAdminView: boolean,
  adminStaffOptions: Option[]
) => [
  [
    {
      title: "signed contract",
      value: staffProfile?.contractSigned
        ? [staffProfile?.contractSigned]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.contractSigned),
      columnWidth: 3,
    },
    {
      title: "Contract received date",
      value: staffProfile?.contractReceivedDate
        ? formatDateWithTime(new Date(staffProfile?.contractReceivedDate))
        : "N/A",
      cellClassName: !isAdminView ? "hidden" : "",
      columnWidth: 3,
    },
    {
      title: "Availability to commence date",
      value: staffProfile?.availiableToCommenceDate
        ? formatDate(new Date(staffProfile?.availiableToCommenceDate))
        : "N/A",
      cellClassName: !isAdminView ? "hidden" : "",
      columnWidth: 3,
    },
    {
      title: "Employment start date",
      value: staffProfile?.employmentStartDate
        ? formatDateWithTime(new Date(staffProfile?.employmentStartDate))
        : "N/A",
      cellClassName: !isAdminView ? "hidden" : "",
      columnWidth: 3,
    },
    {
      title: "Employment end date",
      value: staffProfile?.employmentEndDate
        ? formatDateWithTime(new Date(staffProfile?.employmentEndDate))
        : "N/A",
      cellClassName: !isAdminView ? "hidden" : "",
      columnWidth: 3,
    },
    {
      title: "Reports to Manager/Supervisor",
      value: staffProfile?.reportingManagerId
        ? capitalizeFirstLetter(
            adminStaffOptions.filter(
              (admin) => admin.value === staffProfile.reportingManagerId
            )[0]?.label
          )
        : "N/A",
      cellClassName: !isAdminView ? "hidden" : "",
      columnWidth: 3,
    },
  ],
  [
    {
      title: "Personal care worker job description",
      value: "",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
      cellTitleClassName:
        "!text-lg !leading-6 !font-semibold !text-gray-900 !tracking-normal !normal-case !mt-2",
    },
  ],
  [
    {
      title: "Acknowledgement",
      value: staffProfile.contractAcknowledged
        ? "I certify that I have read, understood and accept the duties,responsibilities and obligations of my position."
        : "N/A",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
    },
  ],
  [
    {
      title: "Employee Signature",
      value: staffProfile.contractAcknowledgedSignature || "N/A",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
      columnWidth: 6,
    },
    {
      title: "Signed date",
      value: staffProfile?.contractAcknowledgedAt
        ? formatDateWithTime(new Date(staffProfile.contractAcknowledgedAt))
        : "N/A",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
      columnWidth: 6,
    },
    {
      title: "Manager Name",
      value: staffProfile?.contractReportsToManager?.firstName
        ? capitalizeFirstLetter(
            `${staffProfile?.contractReportsToManager.firstName} ${staffProfile?.contractReportsToManager.lastName}`
          )
        : "N/A",
      cellClassName:
        isAdminView && staffProfile.isPersonalCareWorker ? "" : "hidden",
      columnWidth: 6,
    },
    {
      title: "Manager signature",
      value: staffProfile.contractAdminSignature || "N/A",
      cellClassName:
        isAdminView && staffProfile.isPersonalCareWorker ? "" : "hidden",
      columnWidth: 6,
    },
    {
      title: "Signed date",
      value: staffProfile?.contractAdminSignedAt
        ? formatDateWithTime(new Date(staffProfile.contractAdminSignedAt))
        : "N/A",
      cellClassName:
        isAdminView && staffProfile.isPersonalCareWorker ? "" : "hidden",
      columnWidth: 6,
    },
  ],
];

export const getPoliceCheckData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Police check",
      value: staffProfile?.policeCheck ? [staffProfile?.policeCheck] : "N/A",
      isTypeFile: Boolean(staffProfile?.policeCheck),
      columnWidth: 5,
    },
    {
      title: "Police check expiry date",
      value: staffProfile?.policeCheckValidUntil
        ? formatDate(new Date(staffProfile?.policeCheckValidUntil))
        : "N/A",
      columnWidth: 5,
    },
  ],
];

export const getInterviewData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Preferred interview date",
      value: Boolean(staffProfile?.interviewDate)
        ? formatDateWithDay(new Date(staffProfile.interviewDate))
        : "N/A",
      columnWidth: 5,
    },
    {
      title: "Preferred interview time",
      value: Boolean(staffProfile?.interviewDate)
        ? new Date(staffProfile.interviewDate).toLocaleString("en-AU", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
            timeZone: "Australia/Sydney",
            timeZoneName: "short",
          })
        : "N/A",
      columnWidth: 5,
    },
    {
      title: "Timezone",
      value: Boolean(staffProfile?.interviewDate)
        ? `Melbourne VIC, Australia ${new Date(staffProfile.interviewDate)
            .toLocaleString("en-AU", {
              timeZone: "Australia/Victoria",
              timeZoneName: "shortOffset",
            })
            ?.split(" ")
            ?.pop()}`
        : "N/A",
    },
  ],
];

export const getReference1Data = (staffProfile: StaffProfile) => [
  [
    {
      title: "Title ",
      value: staffProfile?.reference1?.title || "N/A",
    },
    {
      title: "Firstname",
      value: staffProfile?.reference1?.firstName || "N/A",
    },
    {
      title: "Lastname ",
      value: staffProfile?.reference1?.lastName || "N/A",
    },
  ],
  [
    {
      title: "Email ",
      value: staffProfile?.reference1?.email || "N/A",
    },
    {
      title: "Mobile ",
      value: staffProfile?.reference1?.mobile || "N/A",
    },
    {
      title: "Relationship ",
      value: staffProfile?.reference1?.relationShip || "N/A",
    },
  ],
];

export const getReference2Data = (staffProfile: StaffProfile) => [
  [
    {
      title: "Title ",
      value: staffProfile?.reference2?.title || "N/A",
    },
    {
      title: "Firstname",
      value: staffProfile?.reference2?.firstName || "N/A",
    },
    {
      title: "Lastname ",
      value: staffProfile?.reference2?.lastName || "N/A",
    },
  ],
  [
    {
      title: "Email ",
      value: staffProfile?.reference2?.email || "N/A",
    },
    {
      title: "Mobile ",
      value: staffProfile?.reference2?.mobile || "N/A",
    },
    {
      title: "Relationship ",
      value: staffProfile?.reference2?.relationShip || "N/A",
    },
  ],
];

export const editReference1Data = (staffProfile: StaffProfile) => [
  {
    name: "reference1.title",
    label: "Title *",
    type: FieldType.Select,
    placeholder: "Select",
    containerClassName: "!w-full",
    options: [
      { label: "Mr", value: "Mr" },
      {
        label: "Mrs",
        value: "Mrs",
      },
      { label: "Miss", value: "Miss" },
    ],
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.reference1?.title,
    inputClassName: "w-3/4",
    hideSearch: true,
  },
  {
    name: "reference1.firstName",
    label: "First Name *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    placeholder: "John",
    defaultValue: staffProfile?.reference1?.firstName,
  },
  {
    name: "reference1.lastName",
    label: "Last Name *",
    type: FieldType.Input,
    placeholder: "John",
    parentFormContainerClassName: "w-full md:w-1/3  inline-block align-top",
    defaultValue: staffProfile.reference1?.lastName,
  },
  {
    name: `reference1.email`,
    label: "Email *",
    type: FieldType.EmailInput,
    placeholder: "jamesbond@actionstuff.com",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.reference1?.email,
  },
  {
    name: "reference1.mobile",
    label: "Mobile *",
    type: FieldType.Input,
    placeholder: "+614XXX XXX XXX",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.reference1?.mobile,

    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
  },
  {
    name: "reference1.relationShip",
    label: "Relationship *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    placeholder: "Manager",
    defaultValue: staffProfile.reference1?.relationShip,
  },
];
export const editReference2Data = (staffProfile: StaffProfile) => [
  {
    name: "reference2.title",
    label: "Title *",
    type: FieldType.Select,
    placeholder: "Select",
    containerClassName: "!w-full",
    options: [
      { label: "Mr", value: "Mr" },
      {
        label: "Mrs",
        value: "Mrs",
      },
      { label: "Miss", value: "Miss" },
    ],
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.reference2?.title,
    inputClassName: "w-3/4",
    hideSearch: true,
  },
  {
    name: "reference2.firstName",
    label: "First Name *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    placeholder: "John",
    defaultValue: staffProfile?.reference2?.firstName,
  },
  {
    name: "reference2.lastName",
    label: "Last Name *",
    type: FieldType.Input,
    placeholder: "John",
    parentFormContainerClassName: "w-full md:w-1/3  inline-block align-top",
    defaultValue: staffProfile.reference2?.lastName,
  },
  {
    name: `reference2.email`,
    label: "Email *",
    type: FieldType.EmailInput,
    placeholder: "jamesbond@actionstuff.com",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.reference2?.email,
  },
  {
    name: "reference2.mobile",
    label: "Mobile *",
    type: FieldType.Input,
    placeholder: "+614XXX XXX XXX",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.reference2?.mobile,

    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
  },
  {
    name: "reference2.relationShip",
    label: "Relationship *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    placeholder: "Manager",
    defaultValue: staffProfile.reference2?.relationShip,
  },
];

export const editInterviewDate = (staffProfile: StaffProfile) => [
  {
    name: "interviewDate",
    label: "",
    type: FieldType.Calendar,
    parentFormContainerClassName: "w-1/2 inline-block align-top !my-0 pr-6",
    className: "w-full",
    wrapperClassName: "w-full",
    containerClassName: "w-full",
    minDate: getTomorrowDate(),
    defaultValue: staffProfile.interviewDate
      ? toZonedTime(new Date(staffProfile.interviewDate), "Australia/Sydney")
      : undefined,
    customDateFormat: "EEEE, do MMMM yyyy",
    showOnlyWeekday: true,
  },
  {
    name: "interviewDateTime",
    label: "",
    type: FieldType.Calendar,
    parentFormContainerClassName:
      "w-1/2 inline-block align-top !my-0 timeOnlyPicker",
    className: "w-full",
    wrapperClassName: "w-full",
    containerClassName: "w-full",
    showTimeInput: true,
    minDate: getTomorrowDate(),
    showWorkingTime: true,
    placeholder: "Select time",
    defaultValue: staffProfile.interviewDate
      ? toZonedTime(new Date(staffProfile.interviewDate), "Australia/Sydney")
      : undefined,
  },
];

export const editEmployeeHandbook = (staffProfile: StaffProfile) => [
  {
    name: "readEmployeeHandbook",
    label: "I have read the Employee handbook",
    parentFormContainerClassName: "w-full",
    type: FieldType.Checbox,
    isDisabled: true,
    defaultValue: staffProfile.readEmployeeHandbook,
  },
  {
    name: "signatureCodeOfConductDate",
    label: "Sign Code of Conduct *",
    type: FieldType.Sign,
    parentFormContainerClassName: "mt-5",
    inputClassName: "w-3/4",
    defaultValue: staffProfile.signatureCodeOfConductDate,
    defaultSignature: staffProfile.signatureCodeOfConduct,
    isDisabled: true,
    signatureName: "signatureCodeOfConduct",
  },
];

export const editStatutoryDeclaration = (staffProfile: StaffProfile) => [
  {
    name: "signedStatutoryForm",
    label: "Upload signed statutory form",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.signedStatutoryForm,
    isMultipleFiles: false,
    buttonLabel: "Upload signed Statutory Form",
    parentFormContainerClassName: "w-full inline-block align-top pr-1",
  },
];

export const editContract = (
  staffProfile: StaffProfile,
  adminStaffOptions: Option[]
) => [
  {
    name: "contractSigned",
    label: "Contract",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.contractSigned,
    isMultipleFiles: false,
    buttonLabel: "Upload contractSigned",
    parentFormContainerClassName: "w-full inline-block align-top pr-1",
  },
  {
    name: "contractReceivedDate",
    label: "Contract received date",
    type: FieldType.Calendar,
    defaultValue: staffProfile.contractReceivedDate,
    parentFormContainerClassName: "w-1/2 inline-block align-top pr-6",
    wrapperClassName: "w-full",
    className: "w-full",
  },
  {
    name: "employmentStartDate",
    label: "Employment start date",
    type: FieldType.Calendar,
    defaultValue: staffProfile.employmentStartDate,
    parentFormContainerClassName: "w-1/2 inline-block align-top pr-6",
    wrapperClassName: "w-full",
    className: "w-full",
  },
  {
    name: "employmentEndDate",
    label: "Employment end date",
    type: FieldType.Calendar,
    defaultValue: staffProfile.employmentEndDate,
    parentFormContainerClassName: "w-1/2 inline-block align-top pr-6",
    wrapperClassName: "w-full",
    className: "w-full",
  },
  {
    name: "reportingManagerId",
    label: "Reports to Manager/Supervisor",
    type: FieldType.Select,
    defaultValue: staffProfile.reportingManagerId,
    parentFormContainerClassName: "w-1/2 inline-block align-top pr-1",
    options: adminStaffOptions,
    placeholder: "Select",
    isDropdownFixed: true,
    containerClassName: "w-full",
    className: "mt-2",
  },
  {
    name: "availiableToCommenceDate",
    label: "Availability to commence date",
    type: FieldType.Calendar,
    defaultValue: staffProfile.availiableToCommenceDate,
    parentFormContainerClassName: "w-1/2 inline-block align-top pr-6",
    wrapperClassName: "w-full",
    className: "w-full",
  },
];
