import { GenericResponse } from "api/types";
import { useValidateToken } from "api/user";
import { useSearchParams } from "react-router-dom";

export interface AuthTokenValidationResponse extends GenericResponse {
  email: string;
  username: string;
}

const useAuthToken = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const { isFetching, data, isError, error } = useValidateToken(code);

  const errorMessage = error ? JSON.parse(error.message) : "";
  const invitedByFirstName: string | undefined =
    errorMessage?.invitedBy?.firstName;

  return {
    isFetching,
    isValidToken: data ? true : false,
    isError,
    invitedByFirstName,
    username: data?.username,
  };
};

export default useAuthToken;
