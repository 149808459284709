import { StaffProfile } from "api/types";
import ReusableForm from "components/Form";
import { FieldType, FormData } from "components/types";
import { useForm } from "react-hook-form";
import { validateBSB, validateTextOnly } from "utils/util";
import { AdminUpdateProps } from "./AdminUpdateResidencyAndWorkRights";

const getBankTaxAndSuperData = (
  staffProfile: StaffProfile,
  watchedFields: FormData
) => [
  {
    name: "paymentAuthorisation",
    label: "Salary payment authorisation",
    parentFormContainerClassName: "w-full mt-5",
    type: FieldType.Checbox,
    defaultValue: staffProfile.bankDetails?.paymentAuthorisation,
  },
  {
    name: "bankName",
    label: "Bank name *",
    type: FieldType.Input,
    defaultValue: staffProfile.bankDetails?.bankName,
    parentFormContainerClassName:
      "w-full md:w-1/3 inline-block align-top  pr-6",
  },
  {
    name: "branchAddress",
    label: "Branch address *",
    type: FieldType.Input,
    defaultValue: staffProfile.bankDetails?.branchAddress,
    parentFormContainerClassName:
      "w-full md:w-1/3 inline-block align-top  pr-6",
  },
  {
    name: "accountName",
    label: "Account name *",
    type: FieldType.Input,
    defaultValue: staffProfile.bankDetails?.accountName,
    additionalTriggerRequired: true,
    validationFunction: validateTextOnly,
    requiredCondition: watchedFields && watchedFields.accountName,
    validationErrorMessage: "Please enter only alphabet characters",
    parentFormContainerClassName:
      "w-full md:w-1/3 inline-block align-top  pr-6",
  },
  {
    name: "accountType",
    label: "Type of account *",
    type: FieldType.Select,
    defaultValue: staffProfile.bankDetails?.accountType,
    parentFormContainerClassName:
      "w-full md:w-1/3 inline-block align-top  pr-6",
    containerClassName: "w-full",
    className: "w-full",
    options: [
      {
        label: "Transaction/cheque account",
        value: "Transaction/cheque account",
      },
      {
        label: "Savings/Deposit account",
        value: "Savings/Deposit account",
      },
      {
        label: "Term deposit account",
        value: "Term deposit account",
      },
    ],
    hideSearch: true,
  },
  {
    name: "accountNumber",
    label: "Account number *",
    type: FieldType.Input,
    defaultValue: staffProfile.bankDetails?.accountNumber,
    parentFormContainerClassName:
      "w-full md:w-1/3 inline-block align-top  pr-6",
    subType: "number",
  },
  {
    name: "branchNumber",
    label: "Branch number (BSB) *",
    type: FieldType.Input,
    defaultValue: staffProfile.bankDetails?.branchNumber,
    parentFormContainerClassName: "w-full md:w-1/3 inline-block align-top pr-6",
    subType: "number",
    additionalTriggerRequired: true,
    validationFunction: validateBSB,
    requiredCondition: watchedFields && watchedFields.branchNumber,
    validationErrorMessage: "BSB should be 6 digits",
  },
  {
    name: "tfn",
    label:
      "After you fill the “Tax file number declaration form” upload the document below:",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.bankDetails?.tfn,
    isMultipleFiles: false,
    buttonLabel: "Upload tax form",
    parentFormContainerClassName: "pr-6",
  },
  {
    name: "superannuationFund",
    label: "Super fund selection",
    parentFormContainerClassName: "w-full  pr-6",
    isCol: true,
    options: [
      {
        label:
          "I would like an account established with the default Superannuation fund",
        value: "default",
      },
      {
        label:
          "I would like to nominate a fund and have attached the Choice of Superannuation Fund Standard Choice Form and complying fund evidence",
        value: "nominate",
      },
    ],
    type: FieldType.Radio,
    defaultValue: staffProfile.bankDetails?.superannuationFund,
  },
  {
    name: "superannuationFundChoice",
    label:
      "After you fill the “Superannuation form” upload the document below:",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.bankDetails?.superannuationFundChoice,
    isMultipleFiles: false,
    buttonLabel: "Upload “Superannuation form",
    parentFormContainerClassName: "pr-6",
  },
];

export default function AdminUpdateBankTaxSuper({
  user,
  onSubmitFn,
}: AdminUpdateProps) {
  const { formState, watch, ...formMethods } = useForm<FormData>();
  const { staffProfile } = user;

  const watchedFields = watch();
  const onSubmit = async (data: FormData) => onSubmitFn(data);

  return (
    <div className="bg-white rounded-md">
      <div className="pl-6 pr-0 pb-0">
        <ReusableForm
          fields={getBankTaxAndSuperData(staffProfile, watchedFields)}
          onSubmit={onSubmit}
          id="edit-BANK_DETAILS"
          formClassName="pt-1 pb-6"
          formMethods={{
            ...formMethods,
            formState,
            watch,
          }}
        />
      </div>
    </div>
  );
}
