import { UserStatus } from "api/types";
import { redirect } from "react-router-dom";
import { LoaderProps } from "routes/root";

export async function StartOnBoardingCompletionLoader({
  params,
  request,
  user,
}: LoaderProps) {
  const authData = window.localStorage.getItem("eicare_auth");
  const auth = JSON.parse(authData || "{}");
  if (!(auth && auth.session)) {
    const url = new URL(request.url);
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }
  if (user.status === UserStatus.Completed || user.createdAccount) {
    return redirect("/dashboard/staff");
  }
  if (user.staffProfile.onboardingStatus > 0) {
    return redirect(
      `/onboarding/staff/step/${user.staffProfile.onboardingStatus}`
    );
  }
  return {
    user,
  };
}
