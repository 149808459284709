import { editStaff } from "api/staff";
import { User } from "api/types";
import ReusableForm from "components/Form";
import { FieldType, FormData } from "components/types";
import { handleGenericResponse } from "components/utility";
import { useForm } from "react-hook-form";
import { getChangedFields, validateAustralianPhoneNumber } from "utils/util";
import { AdminUpdateProps } from "./AdminUpdateResidencyAndWorkRights";

export const getUpdateEmergencyContact = (
  emergencyContact: User,
  watchedFields: FormData
) => [
  {
    name: "title",
    label: "Title *",
    type: FieldType.Select,
    placeholder: "Select",

    options: [
      { label: "Mr", value: "Mr" },
      {
        label: "Mrs",
        value: "Mrs",
      },
      { label: "Miss", value: "Miss" },
    ],
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: emergencyContact?.title,
    hideSearch: true,
  },
  {
    name: "firstName",
    label: "First Name *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-6",
    className: "w-72",
    placeholder: "John",
    defaultValue: emergencyContact?.firstName,
  },
  {
    name: "lastName",
    label: "Last Name *",
    type: FieldType.Input,
    className: "w-72",
    placeholder: "Smith",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-6",
    defaultValue: emergencyContact?.lastName,
  },
  {
    name: "email",
    label: "Email *",
    type: FieldType.EmailInput,
    placeholder: "johndoe@gmail.com",
    parentFormContainerClassName: "w-1/3  inline-block align-top pr-6",
    defaultValue: emergencyContact?.email,
  },
  {
    name: "mobile",
    label: "Mobile *",
    type: FieldType.Input,
    placeholder: "04XXX XXX XXX",
    parentFormContainerClassName: "w-1/3  inline-block align-top pr-6",
    defaultValue: emergencyContact?.mobile,
    additionalTriggerRequired: true,
    requiredCondition: watchedFields && watchedFields.mobile,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
  },
  {
    name: "homeTelephoneNo",
    label: "Home Telephone no",
    type: FieldType.Input,
    placeholder: "+614XXX XXX XXX",
    parentFormContainerClassName: "w-1/3  inline-block align-top pr-6",
    defaultValue: emergencyContact?.homeTelephoneNo,
    additionalTriggerRequired: true,
    requiredCondition: watchedFields && watchedFields.homeTelephoneNo,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
  },
  {
    name: "relationShip",
    label: "Relationship *",
    type: FieldType.Input,
    placeholder: "Uncle",
    parentFormContainerClassName: "w-1/3  inline-block align-top pr-6",
    defaultValue: emergencyContact?.relationShip,
  },
];

export default function AdminUpdateEmergencyContact({
  user,
  refreshData,
}: AdminUpdateProps) {
  const { watch, ...formMethods } = useForm<FormData>();
  const { staffProfile } = user;
  const watchedFields = watch();

  const onSubmit = async (data: FormData) => {
    const updatedField = getChangedFields(
      {
        ...user.staffProfile.emergencyContact,
      },
      data
    );
    const response = await editStaff(
      `/api/user/admin/staff/section/emergency-contact/${user.id}`,
      updatedField,
      []
    );

    const result = await handleGenericResponse(response);
    result && refreshData && refreshData();
  };

  return (
    <div className="bg-white rounded-md">
      <div className="pl-6 pr-0 pb-0">
        <ReusableForm
          fields={getUpdateEmergencyContact(
            staffProfile.emergencyContact,
            watchedFields
          )}
          onSubmit={onSubmit}
          id="edit-EMERGENCY_CONTACT"
          formClassName="pt-1 pb-6"
          formMethods={{
            ...formMethods,
            watch,
          }}
        />
      </div>
    </div>
  );
}
