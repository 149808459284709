import { onboardStaff } from "api/staff";
import Calendar from "components/Calendar";
import FileUpload from "components/FileUpload";
import FormFooter from "components/FormFooter";
import Notification, { NotificationProps } from "components/Notification";
import Radio from "components/RadioBox";
import Input from "components/UnControlledInput";
import { FieldType, FormData, StepProps } from "components/types";
import { handleResponse } from "components/utility";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getTodayDate, validateNumberOrDecimal } from "utils/util";

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear", "ownerName"];

export default function Step8({ isCompleted, user, isToggled }: StepProps) {
  const {
    getValues,
    watch,
    formState,
    handleSubmit,
    setError,
    ...formMethods
  } = useForm<FormData>();
  const { staffProfile } = user;
  const navigate = useNavigate();
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const [shouldValidate, setShouldValidate] = useState<boolean>(true);
  const [isOwnVehicle, setIsOwnVehicle] = useState<boolean>(false);
  const vehicleOwnership = watch("ownVehicle");
  const methods = {
    ...formMethods,
    getValues,
    formState,
    watch,
    handleSubmit,
    setError,
  };

  console.log({ isOwnVehicle });

  useEffect(() => {
    setIsOwnVehicle(
      Boolean(staffProfile.vehicleOwnership)
        ? staffProfile.vehicleOwnership
          ? true
          : false
        : true
    );
  }, [staffProfile.vehicleOwnership]);

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    data.ownVehicle = data.ownVehicle?.toLowerCase() === "yes" ? true : false;
    data.engineSize = data.engineSize ? `${data.engineSize}L` : null;
    const response = await onboardStaff(
      8,
      data.ownVehicle ? data : { ownVehicle: false },
      "submit",
      FIELDS_TO_REMOVE
    );
    const isSuccess = await handleResponse(response, setError, setResponse);
    isSuccess && navigate("/onboarding/staff/step/9");
  };

  const handleButtonClick = async () => {
    setShouldValidate(false);
    setTimeout(() => handleSubmit(saveForLater)(), 100);
  };

  const saveForLater = async (data: FormData) => {
    setResponse(null);
    data.ownVehicle = data.ownVehicle?.toLowerCase() === "yes" ? true : false;
    data.engineSize = data.engineSize ? `${data.engineSize}L` : null;
    const response = await onboardStaff(
      8,
      data.ownVehicle ? data : { ownVehicle: false },
      "save",
      FIELDS_TO_REMOVE
    );
    setShouldValidate(true);
    await handleResponse(response, setError, setResponse);
  };

  return (
    <div className="bg-white shadow rounded-md">
      <div className="p-6 pr-0 text-sm font-medium">
        <div className="flex flex-col ">
          <Notification
            type="warningExclamation"
            message="All mandatory fields must be completed in order to continue to the next step."
            className="!m-0 !inline-flex w-max"
            iconClassName="text-yellow-500"
          />
          {response ? (
            <Notification
              type={response.type}
              message={response.message}
              className="!m-0 !my-3 !inline-flex w-max"
            />
          ) : (
            <></>
          )}
        </div>
        <FormProvider {...methods}>
          <form id={"save-step8"} onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <Radio
                name="ownVehicle"
                label="Do you own a vehicle? *"
                options={[
                  {
                    label: "Yes",
                    value: "Yes",
                  },
                  {
                    label: "No",
                    value: "No",
                  },
                ]}
                type={FieldType.Radio}
                requiredCondition={shouldValidate && "This is a required field"}
                isHiglighted={isToggled}
                defaultValue={
                  typeof staffProfile.vehicleOwnership === "boolean"
                    ? staffProfile.vehicleOwnership
                      ? "Yes"
                      : "No"
                    : "Yes"
                }
                isDisabled={isCompleted}
              />
            </div>
            {vehicleOwnership === "Yes" && (
              <div>
                <div>
                  <div className="flex justify-start flex-wrap md:flex-nowrap">
                    <Input
                      name="ownerName"
                      label="Vehicle owner"
                      type={FieldType.Input}
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      defaultValue={`${user.firstName} ${user.lastName}`}
                      isDisabled
                      containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                      className="w-full"
                    />
                    <Input
                      name="vehicleMake"
                      label="Make of vehicle *"
                      type={FieldType.Input}
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      defaultValue={staffProfile.vehicleDetails?.vehicleMake}
                      isDisabled={isCompleted}
                      containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                      className="w-full"
                    />
                    <Input
                      name="vehicleModel"
                      label="Model of vehicle *"
                      type={FieldType.Input}
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      defaultValue={staffProfile.vehicleDetails?.vehicleModel}
                      isDisabled={isCompleted}
                      containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                      className="w-full"
                    />
                  </div>
                  <div className="flex justify-start flex-wrap md:flex-nowrap">
                    <Input
                      name="engineSize"
                      label="Engine size *"
                      type={FieldType.Input}
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      defaultValue={
                        staffProfile.vehicleDetails?.engineSize
                          ?.toLowerCase()
                          .includes("l")
                          ? staffProfile.vehicleDetails?.engineSize.replaceAll(
                              /l/gi,
                              ""
                            )
                          : staffProfile.vehicleDetails?.engineSize
                      }
                      isDisabled={isCompleted}
                      containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                      className="w-full rounded-r-none !border-r-none"
                      inputContainerClassName="flex"
                      validationFunction={validateNumberOrDecimal}
                      validationErrorMessage="Only numbers and decimals are allowed."
                      children={
                        <span
                          className={
                            "rounded-r-md inline-flex items-center px-3 text-gray-500 ring-1 ring-inset ring-gray-300 bg-gray-100 text-sm h-10"
                          }
                        >
                          L
                        </span>
                      }
                    />
                    <Input
                      name="vehiclePlateNumber"
                      label="Vehicle plate number *"
                      type={FieldType.Input}
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      defaultValue={
                        staffProfile.vehicleDetails?.vehiclePlateNumber
                      }
                      isDisabled={isCompleted}
                      containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="flex items-stretch flex-wrap md:flex-nowrap">
                  <div className="w-full md:w-2/3 pr-6">
                    <FileUpload
                      name="vehicleRegistration"
                      label="Vehicle registration * (Download report from Vic Roads)"
                      type={FieldType.FileUpload}
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      defaultValue={
                        staffProfile.vehicleDetails?.vehicleRegistration
                      }
                      isDisabled={isCompleted}
                      isMultipleFiles={false}
                      buttonLabel="Upload Vehicle registration"
                      externalLink="https://www.vicroads.vic.gov.au/registration/buy-sell-or-transfer-a-vehicle/check-vehicle-registration/vehicle-registration-enquiry"
                    />
                  </div>
                  <div className="w-full md:w-1/3 pr-6">
                    <Calendar
                      name="vehicleRegistrationExpiry"
                      defaultValue={
                        staffProfile.vehicleDetails?.vehicleRegistrationExpiry
                      }
                      type={FieldType.Calendar}
                      label="Registration expiry date *"
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      isDisabled={isCompleted}
                      containerClassName="w-full"
                      wrapperClassName="w-full"
                      minDate={getTodayDate()}
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="flex items-stretch flex-wrap md:flex-nowrap">
                  <div className="w-full md:w-2/3 md:pr-6">
                    <FileUpload
                      name="vehicleInsurance"
                      label="Vehicle insurance *"
                      type={FieldType.FileUpload}
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      defaultValue={
                        staffProfile.vehicleDetails?.vehicleInsurance
                      }
                      isDisabled={isCompleted}
                      isMultipleFiles={false}
                      buttonLabel="Upload Vehicle insurance"
                    />
                  </div>
                  <div className="w-full md:w-1/3 md:pr-6">
                    <Calendar
                      name="vehicleInsuranceExpiry"
                      defaultValue={
                        staffProfile.vehicleDetails?.vehicleInsuranceExpiry
                      }
                      type={FieldType.Calendar}
                      label="Vehicle insurance expiry date *"
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      isDisabled={isCompleted}
                      containerClassName="w-full"
                      wrapperClassName="w-full"
                      minDate={getTodayDate()}
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="flex items-stretch flex-wrap md:flex-nowrap">
                  <div className="w-full md:w-1/2 md:pr-6">
                    <Input
                      name="drivingLicenseNumber"
                      label="Driving licence number *"
                      type={FieldType.Input}
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      defaultValue={
                        staffProfile.vehicleDetails?.drivingLicenseNumber
                      }
                      isDisabled={isCompleted}
                    />
                  </div>
                  <div className="w-full md:w-1/2 md:pr-6">
                    <Calendar
                      name="drivingLicenseExpiry"
                      defaultValue={
                        staffProfile.vehicleDetails?.drivingLicenseExpiry
                      }
                      type={FieldType.Calendar}
                      label="Driving licence expiry date *"
                      requiredCondition={
                        shouldValidate && "This is a required field"
                      }
                      isHiglighted={isToggled}
                      isDisabled={isCompleted}
                      containerClassName="w-full"
                      wrapperClassName="w-full"
                      minDate={getTodayDate()}
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="flex items-stretch flex-wrap md:flex-nowrap">
                  <FileUpload
                    name="drivingLicenseFront"
                    label="Certified driving licence (Front) *"
                    type={FieldType.FileUpload}
                    requiredCondition={
                      shouldValidate && "This is a required field"
                    }
                    isHiglighted={isToggled}
                    defaultValue={
                      staffProfile.vehicleDetails?.drivingLicenseFront
                    }
                    isDisabled={isCompleted}
                    buttonLabel="Upload driving licence (Front)"
                    fileUploadContainerClassName="w-full md:w-1/2 md:pr-6"
                  />
                  <FileUpload
                    name="drivingLicenseBack"
                    label="Certified driving licence (Back) *"
                    type={FieldType.FileUpload}
                    requiredCondition={
                      shouldValidate && "This is a required field"
                    }
                    isHiglighted={isToggled}
                    defaultValue={
                      staffProfile.vehicleDetails?.drivingLicenseBack
                    }
                    isDisabled={isCompleted}
                    buttonLabel="Upload driving licence (Back)"
                    fileUploadContainerClassName="w-full md:w-1/2 md:pr-6"
                  />
                </div>
              </div>
            )}
          </form>
        </FormProvider>
      </div>

      <FormFooter
        saveforLater={handleButtonClick}
        isDisabled={isCompleted}
        formId="save-step8"
        step={8}
      />
    </div>
  );
}
