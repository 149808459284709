import { createdAccountDocumentUpdate } from "api/staff";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import { Icon } from "components/Icon";
import Notification, { NotificationProps } from "components/Notification";
import Sign from "components/Sign";
import { FILE_NAME_MAPPING, FieldType, FormData } from "components/types";
import { handleResponse } from "components/utility";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { capitalizeFirstLetter, handleDownload } from "utils/util";
import { CurrentEmployeeProps } from "./CurrentEmployeeStep1";

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear"];

export default function CurrentEmployeeStep8({
  user,
  refreshUser,
  isToggled,
}: CurrentEmployeeProps) {
  const {
    getValues,
    watch,
    formState,
    setValue,
    handleSubmit,
    setError,
    ...formMethods
  } = useForm<FormData>();
  const { staffProfile } = user;
  const [response, setResponse] = useState<NotificationProps | null>(null);
  //const readEmployeeHandbook = watch("readEmployeeHandbook");
  const [isReadEmployeeHandbook, setIsReadEmployeeHandbook] =
    useState<boolean>(false);
  const [isDownloadEmployeeHandbook, setIsDownloadEmployeeHandbook] =
    useState<boolean>(false);
  const methods = {
    ...formMethods,
    getValues,
    formState,
    watch,
    handleSubmit,
    setValue,
    setError,
  };

  useEffect(() => {
    setIsReadEmployeeHandbook(staffProfile.readEmployeeHandbook ? true : false);
    setIsDownloadEmployeeHandbook(
      staffProfile.downloadEmployeeHandbook ? true : false
    );
    if (staffProfile.downloadEmployeeHandbook) {
      setValue(
        "downloadEmployeeHandbook",
        staffProfile.downloadEmployeeHandbook
      );
    }
  }, [
    setValue,
    staffProfile.downloadEmployeeHandbook,
    staffProfile.readEmployeeHandbook,
  ]);

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    const response = await createdAccountDocumentUpdate(
      8,
      data,
      FIELDS_TO_REMOVE
    );
    const isSuccess = await handleResponse(
      response,
      setError,
      setResponse,
      "Submitted successfully"
    );
    isSuccess && (await refreshUser());
  };

  return (
    <div className="bg-white shadow rounded-md">
      <div className="text-sm font-medium">
        <div className="flex flex-col ">
          {response ? (
            <Notification
              type={response.type}
              message={response.message}
              className="!m-0 !mt-2 !inline-flex w-max"
            />
          ) : (
            <></>
          )}
        </div>
        <FormProvider {...methods}>
          <form id={"currentStaffDocument-8"} onSubmit={handleSubmit(onSubmit)}>
            <div className="px-5 pb-5">
              <div className="pt-5">
                <h3 className="text-lg leading-6 font-semibold text-gray-900">
                  Read employee handbook
                </h3>
                <div className="flex items-stretch mt-5 flex-wrap md:flex-nowrap">
                  <div className="w-full md:w-2/5">
                    <Button
                      variant="secondary"
                      size="base"
                      className="text-center"
                      onClick={() => {
                        setValue("downloadEmployeeHandbook", true);
                        setIsDownloadEmployeeHandbook(true);
                        handleDownload(FILE_NAME_MAPPING.EMPLOYEE_HANDBOOK);
                      }}
                    >
                      <Icon name="download" />
                      <span className="text-sm leading-5 font-semibold ml-2">
                        Download Employee handbook
                      </span>
                    </Button>
                    {isDownloadEmployeeHandbook && (
                      <>
                        <h6 className="text-sm leading-5 font-semibold text-gray-700 mt-5">
                          Employee handbook *
                        </h6>
                        <Checkbox
                          name="readEmployeeHandbook"
                          label="I have read the Employee handbook"
                          type={FieldType.Checbox}
                          className="mr-3 !mt-1"
                          requiredCondition="This is a required field"
                          isHiglighted={isToggled}
                          defaultValue={staffProfile.readEmployeeHandbook}
                          onChange={(e) =>
                            setIsReadEmployeeHandbook(e.target.checked)
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className="w-full md:w-3/5 mt-5 md:mt-0">
                    <Notification
                      type="info"
                      message="Helpful information"
                      bodyText={
                        <div className="text-primary-800">
                          <p className="text-sm leading-5 font-normal text-primary-800">
                            Please begin by downloading the employee handbook by
                            clicking the “Download Employee handbook” on the
                            left.
                          </p>
                          <p className="text-sm leading-5 font-normal text-primary-800 mt-5">
                            {`Carefully read through the employee handbook and if you have any questions call us on 1300 180 88 or email to support@eicare.com.au and ask for ${capitalizeFirstLetter(
                              user?.createdBy?.firstName || "staff"
                            )} to assist you.`}
                          </p>
                          <p className="text-sm leading-5 font-normal text-primary-800 mt-1">
                            Once you have completed reading the handbook, click
                            the checkbox on the left to confirm that you have
                            read and understand the handbook to proceed.
                          </p>
                        </div>
                      }
                      className="h-full"
                      iconHeight={24}
                      iconWidth={48}
                    />
                  </div>
                </div>
              </div>
              {isReadEmployeeHandbook && (
                <div className="mt-5  border-t border-gray-300">
                  <h5 className="mt-5 text-lg leading-6 font-semibold text-gray-900">
                    Code of conduct
                  </h5>
                  <p className="text-sm leading-5 font-normal text-gray-700 mt-4">
                    EICARE is committed to ensuring staffs’ behaviour is
                    appropriate in the work environment and demonstrates our
                    five core values Empowerment, independent, integrity,
                    transparency and openness.
                  </p>
                  <p className="text-sm leading-5 font-semibold text-gray-900 mt-4">
                    This Code of Conduct requires all staff members at all times
                    to:
                  </p>
                  <ul className="text-sm leading-5 font-normal text-gray-700 list-disc list-inside">
                    <li>Abide by the philosophy of EICARE Home Care</li>
                    <li>
                      Observe all the rules of EICARE Home Care including those
                      specified in the constitution and any others determined by
                      the Board
                    </li>
                    <li>
                      Adhere to all the accounting procedures of EICARE Home
                      Care
                    </li>
                    <li>
                      Work in a safe and competent manner in accordance with the
                      policies and procedures of EICARE Home Care
                    </li>
                    <li>
                      Respect the dignity and culture, values and beliefs of all
                      individuals
                    </li>
                    <li>Represent EICARE Home Care in a positive way</li>
                    <li>
                      Wear suitable clothing including closed in shoes, clean
                      and discrete shirt and trousers (or shorts)
                    </li>
                    <li>
                      Not discuss confidential issues of EICARE Home Care with
                      people outside the organisation, regard all information
                      provided to them by a consumer as confidential and never
                      disclose personal information to a consumer
                    </li>
                    <li>
                      Not take illegal drugs or consume alcohol when on duty or
                      on the premises
                    </li>
                    <li>
                      Not accept gifts or purchase any items from consumers
                      (except home garden produce)
                    </li>
                    <li>
                      Not have sexual relationships with consumers or take them
                      to their (staff) homes or engage in a relationship with
                      consumers outside of a professional relationship
                    </li>
                    <li>
                      Follow any grievance procedures set down by the Board to
                      try to resolve any conflicts with other staff or members
                      of EICARE Home Care
                    </li>
                    <li>
                      Not harass in any form consumers, other staff or members
                      of EICARE Home Care
                    </li>
                    <li>
                      Not abuse, physically or verbally, consumers, other staff
                      or members of EICARE Home Care
                    </li>
                    <li>
                      Not give advice to consumers or diagnose the condition of
                      a consumer’s health (except health promotion advice
                      provided by a Registered Nurse). If requested by the
                      family as to your thoughts regarding the condition of a
                      consumer – refer them to their GP or hospital
                    </li>
                    <li>Not alienate consumers from their family</li>
                    <li>
                      Treat consumers with courtesy, respect and consideration,
                      act on complaints and provide services to the best of
                      their ability.
                    </li>
                  </ul>
                  <p className="text-sm leading-5 font-normal text-gray-700 mt-4">
                    Commonwealth aged care code of conduct
                  </p>
                  <p className="text-sm leading-5 font-semibold text-gray-700 mt-4">
                    When providing care, supports and services to people, I
                    must:
                  </p>
                  <p className="text-sm leading-5 font-normal text-gray-700 ml-2">
                    (a) act with respect for people’s rights to freedom of
                    expression, self-determination and decision-making in
                    accordance with applicable laws and conventions; and
                  </p>
                  <p className="text-sm leading-5 font-normal text-gray-700 ml-2">
                    (b) act in a way that treats people with dignity and
                    respect, and values their diversity; and
                  </p>
                  <p className="text-sm leading-5 font-normal text-gray-700 ml-2">
                    (c) act with respect for the privacy of people; and
                  </p>
                  <p className="text-sm leading-5 font-normal text-gray-700 ml-2">
                    (d) provide care, supports and services in a safe and
                    competent manner, with care and skill;
                  </p>
                  <p className="text-sm leading-5 font-normal text-gray-700 ml-2">
                    (e) act with integrity, honesty and transparency; and
                  </p>
                  <p className="text-sm leading-5 font-normal text-gray-700 ml-2">
                    (f) promptly take steps to raise and act on concerns about
                    matters that may impact the quality and safety of care,
                    supports and services; and
                  </p>
                  <p className="text-sm leading-5 font-normal text-gray-700 ml-2">
                    (g) provide care, supports and services free from:
                  </p>
                  <p className="text-sm leading-5 font-normal text-gray-700 ml-4">
                    (i) all forms of violence, discrimination, exploitation,
                    neglect and abuse; and
                  </p>
                  <p className="text-sm leading-5 font-normal text-gray-700 ml-4">
                    (ii) sexual misconduct; and
                  </p>
                  <p className="text-sm leading-5 font-normal text-gray-700 ml-2">
                    (h) take all reasonable steps to prevent and respond to:
                  </p>
                  <p className="text-sm leading-5 font-normal text-gray-700 ml-4">
                    (i) all forms of violence, discrimination, exploitation,
                    neglect and abuse; and
                  </p>
                  <p className="text-sm leading-5 font-normal text-gray-700 ml-4">
                    (ii) sexual misconduct.
                  </p>
                  <p className="text-sm leading-5 font-normal text-gray-700 mt-4">
                    Failure to abide by the above rules may lead to disciplinary
                    action or dismissal from EICARE Home Care.
                  </p>
                  <div className="mt-8">
                    <h5 className="text-lg leading-6 font-semibold text-gray-900">
                      Code of Conduct acknowledgment
                    </h5>
                    <p className="text-sm leading-5 font-normal text-gray-700">
                      By ticking the following checkbox, you are agreeing that
                      you have read and will abide by all instructions provided
                      in the employee handbook. I understand that compliance
                      with the requirements of this handbook is a condition of
                      employment.
                    </p>
                    <h6 className="text-sm leading-5 font-semibold text-gray-700 mt-5">
                      Code of Conduct acknowledgment *
                    </h6>
                    <Checkbox
                      name="acknowledgeCodeOfConduct"
                      label="I have read and agree to the Code of Conduct"
                      type={FieldType.Checbox}
                      className="mr-3 !mt-1"
                      requiredCondition="This is a required field"
                      isHiglighted={isToggled}
                      defaultValue={staffProfile.acknowledgeCodeOfConduct}
                    />
                  </div>
                  <div className="flex items-stretch mt-5 flex-wrap md:flex-nowrap">
                    <div className="w-full md:w-2/3">
                      <Sign
                        name="signatureCodeOfConductDate"
                        label="Sign Code of Conduct *"
                        type={FieldType.Sign}
                        signContaineClassName="mt-2"
                        requiredCondition="This is a required field"
                        isHiglighted={isToggled}
                        inputClassName="w-full md:w-3/4"
                        defaultValue={staffProfile.signatureCodeOfConductDate}
                        defaultSignature={staffProfile.signatureCodeOfConduct}
                        signatureName="signatureCodeOfConduct"
                      />
                    </div>
                    <div className="w-full md:w-1/3 mt-5 md:mt-0">
                      <Notification
                        type="info"
                        message="Helpful information"
                        bodyText="Is the name spelled wrong? You can correct the mistake and click “Re-sign” button."
                        className="h-full"
                        iconHeight={24}
                        iconWidth={48}
                        textClassName="!text-primary-800"
                      />
                    </div>
                  </div>
                  {/* <div className="flex items-stretch mt-5 flex-wrap md:flex-nowrap">
                    <div className="w-full md:w-2/3 md:pr-6">
                      <div className="">
                        <Button
                          variant="primary"
                          size="large"
                          className="w-full md:w-96 text-center"
                          onClick={() =>
                            handleDownload(
                              FILE_NAME_MAPPING.STATUTORY_DECLARATION
                            )
                          }
                        >
                          <Icon name="download" />
                          <span className="text-base leading-6 font-bold text-gray-900 mx-2">
                            Download Statutory declaration form
                          </span>
                        </Button>
                      </div>
                      <FileUpload
                        name="signedStatutoryForm"
                        label="After you fill the “Statutory declaration form” upload the form in PDF format below"
                        type={FieldType.FileUpload}
                        requiredCondition="This is a required field"
                        isHiglighted={isToggled}
                        defaultValue={staffProfile.signedStatutoryForm}
                        isMultipleFiles={false}
                        buttonLabel="Upload signed statutory form"
                        externalLink="https://www.justice.vic.gov.au/statdecs"
                      />
                    </div>
                    <div className="w-full md:w-1/3 mt-5 md:mt-0">
                      <Notification
                        type="info"
                        message="Helpful information"
                        bodyText={
                          <div className="!text-primary-800">
                            <span className="mr-1">
                              Get your statutory declaration witnessed by
                              Justice of the Peace, Police officer and
                              others.Learn more
                            </span>
                            <Link
                              url="https://www.justice.vic.gov.au/statdecs"
                              text="here"
                              isIcon={false}
                              className="!text-primary-800"
                            />
                          </div>
                        }
                        className="h-full"
                        iconHeight={24}
                        iconWidth={48}
                      />
                    </div>
                  </div> */}
                </div>
              )}
            </div>
            <div className="bg-gray-50 flex justify-end space-x-2 items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full flex-wrap md:flex-nowrap">
              <Button
                variant="primary"
                type="submit"
                form={`currentStaffDocument-8`}
              >
                Submit
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
