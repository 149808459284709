import { StaffProfile } from "api/types";
import ReusableForm from "components/Form";
import { FieldType, FormData } from "components/types";
import { useForm } from "react-hook-form";
import { getTodayDate } from "utils/util";
import { AdminUpdateProps } from "./AdminUpdateResidencyAndWorkRights";

const getPoliceCheckData = (staffProfile: StaffProfile) => [
  {
    name: "policeCheck",
    label: "Police check *",
    buttonLabel: "Upload police check",
    type: FieldType.FileUpload,
    parentFormContainerClassName: "w-2/3 inline-block align-top pr-6",
    key: "policeCheck",
    defaultValue: staffProfile.policeCheck,
    isMultiple: false,
  },
  {
    name: "policeCheckValidUntil",
    label: "Police check expiry date *",
    type: FieldType.Calendar,
    parentFormContainerClassName: "w-full md:w-1/3 inline-block align-top pr-6",
    wrapperClassName: "w-full",
    defaultValue: staffProfile.policeCheckValidUntil,
    minDate: getTodayDate(),
    className: "w-full",
  },
];

export default function AdminUpdatePoliceCheck({
  user,
  onSubmitFn,
}: AdminUpdateProps) {
  const { ...formMethods } = useForm<FormData>();
  const { staffProfile } = user;

  const onSubmit = async (data: FormData) => onSubmitFn(data);

  return (
    <div className="bg-white rounded-md">
      <div className="pl-6 pr-0 pb-0">
        <ReusableForm
          fields={getPoliceCheckData(staffProfile)}
          onSubmit={onSubmit}
          id="edit-POLICE_CHECK"
          formClassName="pt-1 pb-6"
          formMethods={{
            ...formMethods,
          }}
        />
      </div>
    </div>
  );
}
