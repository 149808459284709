import { StaffProfile } from "api/types";
import ReusableForm from "components/Form";
import { FieldType, FormData } from "components/types";
import { useForm } from "react-hook-form";
import { qualificationOptions } from "routes/onboarding/staff/steps/step4";
import { getTodayDate } from "utils/util";
import { AdminUpdateProps } from "./AdminUpdateResidencyAndWorkRights";

const getTrainingData = (staffProfile: StaffProfile) => [
  {
    name: "highestQualification",
    label: "Highest qualification in your country *",
    type: FieldType.Select,
    options: qualificationOptions,
    defaultValue: staffProfile.highestQualification,
    containerClassName: "w-full mr-3",
    parentFormContainerClassName: "!w-1/2 inline-block align-top md:pr-6",
    isDropdownFixed: true,
  },
  {
    name: "highestQualificationSchool",
    label: "School where highest qualification was attained *",
    type: FieldType.Input,
    parentFormContainerClassName: "w-1/2 inline-block align-top md:pr-6",
    containerClassName: "",
    defaultValue: staffProfile.highestQualificationSchool,
  },
  {
    name: "workCertificates",
    label: "Work-related certificates (Upload up to a maximum of 10 files) *",
    buttonLabel: "Upload work-related certificates",
    type: FieldType.FileUpload,
    fileUploadContainerClassName: "w-full mb-4 md:pr-6",
    key: "workCertificates",
    labelClassName: "text-gray-900 font-bold !text-base",
    isMultipleFiles: true,
    defaultValue: staffProfile.workCertificates,
  },
  {
    name: "resume",
    label: "Resume *",
    buttonLabel: "Upload resume",
    type: FieldType.FileUpload,
    parentFormContainerClassName:
      "md:w-1/2 w-full inline-block align-top md:pr-6 ",
    key: "resume",
    labelClassName: "text-gray-900 font-bold !text-sm mb-3",
    defaultValue: staffProfile.resume,
  },
  {
    name: "foodHandlingCertificate",
    label: "Food handling certificate *",
    buttonLabel: "Upload food handling certificate ",
    type: FieldType.FileUpload,
    parentFormContainerClassName:
      "md:w-1/2 w-full inline-block align-top md:pr-6",
    key: "foodHandlingCertificate",
    labelClassName: "text-gray-900 font-bold !text-sm mb-1",
    isMultipleFiles: true,
    externalLink: "https://dofoodsafely.health.vic.gov.au/index.php/en/",
    defaultValue: staffProfile.foodHandlingCertificate,
  },
  {
    name: "infectionControlCertificate",
    label: "Infection control training",
    buttonLabel: "Upload infection control training",
    type: FieldType.FileUpload,
    parentFormContainerClassName:
      "md:w-1/2 w-full inline-block align-top md:pr-6",
    key: "infectionControlCertificate",
    labelClassName: "text-gray-900 font-bold !text-sm mb-3",
    isMultipleFiles: true,
    defaultValue: staffProfile.infectionControlCertificate,
  },
  {
    name: "ahpra",
    label: "Aged care certificate (AHPRA)",
    type: FieldType.FileUpload,
    buttonLabel: "Attach ahpra",
    placeholder: "or drag and drop",
    defaultValue: staffProfile.ahpra,
    parentFormContainerClassName: `w-1/2  inline-block align-top md:pr-6`,
  },
  {
    name: "ahpraDate",
    label: "Aged care certificate (AHPRA) date",
    type: FieldType.Calendar,
    parentFormContainerClassName: `w-1/2  inline-block align-top md:pr-6`,
    className: "w-full",
    wrapperClassName: "w-full",
    defaultValue: staffProfile.ahpraDate,
    minDate: getTodayDate(),
    placeholder: "Input ahpra date",
  },
];

export default function AdminUpdateTrainingAndQualifications({
  user,
  onSubmitFn,
}: AdminUpdateProps) {
  const { ...formMethods } = useForm<FormData>();
  const { staffProfile } = user;

  const onSubmit = async (data: FormData) => onSubmitFn(data);

  return (
    <div className="bg-white rounded-md">
      <div className="pl-6 pr-0 pb-0">
        <ReusableForm
          fields={getTrainingData(staffProfile)}
          onSubmit={onSubmit}
          id="edit-TRAINING"
          formClassName="pt-1 pb-6"
          formMethods={{
            ...formMethods,
          }}
        />
      </div>
    </div>
  );
}
