import { UserStatus } from "api/types";
import { redirect } from "react-router-dom";
import { LoaderProps } from "routes/root";

export async function StaffOnboardingLoader({
  params,
  request,
  user,
}: LoaderProps) {
  const authData = window.localStorage.getItem("eicare_auth");
  const auth = JSON.parse(authData || "{}");
  if (!(auth && auth.session)) {
    const url = new URL(request.url);
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }
  if (user.status === UserStatus.Completed || user.createdAccount) {
    return redirect("/dashboard/staff");
  }
  if (user.staffProfile.onboardingStatus < 1) {
    return redirect(`/onboarding/staff/start-onboarding`);
  }
  if (
    user.status === UserStatus.In_Review &&
    user.staffProfile.onboardingStatus === 11
  ) {
    return redirect("/onboarding/staff/onBoardingCompletion");
  }
  return {
    user,
  };
}
