import { ADMIN_ROLES } from "api/types";
import { redirect } from "react-router-dom";
import { LoaderProps } from "routes/root";

export async function ReviewNewSubmitedDocumentLoader({
  params,
  request,
  user,
}: LoaderProps) {
  const authData = window.localStorage.getItem("eicare_auth");
  const url = new URL(request.url);

  if (authData) {
    const auth = JSON.parse(authData);
    if (auth.session) {
      try {
        const isAdmin =
          user.roles?.filter((role) => ADMIN_ROLES.indexOf(role) > -1).length >
          0;

        if (!isAdmin) {
          return redirect(
            `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
          );
        }
        return { user };
      } catch (error) {
        console.log("error", error);
        return redirect(
          `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
        );
      }
    }
  }
  return redirect(
    `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
  );
}
