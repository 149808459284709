import { StaffProfile } from "api/types";
import ReusableForm from "components/Form";
import { FieldType, FormData } from "components/types";
import { useForm } from "react-hook-form";
import { AdminUpdateProps } from "./AdminUpdateResidencyAndWorkRights";

export const getUpdatePreExisting = (staffProfile: StaffProfile) => [
  {
    name: "immunisationHistory",
    label: "Immunisation history",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.immunisationHistory,
    isMultipleFiles: false,
    buttonLabel: "Upload Immunisation history",
    parentFormContainerClassName: "pr-6",
    externalLink:
      "https://www.servicesaustralia.gov.au/how-to-get-immunisation-history-statements?context=22436",
  },
  {
    name: "covidVaccineCertificate",
    label: "Covid-19 vaccine certificate",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.covidVaccineCertificate,
    isMultipleFiles: false,
    buttonLabel: "Upload covid-19 vaccine certificate",
    parentFormContainerClassName: "pr-6",
    externalLink:
      "https://www.health.gov.au/our-work/covid-19-vaccines/getting-your-vaccination",
  },
];

export default function AdminUpdatePreExisting({
  user,
  onSubmitFn,
}: AdminUpdateProps) {
  const { ...formMethods } = useForm<FormData>();
  const { staffProfile } = user;

  const onSubmit = async (data: FormData) => onSubmitFn(data);

  return (
    <div className="bg-white rounded-md">
      <div className="pl-6 pr-0 pb-0">
        <ReusableForm
          fields={getUpdatePreExisting(staffProfile)}
          onSubmit={onSubmit}
          id="edit-PRE_EXISTING_INJURY_OR_HEALTH_DECLARATION"
          formClassName="pt-1 pb-6"
          formMethods={{
            ...formMethods,
          }}
        />
      </div>
    </div>
  );
}
