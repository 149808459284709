import { createdAccountDocumentUpdate } from "api/staff";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import FileUpload from "components/FileUpload";
import { Icon } from "components/Icon";
import Link from "components/Link";
import Notification, { NotificationProps } from "components/Notification";
import Radio from "components/RadioBox";
import Select from "components/Select";
import Input from "components/UnControlledInput";
import { FILE_NAME_MAPPING, FieldType, FormData } from "components/types";
import { handleResponse } from "components/utility";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { handleDownload, validateBSB, validateTextOnly } from "utils/util";
import { CurrentEmployeeProps } from "./CurrentEmployeeStep1";

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear"];

export default function CurrentEmployeeStep6({
  user,
  refreshUser,
  isToggled,
}: CurrentEmployeeProps) {
  const {
    getValues,
    watch,
    formState,
    handleSubmit,
    setError,
    ...formMethods
  } = useForm<FormData>();
  const { staffProfile } = user;
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const superFundSelection = watch("superannuationFund");
  const [superannuationFundSelection, setSuperannuationFundSelection] =
    useState<string>();
  const methods = {
    ...formMethods,
    getValues,
    formState,
    watch,
    handleSubmit,
    setError,
  };

  useEffect(() => {
    setSuperannuationFundSelection(
      staffProfile.bankDetails?.superannuationFund || ""
    );
  }, [staffProfile.bankDetails?.superannuationFund]);

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    const response = await createdAccountDocumentUpdate(
      6,
      data,
      FIELDS_TO_REMOVE
    );
    const isSuccess = await handleResponse(
      response,
      setError,
      setResponse,
      "Submitted successfully"
    );
    isSuccess && (await refreshUser());
  };

  const getSuperFund = () => {
    if (!(superFundSelection || superannuationFundSelection)) {
      return <></>;
    }
    const fileName =
      superFundSelection === "default" ||
      superannuationFundSelection === "default"
        ? FILE_NAME_MAPPING.SUPER_STANDARD
        : FILE_NAME_MAPPING.SUPER_HESTA;
    const externalLink =
      superFundSelection === "default" ||
      superannuationFundSelection === "default"
        ? "https://www.australiansuper.com/"
        : "https://www.hesta.com.au/members";
    return (
      <div className="mt-5 md:pr-6">
        <Button
          variant="primary"
          className="w-full md:w-96 text-center"
          onClick={() => handleDownload(fileName)}
        >
          <Icon name="download" />
          <span className="text-base leading-6 font-bold text-gray-900 ml-3 text-center">
            {fileName}
          </span>
        </Button>
        <FileUpload
          name="superannuationFundChoice"
          label={`After you fill the "${fileName}", upload the form below: `}
          type={FieldType.FileUpload}
          requiredCondition="This is a required field"
          isHiglighted={isToggled}
          defaultValue={staffProfile.bankDetails?.superannuationFundChoice}
          fileUploadContainerClassName=" mt-4"
          buttonLabel={`Upload ${fileName.split(".")[0]} form`}
          externalLink={externalLink}
        />
      </div>
    );
  };

  return (
    <div className="bg-white shadow rounded-md">
      <div className=" text-sm font-medium">
        <div className="flex flex-col ">
          {response ? (
            <Notification
              type={response.type}
              message={response.message}
              className="my-3 !inline-flex w-max"
            />
          ) : (
            <></>
          )}
        </div>
        <FormProvider {...methods}>
          <form id={"currentStaffDocument-6"} onSubmit={handleSubmit(onSubmit)}>
            <div className="p-6 pr-0">
              <div>
                <h3 className="text-base leading-6 font-semibold text-gray-900">
                  Salary payment authorisation *
                </h3>
                <div className="flex items-center mt-1">
                  <Checkbox
                    name="paymentAuthorisation"
                    label="I authorise and request Pay Office to deposit my salary into
                  the bank account added below:"
                    type={FieldType.Checbox}
                    labelClassName="text-sm leading-5 font-medium text-gray-900"
                    className="mr-3 !mt-0"
                    requiredCondition="This is a required field"
                    isHiglighted={isToggled}
                    defaultValue={
                      staffProfile.bankDetails?.paymentAuthorisation
                    }
                  />
                </div>
                <div className="flex justify-start flex-wrap md:flex-nowrap">
                  <Input
                    name="bankName"
                    label="Bank name *"
                    type={FieldType.Input}
                    requiredCondition="This is a required field"
                    isHiglighted={isToggled}
                    defaultValue={staffProfile.bankDetails?.bankName}
                    containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                    className="w-full"
                  />
                  <Input
                    name="branchAddress"
                    label="Branch address *"
                    type={FieldType.Input}
                    requiredCondition="This is a required field"
                    isHiglighted={isToggled}
                    defaultValue={staffProfile.bankDetails?.branchAddress}
                    containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                    className="w-full"
                  />
                  <Input
                    name="accountName"
                    label="Account name *"
                    type={FieldType.Input}
                    requiredCondition="This is a required field"
                    isHiglighted={isToggled}
                    defaultValue={staffProfile.bankDetails?.accountName}
                    containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                    className="w-full"
                    validationFunction={validateTextOnly}
                    validationErrorMessage="Please enter only alphabet characters"
                  />
                </div>
                <div className="flex justify-start flex-wrap md:flex-nowrap">
                  <Select
                    name="accountType"
                    label="Type of account *"
                    type={FieldType.Select}
                    requiredCondition="This is a required field"
                    isHiglighted={isToggled}
                    defaultValue={staffProfile.bankDetails?.accountType}
                    containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                    className="w-full"
                    options={[
                      {
                        label: "Transaction/cheque account",
                        value: "Transaction/cheque account",
                      },
                      {
                        label: "Savings/Deposit account",
                        value: "Savings/Deposit account",
                      },
                      {
                        label: "Term deposit account",
                        value: "Term deposit account",
                      },
                    ]}
                    hideSearch
                  />
                  <Input
                    name="accountNumber"
                    label="Account number *"
                    type={FieldType.Input}
                    requiredCondition="This is a required field"
                    isHiglighted={isToggled}
                    defaultValue={staffProfile.bankDetails?.accountNumber}
                    containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                    className="w-full"
                    subType="number"
                  />
                  <Input
                    name="branchNumber"
                    label="Branch number (BSB) *"
                    type={FieldType.Input}
                    requiredCondition="This is a required field"
                    isHiglighted={isToggled}
                    defaultValue={staffProfile.bankDetails?.branchNumber}
                    containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                    className="w-full"
                    subType="number"
                    validationFunction={validateBSB}
                    validationErrorMessage="BSB should be 6 digits"
                  />
                </div>
              </div>
              <div className="flex items-stretch flex-wrap md:flex-nowrap">
                <div className="w-full md:w-2/3 pr-6">
                  <div className="my-5">
                    <h3 className="text-base leading-6 font-semibold text-gray-900">
                      Tax file number declaration form *
                    </h3>
                    <Button
                      variant="primary"
                      size="large"
                      className="w-full md:w-96 text-center mt-2"
                      onClick={() => handleDownload(FILE_NAME_MAPPING.TAX_FORM)}
                    >
                      <Icon name="download" />
                      <span className="text-base leading-6 font-bold text-gray-900 mx-2">
                        Download TFN form
                      </span>
                    </Button>
                  </div>
                  <FileUpload
                    name="tfn"
                    label="After you fill the “Tax file number declaration form” upload the document below:"
                    type={FieldType.FileUpload}
                    requiredCondition="This is a required field"
                    isHiglighted={isToggled}
                    defaultValue={staffProfile.bankDetails?.tfn}
                    isMultipleFiles={false}
                    buttonLabel="Upload tax form"
                    externalLink="https://www.ato.gov.au/forms-and-instructions/tfn-declaration"
                  />
                </div>
                <div className="w-full md:w-1/3 mt-5 md:pr-6">
                  <Notification
                    type="info"
                    message="Helpful information"
                    bodyText={
                      <ul className="list-disc text-primary-800">
                        <li>
                          <span className="mr-1 text-sm leading-5 font-normal text-primary-800">
                            Learn more about Tax file number (TFN)
                          </span>
                          <Link
                            url="https://www.ato.gov.au/forms-and-instructions/tfn-declaration"
                            text="here"
                            isIcon={false}
                            className="text-sm leading-5 font-normal !text-primary-800"
                          />
                        </li>
                        <li className="mt-3">
                          <span className="mr-1 text-sm leading-5 font-normal text-primary-800">
                            Learn more about AustralianSuper
                          </span>
                          <Link
                            url="https://www.australiansuper.com/"
                            text="here"
                            isIcon={false}
                            className="text-sm leading-5 font-normal !text-primary-800"
                          />
                        </li>
                        <li className="mt-3">
                          <span className="mr-1 text-sm leading-5 font-normal text-primary-800">
                            Learn more about HESTA super fund
                          </span>
                          <Link
                            url="https://www.hesta.com.au/members"
                            text="here"
                            isIcon={false}
                            className="text-sm leading-5 font-normal !text-primary-800"
                          />
                        </li>
                      </ul>
                    }
                    className="h-full !m-0"
                    iconHeight={20}
                    iconWidth={20}
                  />
                </div>
              </div>
              <div className="mt-5">
                <Radio
                  name="superannuationFund"
                  label="Super fund selection *"
                  isCol={true}
                  options={[
                    {
                      label:
                        "I would like an account established with the default Superannuation fund",
                      value: "default",
                    },
                    {
                      label:
                        "I would like to nominate a fund and have attached the Choice of Superannuation Fund Standard Choice Form and complying fund evidence",
                      value: "nominate",
                    },
                  ]}
                  type={FieldType.Radio}
                  requiredCondition="This is a required field"
                  isHiglighted={isToggled}
                  defaultValue={staffProfile.bankDetails?.superannuationFund}
                />
                {getSuperFund()}
              </div>
            </div>
            <div className="bg-gray-50 flex justify-end space-x-2 items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full flex-wrap md:flex-nowrap">
              <Button
                variant="primary"
                type="submit"
                form={`currentStaffDocument-6`}
              >
                Submit
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
