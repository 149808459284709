import { createdAccountDocumentUpdate } from "api/staff";
import { StaffProfile, User } from "api/types";
import classNames from "classnames";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import ReusableForm from "components/Form";
import Notification, { NotificationProps } from "components/Notification";
import { FieldType, FormData } from "components/types";
import { handleResponse } from "components/utility";
import { useState } from "react";
import { UseFormGetValues, UseFormSetValue, useForm } from "react-hook-form";
import { OccupationOptions } from "routes/dashboard/staff/InviteStaff";
import {
  AUSTRALIA_STATE_OPTIONS,
  countriesOptions,
  getTodayDate,
  languageOptions,
  validateAustralianPhoneNumber,
} from "utils/util";
import { CurrentEmployeeProps } from "./CurrentEmployeeStep1";

export const getPersonalDetailsFields = (
  getValue: UseFormGetValues<FormData>,
  setValue: UseFormSetValue<FormData>,
  staffProfile: StaffProfile,
  user: User,
  setResidentialState: (value: string | undefined) => void,
  residentialState: string | undefined,
  isToggled: boolean
) => [
  {
    name: "title",
    label: "Title *",
    type: FieldType.Select,
    placeholder: "Select",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    options: [
      { label: "Mr", value: "Mr" },
      {
        label: "Mrs",
        value: "Mrs",
      },
      { label: "Miss", value: "Miss" },
    ],
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.title,
    hideSearch: true,
  },
  {
    name: "firstName",
    label: "First Name *",
    type: FieldType.Input,
    placeholder: "John",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: user.firstName,
  },
  {
    name: "lastName",
    label: "Last Name *",
    type: FieldType.Input,
    placeholder: "Smith",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: user.lastName,
  },
  {
    name: "email",
    label: "Email *",
    type: FieldType.Input,
    placeholder: "johndoe@gmail.com",
    parentFormContainerClassName:
      "md:w-1/4 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: user.email,
    isDisabled: true,
  },
  {
    name: "mobile",
    label: "Mobile *",
    type: FieldType.Input,
    placeholder: "04XXX XXX XXX",
    parentFormContainerClassName:
      "md:w-1/4 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: user.mobile,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
  },
  {
    name: "homePhone",
    label: "Home Telephone no.",
    type: FieldType.Input,
    placeholder: "04XXX XXX XXX",
    parentFormContainerClassName:
      "md:w-1/4 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: staffProfile.homePhone,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
    isHiglighted: isToggled,
  },
  {
    name: "occupation",
    label: "Occupation *",
    type: FieldType.Select,
    placeholder: "Select",
    options: OccupationOptions,
    parentFormContainerClassName:
      "md:w-1/4 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    defaultValue: staffProfile.occupation,
    isDisabled: true,
  },
  {
    type: FieldType.Paragraph,
    parentFormContainerClassName: "w-full",
    name: "",
    label: "",
    children: (
      <p
        className={classNames(
          "mt-5 text-sm leading-5 font-semibold text-gray-700"
        )}
      >
        Residential address *
      </p>
    ),
  },
  {
    name: "residentialAddressBuildingNo",
    label: "Building/House/Unit number",
    type: FieldType.Input,
    placeholder: "2",
    parentFormContainerClassName:
      "md:w-1/5 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.residentialAddressBuildingNo,
  },
  {
    name: "residentialAddressStreetName",
    label: "Street name",
    type: FieldType.Input,
    placeholder: "Rose Street",
    parentFormContainerClassName:
      "md:w-1/5 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.residentialAddressStreetName,
  },
  {
    name: "residentialAddressSuburb",
    label: "Suburb",
    type: FieldType.Input,
    placeholder: "Clayton",
    parentFormContainerClassName:
      "md:w-1/5 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.residentialAddressSuburb,
  },
  {
    name: "residentialAddressState",
    label: "State/Territory",
    type: FieldType.Select,
    placeholder: "Select State/Territory",
    parentFormContainerClassName:
      "md:w-1/5 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.residentialAddressState,
    options: AUSTRALIA_STATE_OPTIONS,
  },
  {
    name: "residentialAddressZip",
    label: "Postcode",
    type: FieldType.Input,
    placeholder: "3001",
    parentFormContainerClassName:
      "md:w-1/5 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.residentialAddressZip,
  },
  {
    type: FieldType.Paragraph,
    parentFormContainerClassName: "w-full",
    name: "",
    label: "",
    children: (
      <div className="flex space-x-4 items-center mt-5 ">
        <p
          className={classNames(
            "text-sm leading-5 font-semibold text-gray-700"
          )}
        >
          Mailing address *
        </p>
        <Checkbox
          name="sameAsResidentialAddress"
          label="Same as Residential address"
          className="!mt-0"
          type={FieldType.Checbox}
          onChange={(e) => {
            if (e.target.checked) {
              setValue(
                "mailingAddressBuildingNo",
                getValue("residentialAddressBuildingNo")
              );
              setValue(
                "mailingAddressStreetName",
                getValue("residentialAddressStreetName")
              );
              setValue(
                "mailingAddressSuburb",
                getValue("residentialAddressSuburb")
              );
              setValue(
                "mailingAddressState",
                getValue("residentialAddressState")
              );
              setResidentialState(getValue("residentialAddressState"));
              setValue("mailingAddressZip", getValue("residentialAddressZip"));
            } else {
              setValue("mailingAddressBuildingNo", "");
              setValue("mailingAddressStreetName", "");
              setValue("mailingAddressSuburb", "");
              setValue("mailingAddressState", "");
              setValue("mailingAddressZip", "");
              setResidentialState(undefined);
            }
          }}
        />
      </div>
    ),
  },
  {
    name: "mailingAddressBuildingNo",
    label: "Building/House/Unit number",
    type: FieldType.Input,
    placeholder: "2",
    parentFormContainerClassName:
      "md:w-1/5 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.mailingAddressBuildingNo,
  },
  {
    name: "mailingAddressStreetName",
    label: "Street name",
    type: FieldType.Input,
    placeholder: "Rose Street",
    parentFormContainerClassName:
      "md:w-1/5 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.mailingAddressStreetName,
  },
  {
    name: "mailingAddressSuburb",
    label: "Suburb",
    type: FieldType.Input,
    placeholder: "Clayton",
    parentFormContainerClassName:
      "md:w-1/5 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.mailingAddressSuburb,
  },
  {
    name: "mailingAddressState",
    label: "State/Territory",
    type: FieldType.Select,
    placeholder: "Select State/Territory",
    parentFormContainerClassName:
      "md:w-1/5 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: residentialState || staffProfile.mailingAddressState,
    options: AUSTRALIA_STATE_OPTIONS,
  },
  {
    name: "mailingAddressZip",
    label: "Postcode",
    type: FieldType.Input,
    placeholder: "3001",
    parentFormContainerClassName:
      "md:w-1/5 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.mailingAddressZip,
  },
  {
    name: "dob",
    label: "Date of Birth *",
    type: FieldType.Calendar,
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    wrapperClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.dob,
    maxDate: getTodayDate(),
  },
  {
    name: "birthCountry",
    label: "Country of Birth *",
    type: FieldType.Select,
    options: countriesOptions,
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.birthCountry,
  },
  {
    name: "gender",
    label: "Gender *",
    type: FieldType.Radio,
    options: [
      { label: "Male", value: "male" },
      {
        label: "Female",
        value: "female",
      },
    ],
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.gender,
  },
  {
    name: "homeRegion",
    label: "Home Region *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.homeRegion,
  },
  {
    name: "spokenLanguages",
    label: "Spoken language(s)*",
    type: FieldType.MultiSelect,
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    options: languageOptions,
    defaultValue: staffProfile.spokenLanguages,
  },
  {
    name: "aboriginalTSIStatus",
    label: "I identify as Aboriginal and or TSI *",
    type: FieldType.Radio,
    options: [
      { label: "Aboriginal", value: "Aboriginal" },
      {
        label: "Torres Strait Islander (TSI)",
        value: "Torres Strait Islander (TSI)",
      },
      { label: "Both", value: "Both" },
      { label: "None of the above", value: "None of the above" },
    ],
    isCol: true,
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    isHiglighted: isToggled,
    defaultValue: staffProfile.aboriginalTSIStatus,
  },
];

const FIELDS_TO_REMOVE = [
  "calendarMonth",
  "calendarYear",
  "sameAsResidentialAddress",
];

export default function CurrentEmployeeStep2({
  user,
  refreshUser,
  isToggled,
}: CurrentEmployeeProps) {
  const {
    setError,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setValue,
    ...formMethods
  } = useForm<FormData>();
  const { staffProfile } = user;
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const [residentialState, setResidentialState] = useState<string | undefined>(
    undefined
  );

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    const response = await createdAccountDocumentUpdate(
      2,
      data,
      FIELDS_TO_REMOVE
    );
    const isSuccess = await handleResponse(
      response,
      setError,
      setResponse,
      "Submitted successfully"
    );
    isSuccess && (await refreshUser());
  };

  return (
    <div className="bg-white shadow rounded-md">
      <div className="pl-6 pr-0 pb-0">
        <div className="flex flex-col ">
          {response ? (
            <Notification
              type={response.type}
              message={response.message}
              className="!inline-flex !m-0 !mt-2 w-max"
            />
          ) : (
            <></>
          )}
        </div>
        <ReusableForm
          fields={getPersonalDetailsFields(
            getValues,
            setValue,
            staffProfile,
            user,
            setResidentialState,
            residentialState,
            isToggled
          )}
          onSubmit={onSubmit}
          id="currentStaffDocument-2"
          formClassName="pt-1 pb-6"
          formMethods={{
            ...formMethods,
            setError,
            watch,
            reset,
            handleSubmit,
            formState,
            getValues,
            setValue,
          }}
        />
      </div>
      <div className="bg-gray-50 flex justify-end space-x-2 items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full flex-wrap md:flex-nowrap">
        <Button variant="primary" type="submit" form={`currentStaffDocument-2`}>
          Submit
        </Button>
      </div>
    </div>
  );
}
