/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */

import { confirmInterviewTime, updateStaffCheckStatus } from "api/staff";
import { Outcome, User } from "api/types";
import classNames from "classnames";
import Badge from "components/Badge";
import Button from "components/Button";
import DataTableContainer from "components/DataTableContainer";
import { Icon } from "components/Icon";
import { InterviewDetail } from "components/InterviewDetail";
import Modal from "components/Modal";
import Notification from "components/Notification";
import { ReferenceDetail } from "components/ReferenceDetail";
import Select, { Option } from "components/Select";
import { FieldType } from "components/types";
import { invalidateQueries } from "components/utility";
import { VehicleDetail } from "components/VehicleDetail";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { Bounce, toast } from "react-toastify";
import {
  capitalizeFirstLetter,
  formatDateWithDay,
  formatTime,
  isBoolean,
  isFeatureEnabled,
} from "../../utils/util";
import {
  getBankData,
  getContractData,
  getEmergencyContactData,
  getEmployeeCodeOfConductData,
  getInjuryData,
  getInterviewData,
  getPersonalInformationData,
  getPoliceCheckData,
  getReference1Data,
  getReference2Data,
  getStatutoryDeclarationData,
  getTrainingData,
  getVehicleData,
  getWorkRightsData,
} from "./common";
import { options, StepNames } from "./staffDetail";
interface Outcomes {
  statutory: React.ReactNode | null;
  "reference/1": React.ReactNode | null;
  "reference/2": React.ReactNode | null;
  "police-check": React.ReactNode | null;
  interview: React.ReactNode | null;
}

export const useStaffDetails = (
  user: User,
  isAdminView = false,
  onEditClick?: (stepName: StepNames) => void,
  adminStaffOptions?: Option[],
  admin?: User,
  isSuperAdminView = false
) => {
  const defaultValue = {
    interview: isBoolean(user.staffProfile.interviewOutcome) ? (
      user.staffProfile.interviewOutcome ? (
        <Notification type="check" message="Interview passed successfully!" />
      ) : (
        <Notification type="warning" message="Interview failed!" />
      )
    ) : null,
    statutory: isBoolean(user.staffProfile.statutoryReviewOutcome) ? (
      user.staffProfile.statutoryReviewOutcome ? (
        <Notification
          type="check"
          message="Statutory declaration passed successfully!"
        />
      ) : (
        <Notification type="warning" message="Statutory failed!" />
      )
    ) : null,
    "reference/1": isBoolean(user.staffProfile?.reference1?.reviewOutcome) ? (
      user.staffProfile?.reference1?.reviewOutcome ? (
        <Notification type="check" message="Reference 1 passed successfully!" />
      ) : (
        <Notification type="warning" message="Reference 1 failed!" />
      )
    ) : null,
    "reference/2": isBoolean(user.staffProfile?.reference2?.reviewOutcome) ? (
      user.staffProfile?.reference2?.reviewOutcome ? (
        <Notification type="check" message="Reference 2 passed successfully!" />
      ) : (
        <Notification type="warning" message="Reference 2 failed!" />
      )
    ) : null,
    "police-check": isBoolean(user.staffProfile?.policeCheckResult) ? (
      user.staffProfile?.policeCheckResult ? (
        <Notification
          type="check"
          message="Police check passed successfully!"
        />
      ) : (
        <Notification type="warning" message="Police check failed!" />
      )
    ) : null,
  };
  const [outcomes, setOutcomes] = useState<Partial<Outcomes>>(defaultValue);
  const [showInterviewConfirmModal, setShowInterviewConfirmModal] =
    useState(false);
  const [showAdditionalQuestionSection, setShowAdditionalQuestionSection] =
    useState(false);
  const [showAdditionalReferenceSection1, setShowAdditionalReferenceSection1] =
    useState(false);
  const [showAdditionalReferenceSection2, setShowAdditionalReferenceSection2] =
    useState(false);
  const [
    showAdditionalVehicleInspectionSection,
    setShowAdditionalVehicleInspectionSection,
  ] = useState(false);
  const queryClient = useQueryClient();

  const cnfrmInterviewTime = async () => {
    const response = await confirmInterviewTime(user.id);
    if (
      (response.data?.message || response.data?.error) &&
      response.status !== 200
    ) {
      toast.error(
        response.data?.message || "Error in Confirming Interview Time",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          icon: <Icon name="error" />,
        }
      );
      return;
    }
    toast.success("Interview time confirmed successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: <Icon name="check" />,
    });
    invalidateQueries(queryClient, [
      `/api/user/admin/profile/${user.id}`,
      `/api/user/admin/staff/review-logs/${user.id}`,
      `/api/user/admin/staff/user-data-logs/${user.id}`,
      `/api/user/admin/staff`,
    ]);
  };

  const updateStatus = async (fieldName: string, value: string) => {
    const result = await updateStaffCheckStatus(user.id, fieldName, value);
    if (result.statusText && result.status !== 200) {
      setOutcomes({
        ...outcomes,
        [fieldName]: (
          <Notification type="warning" message={result.data?.message} />
        ),
      });
      return;
    }
    invalidateQueries(queryClient, [
      `/api/user/admin/profile/${user.id}`,
      `/api/user/admin/staff/review-logs/${user.id}`,
      `/api/user/admin/staff/user-data-logs/${user.id}`,
      `/api/user/admin/staff`,
    ]);
    setOutcomes({
      ...outcomes,
      [fieldName]:
        value === Outcome.ACCEPTED ? (
          <Notification
            type="check"
            message={`${capitalizeFirstLetter(
              fieldName === "statutory" ? "Statutory declaration" : fieldName
            )
              .split(/[\/-]/)
              .join(" ")} passed successfully!`}
          />
        ) : (
          <Notification
            type="warning"
            message={`${capitalizeFirstLetter(
              fieldName === "statutory" ? "Statutory declaration" : fieldName
            )
              .split(/[\/-]/)
              .join(" ")} failed!`}
          />
        ),
    });
  };

  return [
    {
      title: "Residency status and work rights",
      data: getWorkRightsData(user.staffProfile),
      footer: isAdminView && (
        <Button
          variant="primary"
          onClick={() =>
            onEditClick && onEditClick(StepNames.RESIDENCY_WORK_RIGHT)
          }
        >
          Edit
        </Button>
      ),
      footerClassName: "justify-end",
      additionalTitleText: (
        <span
          className={
            "text-xs rounded-full py-0.5 px-2 bg-primary-100 text-primary-900"
          }
        >
          Step 01
        </span>
      ),
    },
    {
      title: "Personal details",
      data: getPersonalInformationData(user),
      footer: isAdminView && (
        <Button
          variant="primary"
          onClick={() =>
            onEditClick && onEditClick(StepNames.PERSONAL_INFORMATION)
          }
        >
          Edit
        </Button>
      ),
      footerClassName: "justify-end",
      additionalTitleText: (
        <span
          className={
            "text-xs rounded-full py-0.5 px-2 bg-primary-100 text-primary-900"
          }
        >
          Step 02
        </span>
      ),
    },
    {
      title: "Pre-existing injury or health declaration",
      data: getInjuryData(user.staffProfile),
      footer: isAdminView && (
        <Button
          variant="primary"
          onClick={() =>
            onEditClick &&
            onEditClick(StepNames.PRE_EXISTING_INJURY_OR_HEALTH_DECLARATION)
          }
        >
          Edit
        </Button>
      ),
      footerClassName: "justify-end",
      additionalTitleText: (
        <span
          className={
            "text-xs rounded-full py-0.5 px-2 bg-primary-100 text-primary-900"
          }
        >
          Step 03
        </span>
      ),
    },
    {
      title: "Emergency contact",
      data: getEmergencyContactData(user.staffProfile?.emergencyContact),
      heading: (
        <span className="text-xl leading-7 font-semibold text-gray-900">{`${user.staffProfile?.emergencyContact?.title} ${user.staffProfile?.emergencyContact?.firstName} ${user.staffProfile?.emergencyContact?.lastName}`}</span>
      ),
      footer: isSuperAdminView && (
        <Button
          variant="primary"
          onClick={() =>
            onEditClick && onEditClick(StepNames.EMERGENCY_CONTACT)
          }
        >
          Edit
        </Button>
      ),
      footerClassName: "justify-end",
      additionalTitleText: (
        <span
          className={
            "text-xs rounded-full py-0.5 px-2 bg-primary-100 text-primary-900"
          }
        >
          Step 03
        </span>
      ),
    },
    {
      title: "Training and qualifications",
      data: getTrainingData(user.staffProfile, isAdminView),
      footer: isAdminView && (
        <Button
          variant="primary"
          onClick={() => onEditClick && onEditClick(StepNames.TRAINING)}
        >
          Edit
        </Button>
      ),
      footerClassName: "justify-end",
      additionalTitleText: (
        <span
          className={
            "text-xs rounded-full py-0.5 px-2 bg-primary-100 text-primary-900"
          }
        >
          Step 04
        </span>
      ),
    },
    {
      title: "",
      heading: (
        <div className="bg-primary-50">
          <div className="p-4 flex items-start">
            <span className="mt-1">
              <Icon name="info" height={20} width={20} />
            </span>
            <div className="pl-3">
              <h3 className="text-sm leading-5 font-semibold text-primary-900">
                Reviewing guidance:
              </h3>
              <p className="text-sm leading-5 font-medium text-primary-800 mt-2">
                1 - Review Police check first. If Police check passes, the
                interview section will become enabled.
              </p>
              <p className="text-sm leading-5 font-medium text-primary-800 mt-3">
                2 - Confirm interview date and time for the new staff
              </p>
              <p className="text-sm leading-5 font-medium text-primary-800 mt-3">
                3 - Conduct an interview with the new staff and select the
                interview outcome. If the interview is a pass, the job
                references section will become enabled.
              </p>
              <p className="text-sm leading-5 font-medium text-primary-800 mt-3">
                4 - Call/Email job reference 1 and 2 and then select review
                outcome.
              </p>
              <p className="text-sm leading-5 font-medium text-primary-800 mt-6">
                When the Police check and interview outcomes are a failure, the
                new staff will be notified that their job application will not
                proceed. For the job references, the new staff is only notified
                when both reference 1 and 2 review outcomes are a fail.
              </p>
            </div>
          </div>
        </div>
      ),
      bodyContainerClassName: "!p-0",
      data: [],
      shouldDisplay: isAdminView,
      headingContainerClassName: "!p-0",
    },
    {
      title: "Police check",
      data: getPoliceCheckData(user.staffProfile),
      footer: isAdminView && (
        <>
          <div className="flex items-center flex-1">
            <Select
              hideSearch
              name="policeCheck"
              type={FieldType.Select}
              label=""
              isDisabled={
                !isSuperAdminView && Boolean(outcomes?.["police-check"])
              }
              placeholder="Select review outcome"
              defaultValue={
                isBoolean(user.staffProfile?.policeCheckResult)
                  ? user.staffProfile?.policeCheckResult
                    ? Outcome.ACCEPTED
                    : Outcome.REJECTED
                  : undefined
              }
              options={options}
              containerClassName="w-full md:w-auto !mt-0"
              className="font-semibold"
              onChange={async (option: Option) => {
                await updateStatus("police-check", option.value as string);
              }}
            />
            {outcomes?.["police-check"]}
          </div>
          <Button
            variant="primary"
            onClick={() => onEditClick && onEditClick(StepNames.POLICE_CHECK)}
            className="ml-2 md:ml-0"
          >
            Edit
          </Button>
        </>
      ),
      footerClassName: "justify-between items-center",
      additionalTitleText: (
        <span
          className={
            "text-xs rounded-full py-0.5 px-2 bg-primary-100 text-primary-900"
          }
        >
          Step 05
        </span>
      ),
    },
    {
      title: "Interview",
      data: getInterviewData(user.staffProfile),
      isDisabled: !Boolean(user.staffProfile?.policeCheckResult),
      footer: isAdminView && (
        <>
          <div className="flex items-center flex-1 space-x-6">
            <Button
              variant="gray"
              className={classNames(
                "flex items-start justify-center space-x-4",
                showAdditionalQuestionSection &&
                  "outline-offset-2 outline-gray-800 outline"
              )}
              onClick={() =>
                setShowAdditionalQuestionSection(!showAdditionalQuestionSection)
              }
            >
              <Icon
                name="downChevron"
                className={classNames(
                  showAdditionalQuestionSection && "rotate-180"
                )}
              />
              <span>Questions</span>
            </Button>
            <Select
              hideSearch
              name="interviewOutcome"
              type={FieldType.Select}
              label=""
              options={options}
              containerClassName="w-full md:w-auto !mt-0"
              className="font-semibold"
              isDisabled={
                !isSuperAdminView &&
                (Boolean(outcomes?.interview) ||
                  !Boolean(user.staffProfile.interviewConfirmed))
              }
              placeholder="Select interview outcome"
              defaultValue={
                isBoolean(user.staffProfile.interviewOutcome)
                  ? user.staffProfile.interviewOutcome
                    ? Outcome.ACCEPTED
                    : Outcome.REJECTED
                  : undefined
              }
              onChange={async (option: Option) => {
                await updateStatus("interview", option.value as string);
              }}
            />
            {outcomes?.interview}
          </div>
          <div className="flex space-x-2">
            <Button
              variant="white"
              onClick={() => setShowInterviewConfirmModal(true)}
              className="ml-2 md:ml-0"
              disabled={
                (isBoolean(user.staffProfile.interviewConfirmed)
                  ? user.staffProfile.interviewConfirmed
                  : false) || !Boolean(user.staffProfile?.policeCheckResult)
              }
            >
              Confirm interview date and time
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                onEditClick && onEditClick(StepNames.AVAILABILITY_INTERVIEW)
              }
              className="ml-2 md:ml-0"
              disabled={
                Boolean(outcomes?.interview) ||
                !Boolean(user.staffProfile?.policeCheckResult)
              }
            >
              Edit
            </Button>
            <Modal
              isOpen={showInterviewConfirmModal}
              onClose={() => setShowInterviewConfirmModal(false)}
              modalHeader=""
              className="!bg-primary-50"
              modalBodyClassName="!mt-2"
            >
              <h3 className="text-primary-900 font-bold flex justify-start items-center px-4">
                <Icon name="info" />
                <span className="ml-3">Confirm interview date and time</span>
              </h3>
              <p className="text-primary-800 text-sm leading-5 font-normal px-4 pt-4">
                {`You are about to send an email to ${user.firstName} ${user.lastName} ${user.email} confirming the
                  interview will be on `}
              </p>
              {user?.staffProfile?.interviewDate && (
                <p className="text-primary-800 text-sm leading-5 font-normal px-4 pb-1 space-x-1">
                  <span className="text-primary-800 text-sm leading-5 font-bold">
                    {`${formatDateWithDay(
                      new Date(user?.staffProfile?.interviewDate)
                    )}
                  `}
                  </span>
                  at
                  <span className="text-primary-800 text-sm leading-5 font-bold">
                    {`${formatTime(new Date(user.staffProfile.interviewDate))} Melbourne VIC, Australia ${new Date(
                      user?.staffProfile?.interviewDate
                    )
                      .toLocaleString("en-AU", {
                        timeZone: "Australia/Victoria",
                        timeZoneName: "shortOffset",
                      })
                      ?.split(" ")
                      ?.pop()}`}
                  </span>
                </p>
              )}
              <div className="flex justify-start items-center  rounded-b-md px-4 py-3  rounded-b-md space-x-2">
                <Button
                  variant="text"
                  size="noSize"
                  onClick={() => setShowInterviewConfirmModal(false)}
                  className="text-primary-800"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="text-primary-800"
                  onClick={() => {
                    cnfrmInterviewTime();
                    setShowInterviewConfirmModal(false);
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Modal>
          </div>
        </>
      ),
      footerClassName: "justify-between items-center",
      shouldDisplay: isAdminView,
      additionalTitleText: (
        <span
          className={
            "text-xs rounded-full py-0.5 px-2 bg-primary-100 text-primary-900"
          }
        >
          Step 06
        </span>
      ),
      additionalInformationContainer: showAdditionalQuestionSection && (
        <InterviewDetail user={user} admin={admin} />
      ),
    },
    {
      title: "Job reference contact details",
      heading: (
        <div className="flex">
          <DataTableContainer
            title="Job reference 1"
            heading=""
            data={getReference1Data(user.staffProfile)}
            parentContainerClassName={classNames(
              "w-full md:w-49 inline-block align-top mr-6 !mt-3 h-full",
              showAdditionalReferenceSection1 && "border-2 border-primary-500"
            )}
            isDisabled={!Boolean(user.staffProfile.interviewOutcome)}
            footer={
              isAdminView && (
                <div className="flex items-center flex-1 space-x-3">
                  <Button
                    variant="gray"
                    className={classNames(
                      "flex items-start justify-center space-x-4",
                      showAdditionalReferenceSection1 &&
                        "outline-offset-2 outline-gray-800 outline"
                    )}
                    onClick={() => {
                      setShowAdditionalReferenceSection2(false);
                      setShowAdditionalReferenceSection1(
                        !showAdditionalReferenceSection1
                      );
                    }}
                  >
                    <Icon
                      name="downChevron"
                      className={classNames(
                        showAdditionalReferenceSection1 && "rotate-180"
                      )}
                    />
                    <span>Questions</span>
                  </Button>
                  <Select
                    hideSearch
                    name="status"
                    type={FieldType.Select}
                    label=""
                    options={options}
                    isDisabled={
                      !isSuperAdminView &&
                      (Boolean(outcomes?.["reference/1"]) ||
                        !Boolean(user.staffProfile.interviewOutcome))
                    }
                    placeholder="Select review outcome"
                    defaultValue={
                      isBoolean(user.staffProfile?.reference1?.reviewOutcome)
                        ? user.staffProfile?.reference1?.reviewOutcome
                          ? Outcome.ACCEPTED
                          : Outcome.REJECTED
                        : undefined
                    }
                    containerClassName="w-full md:w-auto !mt-0"
                    className="font-semibold"
                    onChange={async (option: Option) => {
                      await updateStatus("reference/1", option.value as string);
                    }}
                  />
                  {outcomes?.["reference/1"]}
                </div>
              )
            }
            footerClassName="justify-between items-center h-full"
          />
          <DataTableContainer
            title="Job reference 2"
            heading=""
            data={getReference2Data(user.staffProfile)}
            parentContainerClassName={classNames(
              "w-full md:w-49 inline-block align-top mr-0 !mt-3 h-full",
              showAdditionalReferenceSection2 && "border-2 border-primary-500"
            )}
            isDisabled={!Boolean(user.staffProfile.interviewOutcome)}
            footer={
              isAdminView && (
                <div className="flex items-center flex-1 space-x-3">
                  <Button
                    variant="gray"
                    className={classNames(
                      "flex items-start justify-center space-x-4",
                      showAdditionalReferenceSection2 &&
                        "outline-offset-2 outline-gray-800 outline"
                    )}
                    onClick={() => {
                      setShowAdditionalReferenceSection1(false);
                      setShowAdditionalReferenceSection2(
                        !showAdditionalReferenceSection2
                      );
                    }}
                  >
                    <Icon
                      name="downChevron"
                      className={classNames(
                        showAdditionalReferenceSection2 && "rotate-180"
                      )}
                    />
                    <span>Questions</span>
                  </Button>
                  <Select
                    hideSearch
                    name="status"
                    type={FieldType.Select}
                    label=""
                    isDisabled={
                      !isSuperAdminView &&
                      (Boolean(outcomes?.["reference/2"]) ||
                        !Boolean(user.staffProfile.interviewOutcome))
                    }
                    placeholder="Select review outcome"
                    defaultValue={
                      isBoolean(user.staffProfile?.reference2?.reviewOutcome)
                        ? user.staffProfile?.reference2?.reviewOutcome
                          ? Outcome.ACCEPTED
                          : Outcome.REJECTED
                        : undefined
                    }
                    options={options}
                    containerClassName="w-full md:w-auto !mt-0"
                    className="font-semibold"
                    onChange={async (option: Option) => {
                      await updateStatus("reference/2", option.value as string);
                    }}
                  />
                  {outcomes?.["reference/2"]}
                </div>
              )
            }
            footerClassName="justify-between items-center h-full"
          />
        </div>
      ),
      data: [],
      additionalTitleText: (
        <span
          className={
            "text-xs rounded-full py-0.5 px-2 bg-primary-100 text-primary-900"
          }
        >
          Step 04
        </span>
      ),
      isDisabled: !Boolean(user.staffProfile.interviewOutcome),
      additionalInformationContainer:
        (showAdditionalReferenceSection1 && (
          <ReferenceDetail user={user} admin={admin} referenceNumber={1} />
        )) ||
        (showAdditionalReferenceSection2 && (
          <ReferenceDetail user={user} admin={admin} referenceNumber={2} />
        )),
      bodyContainerClassName: "!pb-3",
    },
    {
      title: "Bank, tax file number and superannuation details",
      data: getBankData(user.staffProfile),
      additionalTitleText: (
        <span
          className={
            "text-xs rounded-full py-0.5 px-2 bg-primary-100 text-primary-900"
          }
        >
          Step 07
        </span>
      ),
      footer: isSuperAdminView && (
        <Button
          variant="primary"
          onClick={() => onEditClick && onEditClick(StepNames.BANK_DETAILS)}
        >
          Edit
        </Button>
      ),
      footerClassName: "justify-end",
    },
    {
      title: "Vehicle and driving licence",
      data: getVehicleData(user),
      additionalTitleText: (
        <span
          className={
            "text-xs rounded-full py-0.5 px-2 bg-primary-100 text-primary-900"
          }
        >
          Step 08
        </span>
      ),
      footer: (
        <div className="flex justify-between items-center w-full">
          {!isFeatureEnabled() ? (
            <Button
              variant="gray"
              className={classNames(
                "flex items-start justify-center space-x-4 ",
                showAdditionalVehicleInspectionSection &&
                  "outline-offset-2 outline-gray-800 outline"
              )}
              onClick={() => {
                setShowAdditionalVehicleInspectionSection(
                  !showAdditionalVehicleInspectionSection
                );
              }}
            >
              <Icon
                name="downChevron"
                className={classNames(
                  showAdditionalVehicleInspectionSection && "rotate-180"
                )}
              />
              <span>Inspection checklist</span>
            </Button>
          ) : (
            <span></span>
          )}
          {isAdminView && (
            <Button
              variant="primary"
              onClick={() =>
                onEditClick &&
                onEditClick(StepNames.VEHICLE_AND_DRIVING_LICENCE)
              }
            >
              Edit
            </Button>
          )}
        </div>
      ),
      additionalInformationContainer:
        showAdditionalVehicleInspectionSection && (
          <VehicleDetail user={user} admin={admin} />
        ),
    },
    {
      title: "Employee handbook and code of conduct",
      data: getEmployeeCodeOfConductData(user.staffProfile),
      additionalTitleText: (
        <span
          className={
            "text-xs rounded-full py-0.5 px-2 bg-primary-100 text-primary-900"
          }
        >
          Step 09
        </span>
      ),
    },
    {
      title: "Statutory declaration",
      data: getStatutoryDeclarationData(user.staffProfile),
      footer: isAdminView && (
        <>
          <div className="flex items-center flex-1">
            <Select
              hideSearch
              name="status"
              type={FieldType.Select}
              label=""
              isDisabled={
                !isSuperAdminView &&
                (Boolean(outcomes?.statutory) ||
                  !user.staffProfile?.signedStatutoryForm)
              }
              placeholder="Select review outcome"
              defaultValue={
                isBoolean(user.staffProfile.statutoryReviewOutcome)
                  ? user.staffProfile?.statutoryReviewOutcome
                    ? Outcome.ACCEPTED
                    : Outcome.REJECTED
                  : undefined
              }
              options={options}
              containerClassName="w-full md:w-auto !mt-0"
              className="font-semibold"
              onChange={async (option: Option) => {
                await updateStatus("statutory", option.value as string);
              }}
            />
            {outcomes?.statutory}
          </div>
          {isSuperAdminView && (
            <Button
              variant="primary"
              onClick={() => onEditClick && onEditClick(StepNames.SATUATORY)}
              className="ml-2 md:ml-0"
            >
              Edit
            </Button>
          )}
        </>
      ),
      isDisabled: !user.staffProfile?.signedStatutoryForm,
      footerClassName: "justify-between items-center",
      additionalTitleText: (
        <span
          className={
            "text-xs rounded-full py-0.5 px-2 bg-primary-100 text-primary-900"
          }
        >
          Step 09
        </span>
      ),
    },
    {
      title: "Contract",
      data: getContractData(
        user.staffProfile,
        isAdminView,
        adminStaffOptions || []
      ),
      footer: isAdminView && (
        <Button
          variant="primary"
          onClick={() => onEditClick && onEditClick(StepNames.CONTRACT)}
        >
          Edit
        </Button>
      ),
      footerClassName: "justify-end",
      additionalTitleText: (
        <span
          className={
            "text-xs rounded-full py-0.5 px-2 bg-primary-100 text-primary-900"
          }
        >
          Step 10
        </span>
      ),
    },
    {
      title: "Account setup failure reasons",
      data: [
        [
          {
            title: "",
            value: (
              <div className="flex space-x-2">
                {user.failureReasons?.map((reason) => (
                  <Badge
                    message={reason.split("_").join(" ")}
                    iconName="hazardWarning"
                    className="bg-yellow-100 text-yellow-800 mr-4"
                    iconClassName="text-yellow-800"
                    size={12}
                  />
                ))}
              </div>
            ),
          },
        ],
      ],
      shouldDisplay: isAdminView && user.failureReasons?.length > 0,
    },
  ];
};
