import { SubmitHandler, UseFormReturn, ValidationRule } from "react-hook-form";

import { User } from "api/types";
import { SignProps } from "./Sign";

export interface FormData {
  [key: string]: any;
}

interface additionalValidationProps {
  [key: string]: {
    value: string | number | ValidationRule<RegExp> | undefined;
    message: string;
  };
}
export interface FormField {
  name: string;
  label: string;
  type: string;
  subType?: string; // For radio buttons or select dropdown
  options?: { value: string | number; label: string; isDisabled?: boolean }[]; // For radio buttons or select dropdown
  multiple?: boolean; // For multiple select or checkboxes
  requiredCondition?: string | boolean;
  className?: string;
  additionalValidationProps?: additionalValidationProps;
  placeholder?: string;
  parentFormContainerClassName?: string;
  defaultValue?: string | number | boolean | Date | string[];
  isDisabled?: boolean;
  isHiglighted?: boolean;
}

export interface FormProps<T extends object> {
  fields: FormField[];
  onSubmit: SubmitHandler<FormData>;
  id: string;
  formClassName?: string;
  formMethods: UseFormReturn<T>;
}

export enum FieldType {
  Input = "Input",
  Button = "Button",
  Select = "Select",
  Radio = "Radio",
  Calendar = "Calendar",
  FileUpload = "FileUpload",
  Checbox = "Checbox",
  Textarea = "Textarea",
  Sign = "Sign",
  MultiSelect = "MultiSelect",
  EmailInput = "EmailInput",
  Paragraph = "Paragraph",
}

export enum RESIDENCY_STATUS {
  "citizen" = "citizen",
  "permanent_resident" = "permanent_resident",
  "other" = "other",
}

export type TotalFormFields = FormField & Partial<SignProps>;

export interface StepProps {
  isCompleted: boolean;
  user: User;
  isToggled: boolean;
}

export enum FILE_NAME_MAPPING {
  TAX_FORM = "Tax file form.pdf",
  SUPER_HESTA = "Super HESTA Application.pdf",
  SUPER_STANDARD = "Superannuation standard choice form.pdf",
  EMPLOYEE_HANDBOOK = "EICARE employee handbook.pdf",
  STATUTORY_DECLARATION = "statutory declaration form.DOC",
}
export enum DOWNLOAD_FILE_NAME_MAPPING {
  "Tax file form.pdf" = "tax_file_form.pdf",
  "Super HESTA Application.pdf" = "super_hesta_application.pdf",
  "Superannuation standard choice form.pdf" = "superannuation_standard_choice_form.pdf",
  "EICARE employee handbook.pdf" = "employee_handbook.pdf",
  "statutory declaration form.DOC" = "statutory_declaration_form.doc",
}
