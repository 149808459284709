/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */

import { editStaff } from "api/staff";
import { Outcome, User } from "api/types";
import ReusableForm from "components/Form";
import { Option } from "components/Select";
import { FormData, FormField } from "components/types";
import { handleGenericResponse } from "components/utility";
import { format } from "date-fns-tz";
import { UseFormReturn } from "react-hook-form";
import { getChangedFields } from "../../utils/util";
import AdminUpdateBankTaxSuper from "./admin/AdminUpdateBankTaxSuper";
import AdminUpdateEmergencyContact from "./admin/AdminUpdateEmergencyContact";
import AdminUpdatePersonalDetails from "./admin/AdminUpdatePersonalDetails";
import AdminUpdatePoliceCheck from "./admin/AdminUpdatePoliceCheck";
import AdminUpdatePreExisting from "./admin/AdminUpdatePreExisting";
import AdminUpdateResidencyAndWorkRights from "./admin/AdminUpdateResidencyAndWorkRights";
import AdminUpdateTrainingAndQualifications from "./admin/AdminUpdateTrainingAndQualifications";
import AdminUpdateVehicleDetails from "./admin/AdminUpdateVehicleDetails";
import {
  editContract,
  editEmployeeHandbook,
  editInterviewDate,
  editReference1Data,
  editReference2Data,
  editStatutoryDeclaration,
} from "./common";

export const options = [
  {
    label: Outcome.PASSED,
    value: Outcome.ACCEPTED,
  },
  {
    label: Outcome.FAILED,
    value: Outcome.REJECTED,
  },
];

export enum StepNames {
  "PERSONAL_INFORMATION" = "PERSONAL_INFORMATION",
  "EMERGENCY_CONTACT" = "EMERGENCY_CONTACT",
  "SATUATORY" = "SATUATORY",
  "TRAINING" = "TRAINING",
  "POLICE_CHECK" = "POLICE_CHECK",
  "RESIDENCY_WORK_RIGHT" = "RESIDENCY_WORK_RIGHT",
  "AVAILABILITY_INTERVIEW" = "AVAILABILITY_INTERVIEW",
  "INTERVIEW_OUTCOME" = "INTERVIEW_OUTCOME",
  "INJURY" = "INJURY",
  "REFERENCE1" = "REFERENCE1",
  "REFERENCE2" = "REFERENCE2",
  "BANK_DETAILS" = "BANK_DETAILS",
  "VEHICLE_AND_DRIVING_LICENCE" = "VEHICLE_AND_DRIVING_LICENCE",
  "EMPLOYEE_HANDBOOK" = "EMPLOYEE_HANDBOOK",
  "CONTRACT" = "CONTRACT",
  "PRE_EXISTING_INJURY_OR_HEALTH_DECLARATION" = "PRE_EXISTING_INJURY_OR_HEALTH_DECLARATION",
}

export const StepnameToEndpoint = {
  CONTRACT: "/api/user/admin/staff/section/contract",
  RESIDENCY_WORK_RIGHT: "/api/user/admin/staff/section/residency",
  PERSONAL_INFORMATION: "/api/user/admin/staff/section/personal-details",
  TRAINING: "/api/user/admin/staff/section/training-qualification",
  POLICE_CHECK: "/api/user/admin/staff/section/police-check",
  AVAILABILITY_INTERVIEW: "/api/user/admin/staff/section/interview",
  BANK_DETAILS: "/api/user/admin/staff/section/banking",
  VEHICLE_AND_DRIVING_LICENCE: "/api/user/admin/staff/section/vehicle",
  PRE_EXISTING_INJURY_OR_HEALTH_DECLARATION:
    "/api/user/admin/staff/section/pre-existing",
  EMERGENCY_CONTACT: "/api/user/admin/staff/section/emergency-contact",
  SATUATORY: "/api/user/admin/staff/section/statutory",
};

const getFields = (
  user: User,
  stepName: StepNames,
  adminStaffOptions: Option[]
): FormField[] => {
  switch (stepName) {
    case StepNames.REFERENCE1:
      return editReference1Data(user?.staffProfile);
    case StepNames.REFERENCE2:
      return editReference2Data(user?.staffProfile);
    case StepNames.AVAILABILITY_INTERVIEW:
      return editInterviewDate(user?.staffProfile);
    case StepNames.EMPLOYEE_HANDBOOK:
      return editEmployeeHandbook(user?.staffProfile);
    case StepNames.SATUATORY:
      return editStatutoryDeclaration(user?.staffProfile);
    case StepNames.CONTRACT:
      return editContract(user?.staffProfile, adminStaffOptions);
    default:
      return editContract(user?.staffProfile, adminStaffOptions);
  }
};

export const FIELDS_TO_REMOVE = [
  "calendarMonth",
  "calendarYear",
  "confirmDetails",
  "legallyWorkInAustralia",
  "firstName",
  "lastName",
  "emailCheckForValidation",
  "sameAsResidentialAddress",
  "ownerName",
];

export const SUPER_ADMIN_FIELDS_TO_REMOVE = [
  "calendarMonth",
  "calendarYear",
  "confirmDetails",
  "legallyWorkInAustralia",
  "emailCheckForValidation",
  "sameAsResidentialAddress",
];

export const getFieldsToRemovePerStep = (
  stepName: StepNames,
  isSuperAdmin: boolean
) => {
  return isSuperAdmin ? SUPER_ADMIN_FIELDS_TO_REMOVE : FIELDS_TO_REMOVE;
};

export const useEditableForm = (
  user: User,
  stepName: StepNames | undefined,
  defaultFormMethods: UseFormReturn<FormData, any, undefined>,
  adminStaffOptions: Option[],
  handleCloseModal: () => void,
  isSuperAdmin = false
) => {
  if (!stepName) {
    return <h3>Please select a step to edit</h3>;
  }

  const { watch, ...formMethods } = defaultFormMethods;

  const onSubmit = async (data: FormData) => {
    const endpoint =
      StepnameToEndpoint[stepName as keyof typeof StepnameToEndpoint];
    if (stepName === StepNames.VEHICLE_AND_DRIVING_LICENCE) {
      data.ownVehicle = data.ownVehicle?.toLowerCase() === "yes" ? true : false;
      if (data.ownVehicle === user.staffProfile.vehicleOwnership) {
        delete data.ownVehicle;
      }
      data.engineSize = data.engineSize ? `${data.engineSize}L` : null;
    }
    const updatedField = getChangedFields(
      {
        ...user,
        ...user.staffProfile,
        ...user.staffProfile.bankDetails,
        ...user.staffProfile.vehicleDetails,
      },
      data
    );
    if (stepName === StepNames.AVAILABILITY_INTERVIEW) {
      const interviewDate = new Date(data.interviewDate);
      interviewDate.setHours(data.interviewDateTime.getHours());
      interviewDate.setMinutes(data.interviewDateTime.getMinutes());
      interviewDate.setSeconds(data.interviewDateTime.getSeconds());
      delete data.interviewDateTime;
      delete updatedField.interviewDateTime;
      updatedField.interviewDate = format(
        interviewDate,
        "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
        { timeZone: "Australia/Sydney" }
      );
    }
    const response = await editStaff(
      `${endpoint}/${user.id}`,
      updatedField,
      getFieldsToRemovePerStep(stepName, isSuperAdmin)
    );

    const result = await handleGenericResponse(response);
    result && handleCloseModal();
  };

  switch (stepName) {
    case StepNames.RESIDENCY_WORK_RIGHT as StepNames:
      return (
        <AdminUpdateResidencyAndWorkRights user={user} onSubmitFn={onSubmit} />
      );
    case StepNames.PERSONAL_INFORMATION as StepNames:
      return (
        <AdminUpdatePersonalDetails
          user={user}
          onSubmitFn={onSubmit}
          isSuperAdmin={isSuperAdmin}
        />
      );
    case StepNames.PRE_EXISTING_INJURY_OR_HEALTH_DECLARATION as StepNames:
      return (
        <AdminUpdatePreExisting
          user={user}
          onSubmitFn={onSubmit}
          isSuperAdmin={isSuperAdmin}
        />
      );
    case StepNames.EMERGENCY_CONTACT as StepNames:
      return (
        <AdminUpdateEmergencyContact
          user={user}
          onSubmitFn={onSubmit}
          isSuperAdmin={isSuperAdmin}
          refreshData={handleCloseModal}
        />
      );
    case StepNames.TRAINING as StepNames:
      return (
        <AdminUpdateTrainingAndQualifications
          user={user}
          onSubmitFn={onSubmit}
        />
      );
    case StepNames.POLICE_CHECK as StepNames:
      return <AdminUpdatePoliceCheck user={user} onSubmitFn={onSubmit} />;
    case StepNames.BANK_DETAILS as StepNames:
      return <AdminUpdateBankTaxSuper user={user} onSubmitFn={onSubmit} />;
    case StepNames.VEHICLE_AND_DRIVING_LICENCE as StepNames:
      return (
        <AdminUpdateVehicleDetails
          user={user}
          onSubmitFn={onSubmit}
          isSuperAdmin={isSuperAdmin}
        />
      );
    default:
      return (
        <ReusableForm
          fields={getFields(user, stepName, adminStaffOptions)}
          onSubmit={onSubmit}
          id={`edit-${stepName}`}
          formClassName="p-6 pt-0"
          formMethods={{
            ...formMethods,
            watch,
          }}
        />
      );
  }
};
