import { createdAccountDocumentUpdate } from "api/staff";
import Button from "components/Button";
import FileUpload from "components/FileUpload";
import Notification, { NotificationProps } from "components/Notification";
import Select from "components/Select";
import Input from "components/UnControlledInput";
import { FieldType, FormData } from "components/types";
import { handleResponse } from "components/utility";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  getLabel,
  qualificationOptions,
} from "routes/onboarding/staff/steps/step4";
import { CurrentEmployeeProps } from "./CurrentEmployeeStep1";

const uploadComponents = [
  {
    name: "workCertificates",
    label: "Work-related certificates (Upload up to a maximum of 10 files) *",
    buttonLabel: "Upload work-related certificates",
    type: FieldType.FileUpload,
    fileUploadContainerClassName: "w-full mb-4 md:pr-6",
    key: "workCertificates",
    labelClassName: "text-gray-900 font-bold !text-base",
    isMultipleFiles: true,
  },
  {
    name: "resume",
    label: "Resume *",
    buttonLabel: "Upload resume",
    type: FieldType.FileUpload,
    fileUploadContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6 ",
    key: "resume",
    labelClassName: "text-gray-900 font-bold !text-sm mb-3",
  },
  {
    name: "foodHandlingCertificate",
    label: "Food handling certificate *",
    buttonLabel: "Upload food handling certificate ",
    type: FieldType.FileUpload,
    fileUploadContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    key: "foodHandlingCertificate",
    labelClassName: "text-gray-900 font-bold !text-sm mb-1",
    isMultipleFiles: true,
    externalLink: "https://dofoodsafely.health.vic.gov.au/index.php/en/",
  },
  {
    name: "infectionControlCertificate",
    label: "Infection control training",
    buttonLabel: "Upload infection control training",
    type: FieldType.FileUpload,
    fileUploadContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    key: "infectionControlCertificate",
    labelClassName: "text-gray-900 font-bold !text-sm mb-3",
    isMultipleFiles: true,
  },
];

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear"];

export default function CurrentEmployeeStep4({
  user,
  refreshUser,
  isToggled,
}: CurrentEmployeeProps) {
  const { handleSubmit, formState, setError, ...formMethods } =
    useForm<FormData>();
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const { staffProfile } = user;

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    const response = await createdAccountDocumentUpdate(
      4,
      data,
      FIELDS_TO_REMOVE
    );
    const isSuccess = await handleResponse(
      response,
      setError,
      setResponse,
      "Submitted successfully"
    );
    isSuccess && (await refreshUser());
  };

  return (
    <div className="bg-white shadow rounded-md">
      <div className="flex flex-col ">
        {response ? (
          <Notification
            type={response.type}
            message={response.message}
            className="my-3 !inline-flex w-max"
          />
        ) : (
          <></>
        )}
      </div>
      <FormProvider {...{ ...formMethods, handleSubmit, formState, setError }}>
        <form id={"currentStaffDocument-4"} onSubmit={handleSubmit(onSubmit)}>
          <div className="pl-6 pt-1 pr-0 pb-6">
            <div className="flex justify-start items-start flex-wrap md:flex-nowrap">
              <Select
                name="highestQualification"
                label="Highest qualification in your country *"
                type={FieldType.Select}
                options={qualificationOptions}
                requiredCondition="This is a required field"
                isHiglighted={isToggled}
                defaultValue={staffProfile.highestQualification}
                containerClassName="md:pr-6"
                className="w-full md:!w-96"
              />
              <Input
                name="highestQualificationSchool"
                label="School where highest qualification was attained *"
                type={FieldType.Input}
                requiredCondition="This is a required field"
                isHiglighted={isToggled}
                containerClassName="w-full md:!w-96 md:pr-6"
                defaultValue={staffProfile.highestQualificationSchool}
              />
            </div>
            <div className="">
              {uploadComponents.map((uploadComp) => (
                <FileUpload
                  {...uploadComp}
                  label={getLabel(
                    uploadComp.name,
                    staffProfile.occupation,
                    uploadComp.label
                  )}
                  requiredCondition={
                    uploadComp.name === "infectionControlCertificate" ||
                    (uploadComp.name === "workCertificates" &&
                      staffProfile.occupation ===
                        "Support Worker (Domestic Assistance)") ||
                    (uploadComp.name === "foodHandlingCertificate" &&
                      staffProfile.occupation === "Nurse")
                      ? false
                      : "This is a required field"
                  }
                  isHiglighted={isToggled}
                  defaultValue={
                    staffProfile[
                      uploadComp.name as
                        | "workCertificates"
                        | "foodHandlingCertificate"
                        | "infectionControlCertificate"
                        | "ndisWorkerOrientationCertificate"
                    ]
                  }
                  key={uploadComp.name}
                />
              ))}
            </div>
            {/* <div className="mt-5">
              <h4 className="text-gray-900 text-lg font-bold pt-5">
                Job Reference Contact Details
              </h4>
              <div className="flex py-6 flex-wrap md:flex-nowrap">
                {["reference1", "reference2"].map((ref, index) => (
                  <div
                    className={classNames(
                      "md:w-1/2 w-full",
                      index === 0 ? "md:border-r border-gray-300" : "pl-6"
                    )}
                  >
                    <h5 className="text-gray-900 text-base font-bold">
                      Reference {index + 1}
                    </h5>
                    <Select
                      name={`${ref}.title`}
                      key={`${ref}title`}
                      label="Title *"
                      type={FieldType.Select}
                      placeholder="Select"
                      options={[
                        { label: "Mr", value: "Mr" },
                        {
                          label: "Mrs",
                          value: "Mrs",
                        },
                        { label: "Miss", value: "Miss" },
                      ]}
                      defaultValue={
                        staffProfile[ref as "reference1" | "reference2"]?.title
                      }
                      inputClassName="w-3/4"
                      hideSearch
                      containerClassName={classNames(
                        "md:w-1/5 w-full  inline-block align-top",
                        "md:pr-3"
                      )}
                      className="w-full"
                    />
                    <Input
                      name={`${ref}.firstName`}
                      label="First Name *"
                      type={FieldType.Input}
                      containerClassName={classNames(
                        "md:w-2/5 w-full  inline-block align-top",
                        "md:pr-3"
                      )}
                      className="w-full"
                      placeholder="John"
                      defaultValue={
                        staffProfile[ref as "reference1" | "reference2"]
                          ?.firstName
                      }
                    />
                    <Input
                      name={`${ref}.lastName`}
                      label="Last Name *"
                      type={FieldType.Input}
                      placeholder="John"
                      containerClassName={classNames(
                        "md:w-2/5 w-full  inline-block align-top",
                        "md:pr-6"
                      )}
                      className="w-full"
                      defaultValue={
                        staffProfile[ref as "reference1" | "reference2"]
                          ?.lastName
                      }
                    />
                    <EmailInput
                      name={`${ref}.email`}
                      label="Email *"
                      type={FieldType.Input}
                      className="w-full"
                      placeholder="jamesbond@actionstuff.com"
                      containerClassName={classNames(
                        "w-1/2  inline-block align-top",
                        "md:pr-3"
                      )}
                      defaultValue={
                        staffProfile[ref as "reference1" | "reference2"]?.email
                      }
                    />
                    <Input
                      name={`${ref}.mobile`}
                      label="Mobile *"
                      type={FieldType.Input}
                      className="w-full"
                      placeholder="+614XXX XXX XXX"
                      containerClassName={classNames(
                        "w-1/2  inline-block align-top",
                        "md:pr-6"
                      )}
                      defaultValue={
                        staffProfile[ref as "reference1" | "reference2"]?.mobile
                      }
                      validationFunction={validateAustralianPhoneNumber}
                      validationErrorMessage="Invalid Australian phone number"
                    />
                    <Input
                      name={`${ref}.relationShip`}
                      label="Relationship *"
                      type={FieldType.Input}
                      containerClassName={"md:pr-6"}
                      placeholder="Manager"
                      defaultValue={
                        staffProfile[ref as "reference1" | "reference2"]
                          ?.relationShip
                      }
                    />
                  </div>
                ))}
              </div>
            </div> */}
          </div>
          <div className="bg-gray-50 flex justify-end space-x-2 items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full flex-wrap md:flex-nowrap">
            <Button
              variant="primary"
              type="submit"
              form={`currentStaffDocument-4`}
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
