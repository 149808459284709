import { finishOnboarding } from "api/staff";
import { User } from "api/types";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import DataTableContainer from "components/DataTableContainer";
import { Icon } from "components/Icon";
import { FieldType } from "components/types";
import { useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { FormProvider, useForm } from "react-hook-form";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import ApplicationMenu from "routes/dashboard/Menu";
import { useStaffDetails } from "routes/ViewStaffDetails/useStaffDetails";

export default function ReviewDetail() {
  const { user } = useLoaderData() as { user: User };
  const navigate = useNavigate();
  const [isDetailsConfirmed, setIsDetailsConfirmed] = useState(false);
  const formMethods = useForm<FormData>();

  const completeOnboarding = async () => {
    const response = await finishOnboarding();
    if (response.statusText && response.status !== 200) {
      toast.error("Error in saving", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        icon: <Icon name="error" />,
      });
      return;
    }

    navigate("/onboarding/staff/onBoardingCompletion");
  };

  return (
    <>
      <ApplicationMenu navigationItems={[]} />
      <div className="py-1">
        <header className="mx-auto max-w-7xl ">
          <div className="font-bold leading-tight tracking-tight text-gray-900 border-b-2 border-gray-200 py-5 flex justify-between">
            <h1 className="text-3xl">My Profile</h1>
            <div className="flex justify-center items-center">
              <span className="mr-2">Profile creation: </span>
              <CircularProgressbar
                value={100}
                text={`100%`}
                className="h-10 w-10"
                styles={{
                  path: {
                    stroke: `#0FACD7`,
                  },
                  trail: {
                    stroke: "#E5E7EB",
                  },
                  text: {
                    fill: "#111827",
                    fontSize: "30px",
                  },
                }}
              />
            </div>
          </div>
        </header>
        <div className="mx-auto max-w-7xl  mt-8 mb-10">
          <div className="mt-5">
            <h3 className="text-xl leading-7 font-semibold text-gray-900">
              Review all information
            </h3>
            <p className="text-sm leading-5 font-normal text-gray-500">
              Check and confirm your details are correct. Please call us on{" "}
              <span className="font-semibold mr-1">1300 180 888</span> or email{" "}
              <span className="font-medium text-primary-600">
                support@eicare.com.au
              </span>{" "}
              if there is something that needs to be updated.
            </p>
          </div>
          {useStaffDetails(user).map((detail) => (
            <DataTableContainer
              title={detail.title}
              heading={detail.heading || <></>}
              data={detail.data}
              shouldDisplay={detail.shouldDisplay}
              // parentContainerClassName={detail.parentContainerClassName}
              additionalTitleText={detail.additionalTitleText}
            />
          ))}
          <div className="modal-footer bg-gray-50 flex justify-between items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full mt-10">
            <Button
              variant="icon"
              onClick={() => navigate(`/onboarding/staff/step/10`)}
              className="flex items-center"
              size="noSize"
            >
              <Icon name="back" />
              <span className="ml-2 text-gray-800 text-sm mr-4">Back</span>
            </Button>
            <div className="flex items-center">
              <FormProvider {...formMethods}>
                <Checkbox
                  name="confirmDetails"
                  label="I confirm all details are correct"
                  className="!mt-0"
                  type={FieldType.Checbox}
                  onChange={() => setIsDetailsConfirmed(!isDetailsConfirmed)}
                />
                <Button
                  variant="primary"
                  type="button"
                  disabled={!isDetailsConfirmed}
                  className="ml-3"
                  onClick={() => completeOnboarding()}
                >
                  Finish
                </Button>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
