import { createStaffAccount } from "api/staff";
import Button from "components/Button";
import ReusableForm from "components/Form";
import Modal from "components/Modal";
import Notification, { NotificationProps } from "components/Notification";
import { FieldType, FormData } from "components/types";
import { handleResponse } from "components/utility";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { ResidencyOptions } from "routes/onboarding/staff/steps/step1";
import { OccupationOptions } from "./InviteStaff";

interface CreateStaffAccountProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const getFields = (username: string) => [
  {
    name: "title",
    label: "Title",
    type: FieldType.Select,
    placeholder: "Select",
    options: [
      { label: "Mr", value: "Mr" },
      {
        label: "Mrs",
        value: "Mrs",
      },
      { label: "Miss", value: "Miss" },
    ],
    requiredCondition: "This is a required field",
    containerClassName: "w-full",
    hideSearch: true,
    parentFormContainerClassName:
      "md:w-1/5 w-full  inline-block align-top md:pr-5",
  },
  {
    name: "firstName",
    label: "First Name",
    type: FieldType.Input,
    placeholder: "John",
    requiredCondition: "This is a required field",
    parentFormContainerClassName:
      "md:w-2/5 w-full  inline-block align-top md:pr-5",
  },
  {
    name: "lastName",
    label: "Last Name",
    type: FieldType.Input,
    placeholder: "Doe",
    requiredCondition: "This is a required field",
    parentFormContainerClassName: "md:w-2/5 w-full  inline-block align-top",
  },
  {
    name: "username",
    label: "Username",
    type: FieldType.Input,
    placeholder: "jdoe",
    requiredCondition: "This is a required field",
    className: "w-full",
    parentFormContainerClassName:
      "md:w-1/2 w-full  inline-block align-top md:pr-5",
    defaultValue: username,
    isDisabled: true,
  },
  {
    name: "email",
    label: "Email",
    type: FieldType.EmailInput,
    placeholder: "Email",
    requiredCondition: "This is a required field",
    className: "w-full",
    parentFormContainerClassName: "md:w-1/2 w-full  inline-block align-top",
    containerClassName: "w-full",
  },
  {
    name: "occupation",
    label: "Occupation",
    type: FieldType.Select,
    placeholder: "Select an occupation",
    options: OccupationOptions,
    requiredCondition: "This is a required field",
    className: "w-full",
    containerClassName: "w-full",
    parentFormContainerClassName: "mb-6",
  },
  {
    name: "role",
    label: "User role",
    type: FieldType.Select,
    placeholder: "Select a role",
    options: [
      { label: "Super admin", value: "super_admin" },
      { label: "Administrator", value: "admin" },
      { label: "Normal", value: "normal" },
    ],
    requiredCondition: "This is a required field",
    className: "w-full",
    containerClassName: "w-full",
    hideSearch: true,
  },
  {
    name: "currentStaffStatus",
    label: "Current staff status *",
    type: FieldType.Radio,
    options: [
      { label: "Employed", value: "employed" },
      { label: "Unemployed", value: "unemployed", isDisabled: true },
    ],
    requiredCondition: "This is a required field",
    className: "w-full",
    containerClassName: "w-full",
  },
  {
    name: "residencyStatus",
    label: "Select Residency Status *",
    type: FieldType.Select,
    placeholder: "Select",
    className: "w-full ",
    requiredCondition: "This is a required field",
    options: ResidencyOptions,
    parentFormContainerClassName: "w-full",
    containerClassName: "w-full ",
    hideSearch: true,
  },
];

const CreateStaffAccount: React.FC<CreateStaffAccountProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const queryClient = useQueryClient();
  const handleCloseModal = () => {
    setResponse(null);
    setIsOpen(false);
    reset();
  };

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    const response = await createStaffAccount(data);
    const isSuccess = await handleResponse(response, setError, setResponse);
    if (isSuccess) {
      setResponse({
        type: "success",
        message: "Staff account sucessfuly created!",
      });
      queryClient.invalidateQueries({ queryKey: [`/api/user/admin/staff`] });
    }
  };

  const { reset, formState, setError, watch, ...formMethods } =
    useForm<FormData>();
  const { isValid } = formState;
  const [username, setUsername] = useState("");
  const watchedUsername = watch(["firstName", "lastName"]);
  useEffect(() => {
    if (
      watchedUsername.length >= 2 &&
      watchedUsername[0] &&
      watchedUsername[1]
    ) {
      setUsername(watchedUsername[0][0] + watchedUsername[1]);
    }
  }, [watchedUsername]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={handleCloseModal}
        modalHeader="Create staff account"
        customModalStyles={{ maxWidth: "574px", width: "574px" }}
      >
        {response && (
          <Notification type={response.type} message={response.message} />
        )}
        <ReusableForm
          fields={getFields(username)}
          onSubmit={onSubmit}
          id="hook-form"
          formClassName="m-4 px-3"
          formMethods={{
            ...formMethods,
            reset,
            formState,
            setError,
            watch,
          }}
        />
        <div className="modal-footer bg-gray-50 flex justify-end items-center  rounded-b-md px-6 py-2.5 border-t border-gray-200  rounded-b-md mt-6">
          <Button variant="white" onClick={handleCloseModal} className="mr-3">
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="hook-form"
            disabled={!isValid}
          >
            Create account
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CreateStaffAccount;
